(function() {
    "use strict";
    
          angular.module('app.controllers').controller('MessageHistoryController', function($http,$stateParams,$scope,UsersInvites,NotificationService,$window) 
          {
          var request_id = $stateParams.service_request_id;
          var vendor_id = $stateParams.vendor_id;
          //$window.location = function(){ '/users/messagehistory/'+request_id+'/'+vendor_id  }, 1000);
          $scope.messages = {};
          $scope.request_ids = {};
          UsersInvites.getMessageHistory(request_id,vendor_id).success(function(response)
           {
            $scope.messages = response;
            $scope.current_login_id = response;
            $scope.request_ids = response;
            $scope.service_request_id = response;
            $scope.vendor_id = response;
            $scope.role_id = response;
          });

         
          $scope.vendorBiddingnotesFormSubmit = function(){ 
            if ($('#redundancyCalls1').length > 0) {
            return false;
            }
            $('<input>').attr({
            type: 'hidden',
            id: 'redundancyCalls1',
            name: 'redundancyCalls1'
            }).appendTo('body');

              var role_id = $("input[name='role_id']").val();

              // Hard coded bride role id 3, vendor role id 2
              if(parseInt(role_id) == 3){
                  role_id = 2;
              }else{
                  role_id = 3;
              }
              $scope.role_id = role_id;

              var formData = {
                  service_id: $scope.service_id = $("input[name='service_id']").val(),
                  bride_id: $scope.bride_id = $("input[name='bride_id']").val(),
                  vendor_id: $scope.vendor_id = $("input[name='vendor_id']").val(),
                  role_id: $scope.role_id,

                  notes: $scope.notes = $scope.bidding.notes,
              };

            $http({
              url: "users/sendanotes",
              data: formData,
              method: 'POST',
              headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
            }).success(function(response){
              // console.log("OK", response);
               $window.location.reload();
             // NotificationService.serverNotificationRedirectSamePage(response.message.title,response.message.body);
            });
          };

  
          }); 

          angular.module('app.controllers').filter('dateformat', function ()
          {
          return function (item) {
            var t = item.split(/[- :]/);
            var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
            var time = d.getTime(); 
            return time;
          };
          });

angular.module('app.controllers').directive('backButton', function(){
    return {
      restrict: 'A',

      link: function(scope, element, attrs) {
        element.bind('click', goBack);

        function goBack() {
          history.back();
          scope.$apply();
        }
      }
    };
});
          angular.module('app.controllers').directive('enterSubmit', function () {
          return {
          restrict: 'A',
          link: function (scope, elem, attrs) {

          elem.bind('keydown', function(event) {
          var code = event.keyCode || event.which;

          if (code === 13) {
          if (!event.shiftKey) {
          event.preventDefault();
          scope.$apply(attrs.enterSubmit);
          }
          }
          });
          }
          };
          });

        })();


