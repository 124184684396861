(function() {
    "use strict";


    angular.module('app.controllers').controller('WeddingRequestController', function($scope, $state, VendorsProfileService, BrideRequestService, NotificationService, BridesProfileService, $mdDialog) 
    {

       
        $scope.allCategories = {};

        $scope.serviceCreate = {};

        $scope.event_details = {};



$scope.serviceRequest = {};


    if($scope.serviceCreates.service_request_id.length !== 0)
    {

    BridesProfileService.getServiceRequestDetails($scope.serviceCreates.service_request_id)
    .success(function(response) {
    $scope.serviceRequest = response;
    });
    }

else
{
  $scope.serviceRequest.biddings =[];

}


$scope.hourstoadd = function ()
             {
              min = 8;
              max = 24;
             };

 $scope.serviceCreates.musicarray='';

 $scope.serviceCreates.customarray='';


 $scope.serviceCreates.musicarray='';

 $scope.serviceCreates.customarray='';


if($scope.serviceCreates.wedding_event_plan===undefined)
        {
        //$scope.ServiceCategorieDetails.type_function=''; 
        $scope.serviceCreates.musicarray =[];
        if($scope.serviceCreates.musicarray.length===0)
                {
                 $scope.serviceCreates.musicarraylength=''; 
                }
                else
                {
                 $scope.serviceCreates.musicarraylength='1';  
                }


        }
        else if($scope.serviceCreates.wedding_event_plan==='')
        {
        $scope.serviceCreates.musicarray =[];
        if($scope.serviceCreates.musicarray.length===0)
                {
                 $scope.serviceCreates.musicarraylength=''; 
                }
                else
                {
                 $scope.serviceCreates.musicarraylength='1';  
                }

                }
        else
        {

        var split_video   = $scope.serviceCreates.wedding_event_plan.split(',');
        $scope.serviceCreates.musicarray  = [];

        for(var j=0;j<split_video.length;j++)
        {
$scope.serviceCreates.musicarray.push(parseInt(split_video[j]));
       
        }

        if($scope.serviceCreates.musicarray.length===0)
                {
                 $scope.serviceCreates.musicarraylength=''; 
                }
                else
                {
                 $scope.serviceCreates.musicarraylength='1';  
                }
        } 

 $scope.serviceCreates.musicarray = angular.copy($scope.serviceCreates.musicarray);





if($scope.serviceCreates.wedding_selected_id===undefined)
        {
        //$scope.ServiceCategorieDetails.type_function=''; 
        $scope.serviceCreates.customarray =[];
        if($scope.serviceCreates.customarray.length===0)
                {
                 $scope.serviceCreates.customarraylength=''; 
                }
                else
                {
                 $scope.serviceCreates.customarraylength='1';  
                }


        }
        else if($scope.serviceCreates.wedding_selected_id==='')
        {
        $scope.serviceCreates.customarray =[];
        if($scope.serviceCreates.customarray.length===0)
                {
                 $scope.serviceCreates.customarraylength=''; 
                }
                else
                {
                 $scope.serviceCreates.customarraylength='1';  
                }

                }
        else
        {

        var split_videos   = $scope.serviceCreates.wedding_selected_id.split(',');
        $scope.serviceCreates.customarray  = [];

        for(var k=0;k<split_videos.length;k++)
        {
$scope.serviceCreates.customarray.push(parseInt(split_videos[k]));
       
        }

        if($scope.serviceCreates.customarray.length===0)
                {
                 $scope.serviceCreates.customarraylength=''; 
                }
                else
                {
                 $scope.serviceCreates.customarraylength='1';  
                }
        } 

 $scope.serviceCreates.customarray = angular.copy($scope.serviceCreates.customarray);


          var path = window.location.pathname;
          var str = path.split("/");

          var url5 =  str[5];
          var url6 =  str[6];
 
         BridesProfileService.geteventdetails(url6).success(function(response)
           {
            $scope.event_details = response;
// popeye - angular expression display issue 

          jQuery(".WedServiceForm").removeClass("temphide");

           });

          $scope.teethUR =       [{ name: '1',id: 1, value: 'Wedding design (color palette,"look and feel")', checked: false },
  { name: '2', id: 2,value: 'Venue Selection', checked: false }, { name: '3', id: 3,value: 'Vendor Selection', checked: false }, { name: '4',id: 4,value: 'Vendor Coordination', checked: false }, 
  { name: '5',id: 5,value: 'Day of Wedding Coordination', checked: false }];

            $scope.upperRight = function ()
             {
              $scope.wedding_selected_id = "";
              for (var i = 0; i < $scope.teethUR.length; i++) 
              {
                if ($scope.teethUR[i].checked === true) 
                {
                if ($scope.wedding_selected_id === "") 
                  {
                   $scope.wedding_selected_id = $scope.teethUR[i].name;
                  } 
                  else 
                  {
                   $scope.wedding_selected_id = $scope.wedding_selected_id + "," + $scope.teethUR[i].name;
                  }
                  
                }
              }
            };



            $scope.event_plan = [{ name: '1',id: 1,value: 'Rehearsal', checked: false },
  { name: '2', id: 2,value: 'Ceremony', checked: false }, { name: '3', id: 3,value: 'Reception', checked: false }];



            $scope.upperRight1 = function ()
             {
              $scope.wedding_selected_id1 = "";
              for (var i = 0; i < $scope.event_plan.length; i++) 
              {
                if ($scope.event_plan[i].checked === true) 
                {
                if ($scope.wedding_selected_id1 === "") 
                  {
                   $scope.wedding_selected_id1 = $scope.event_plan[i].name;
                  } 
                  else 
                  {
                   $scope.wedding_selected_id1 = $scope.wedding_selected_id1 + "," + $scope.event_plan[i].name;
                  }
                  
                }
              }
            };
     

        VendorsProfileService.getAvaliableServicecategories().success(function(response) {
            $scope.allCategories = response;
        });


       
        $scope.weddingrequestFormSubmit = function() {

       $scope.serviceCreate.service_category_id = $("input[name='selectedCategories']").val();
       $scope.serviceCreate.ends_at = $("input[name='ends_at']").val();
       $scope.serviceCreate.wedding_selected_id = $("input[name='wedding_selected_id']").val();
       $scope.serviceCreate.wedding_location = $("input[name='wedding_location']").val();
       $scope.serviceCreate.wedding_no_guests = $("input[name='wedding_no_guests']").val();
       $scope.serviceCreate.wedding_event_plan = $("input[name='wedding_selected_id1']").val();
       $scope.serviceCreate.wedding_hours = $("input[name='wedding_hours']").val();
       $scope.serviceCreate.maximum_budget = $("input[name='maximum_budget']").val();

       var confirm = $mdDialog.confirm()
            .parent(angular.element(document.body))
            .title('Attention')
            .content('Please click OKAY to post this service request or click CANCEL to review it again.')
            .ariaLabel('Attention')
            .ok('Okay')
            .cancel('Cancel')
            .hasBackdrop(false);

            $mdDialog.show(confirm).then(function() {

        var weddingRequestForm = BrideRequestService.saveRequest($scope.serviceCreate,url6);
        //console.log(weddingRequestForm);

            weddingRequestForm.success(function(response) {

              // NotificationService.serverAlert(response.message.title, response.message.body);
       location.href = '/brides/requests/'+url6;

            }).error(function(response) {

                //  _.each(response, function(errors, key) {
                //      _.each(errors, function(e) {
                //        $scope.registrationForm[key].$dirty = true;
                //        $scope.registrationForm[key].$setValidity(key, false);
                //        $scope.errorMessages[key] = e;
                //      });
                //  });

            });
      }, function() {

             // $window.location.reload();

            });

        };

    });

 angular.module('app.controllers').filter('dateformat', function ()
          {
          return function (item) {
            var t = item.split(/[- :]/);
            var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
            var time = d.getTime(); 
            return time;
          };
          });


angular.module('app.controllers').directive("checkboxMusic", function() {
        return {
            restrict: "A",
            link: function(scope, elem, attrs) {
                // Determine initial checked boxes
                if (scope.serviceCreates.musicarray.indexOf(scope.colornew.id) !== -1) {
                    elem[0].checked = true;
                    //scope.ServiceCategorieDetails.type_function=elem[0];
                }


                // Update array on click
                elem.bind('click', function() {
                    var index = scope.serviceCreates.musicarray.indexOf(scope.colornew.id);
                    // Add if checked
                    if (elem[0].checked) {
                        if (index === -1)
                        {

                          

                         scope.serviceCreates.musicarray.push(scope.colornew.id);
                         //scope.ServiceCategorieDetails.type_function=scope.item.id;
                       }
                    }
                    // Remove if unchecked
                    else {
                          
                             if (index !== -1) scope.serviceCreates.musicarray.splice(index, 1);
                    }
                      if(scope.serviceCreates.musicarray.length===0)
                {
                 scope.serviceCreates.musicarraylength=''; 
                // alert(scope.ServiceCategorieDetails.array.length);
                }
                else
                {
                 scope.serviceCreates.musicarraylength='1';  
                 //alert('1');
                }
                    // Sort and update DOM display
                    scope.$apply(scope.serviceCreates.musicarray.sort(function(a, b) {
                        return a - b;
                    }));
                });

            }

        };
    });



angular.module('app.controllers').directive("checkboxPlan", function() {
        return {
            restrict: "A",
            link: function(scope, elem, attrs) {
                // Determine initial checked boxes
                if (scope.serviceCreates.customarray.indexOf(scope.colors.id) !== -1) {
                    elem[0].checked = true;
                    //scope.ServiceCategorieDetails.type_function=elem[0];
                }


                // Update array on click
                elem.bind('click', function() {
                    var index = scope.serviceCreates.customarray.indexOf(scope.colors.id);
                    // Add if checked
                    if (elem[0].checked) {
                        if (index === -1)
                        {

                          

                         scope.serviceCreates.customarray.push(scope.colors.id);
                         //scope.ServiceCategorieDetails.type_function=scope.item.id;
                       }
                    }
                    // Remove if unchecked
                    else {
                          
                             if (index !== -1) scope.serviceCreates.customarray.splice(index, 1);
                    }
                      if(scope.serviceCreates.customarray.length===0)
                {
                 scope.serviceCreates.customarraylength=''; 
                // alert(scope.ServiceCategorieDetails.array.length);
                }
                else
                {
                 scope.serviceCreates.customarraylength='1';  
                 //alert('1');
                }
                    // Sort and update DOM display
                    scope.$apply(scope.serviceCreates.customarray.sort(function(a, b) {
                        return a - b;
                    }));
                });

            }

        };
    });


  
angular.module("app.controllers").directive("maxNumbers", ["MaxNumbersService", function(MaxNumbersService){
  return {
      require : "ngModel",
      link : function(scope, element, attrs, modelCtrl){


          //parseInt removes spaces or ennucessary chars. To allow any falsy number
          var maxLength = parseInt(attrs.maxNumbers);
          var allowedIntegerLength = maxLength;
          var allowedDecimalLength = 0;

          if("decimals" in attrs){
              allowedDecimalLength = parseInt(attrs.decimals);
              allowedIntegerLength = maxLength - allowedDecimalLength;
          }

          if("min" in attrs){
              var minimumValue = parseFloat(attrs.min);
              modelCtrl.$validators.min = min;
          }

          if("max" in attrs){
              var maximumValue = parseFloat(attrs.max);
              modelCtrl.$validators.max = max;
          }




          modelCtrl.$parsers.unshift(function(val){
              var maxInteger = new MaxNumbersService(val, allowedIntegerLength, allowedDecimalLength);

              if(maxInteger.isNotANumber()){
                  maxInteger.handleIsNaN();
              }

              if(maxInteger.hasDecimals()){
                  if(allowedDecimalLength){
                      if(maxInteger.hasTooMuchDecimals()){
                          maxInteger.throwDecimals();
                      }
                  } else{
                      maxInteger.throwDecimals();
                  }
              }

              if(maxInteger.hasTooMuchIntegers()){
                  maxInteger.throwIntegers();
              }

              if(maxInteger.didItChanged()){
                  modelCtrl.$setViewValue(maxInteger.val);
              }

              modelCtrl.$render();
              return maxInteger.val;
          });

          modelCtrl.$parsers.unshift(function(view){
              return String(view);
          });

          modelCtrl.$parsers.push(function(view){
              if(!view) return view;
              return Number(view);
          });

          if(("simpleLink") in attrs){
              modelCtrl.$formatters.unshift(function(val){
                  if(!val) return val;
                  return String(val);
              });

              modelCtrl.$formatters.push(function(val){
                  //On load, this pipeline is always called because view needs to has model
                  if(!val) return val;

                  var maxInteger = new MaxNumbersService(val, allowedIntegerLength, allowedDecimalLength);

                  if(maxInteger.isNotANumber()){
                      maxInteger.handleIsNaN();
                  }

                  if(maxInteger.hasDecimals()){
                      if(allowedDecimalLength){
                          if(maxInteger.hasTooMuchDecimals()){
                              maxInteger.throwDecimals();
                          }
                      } else{
                          maxInteger.throwDecimals();
                      }
                  }

                  if(maxInteger.hasTooMuchIntegers()){
                      maxInteger.throwIntegers();
                  }

                  if(maxInteger.didItChanged()){
                      modelCtrl.$setViewValue(val);
                  }
                  return maxInteger.val;
              });
          }

          function min(model){
              return model ? model >= minimumValue : true;
          }

          function max(model){
              return model ? model <= maximumValue  : true;
          }

      }
  };
}]);





angular.module("app.controllers").factory("MaxNumbersService", [function(){

  /**
   * Contructor
   */
  function MaxInteger(val, allowedIntegerLength, allowedDecimalLength){
      this.val = val;
      this.pointIndex = val.indexOf(".");
      this.integersLength = (this.pointIndex !== -1) ? (val.substring(0, this.pointIndex)).length : val.length;
      //TODO what if finishes by a point
      this.decimalsLength = (this.pointIndex !== -1) ? (val.substring(this.pointIndex + 1)).length : 0;

      this.allowedIntegerLength = allowedIntegerLength;
      this.allowedDecimalLength = allowedDecimalLength;

      this.hasChanged = false;
  }

  MaxInteger.prototype.isNotANumber = function(){
      return isNaN(Number(this.val));
  };

  MaxInteger.prototype.handleIsNaN = function(){
      while(true){
          if(isNaN(Number(this.val))){
              this.val = this.val.substr(0, this.val.length - 1);
          } else{
              break;
          }
      }
      this.hasChanged = true;
  };

  MaxInteger.prototype.throwDecimals = function(){
      var incrementer = (this.allowedDecimalLength) ? this.allowedDecimalLength + 1 : 0;
      this.val = this.val.substring(0, this.pointIndex + incrementer);
      this.hasChanged = true;
  };

  MaxInteger.prototype.hasDecimals = function(){
      return this.val.indexOf(".") !== -1;
  };

  MaxInteger.prototype.hasTooMuchDecimals = function(){
      return this.decimalsLength > this.allowedDecimalLength;
  };

  MaxInteger.prototype.hasTooMuchIntegers = function(){
      return this.integersLength > this.allowedIntegerLength;
  };

  MaxInteger.prototype.throwIntegers = function(){
      var integersLength = this.allowedIntegerLength;
      if(this.pointIndex !== -1){
          integersLength = (this.pointIndex <= this.allowedIntegerLength) ? this.pointIndex : this.allowedIntegerLength;
          this.val = this.val.substr(0, integersLength);
      } else{
          this.val = this.val.substr(0, integersLength);
      }

      this.hasChanged = true;
  };

  MaxInteger.prototype.didItChanged  = function(){
      return this.hasChanged;
  };

  return MaxInteger;


}]);



})();

jQuery(document).ready(function(){

  var someDate = new Date();
    someDate.setDate(someDate.getDate() + 60);

    $('body').on('click','#end-date', function () {
      $(this).datetimepicker({
              format: 'MM/DD/YYYY',
              minDate: new Date(),
              
              defaultDate: new Date(),
      });//maxDate: someDate,
    });

$('body').on('keyup','#hournumber_only',function(e){
    var value = $(this).val();
      value = value.replace(/^(0*)(9*)/,"");
      $(this).val(value);
  if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
     this.value = this.value.replace(/[^0-9\.]/g, '');
  }
});
});

jQuery(document).ready(function(){

$('body').on('keyup','#number_only',function(e){
    var value = $(this).val();
      value = value.replace(/^(0*)/,"");
      $(this).val(value);
  if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
     this.value = this.value.replace(/[^0-9\.]/g, '');
  }
});
});
