(function() {
    "use strict";

    angular.module('app.controllers').controller('VendorsBiddingsController', function($window,$scope, $mdDialog, $state, $stateParams, VendorsProfileService, NotificationService) {

        $scope.serviceRequest = {};
        
        var service_fee = " ";

        VendorsProfileService.getServiceFeeDetails().success(function(response) {
               $scope.service_fee = response;
               var service_fee = $scope.service_fee;
            });

            VendorsProfileService.getcurrentvendorid().success(function(response) {

            $scope.currentvid = response;

            });

            VendorsProfileService.getServiceRequestMiniumDetails($stateParams.id)
            .success(function(response) {

              $scope.serviceRequest_minimum = response;
              $scope.minimumCount = $scope.serviceRequest_minimum.length;
              //console.log($scope.serviceRequest_minimum);

            });

             VendorsProfileService.getServiceRequestIdVendor($stateParams.id)
            .success(function(response) {
                $scope.serviceRequestIdshow = response;
            });
            


            VendorsProfileService.getServiceRequestDetails($stateParams.id)
            .success(function(response) {

              $scope.serviceRequest = response;
               jQuery('.dateWonClass').css('display','');

            });

            VendorsProfileService.getServiceRequestBestoffer().success(function(response) {

              $scope.bestOffer = response;
              $scope.bestOfferCount = $scope.bestOffer.length;

            });
            $scope.sort = function(keyname){
              $scope.sortKey = keyname;   //set the sortKey to the param passed
              $scope.reverse = !$scope.reverse; //if true make it false and vice versa
            };

    });

 angular.module('app.controllers').filter('format', function () {
          return function (item) {
           
           var t = item.split(/[- :]/);
           var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
           var time = d.getTime(); 
           
            var nextDay = new Date(d);
            nextDay.setDate(d.getDate()+15);
            return nextDay;
          };
        });

 angular.module('app.controllers').filter('dateformat', function ()
          {
          return function (item) {
            var t = item.split(/[- :]/);
            var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
            var time = d.getTime(); 
            return time;
          };
          });

})();

$('body').on('keyup','#number_only',function(e){
  if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
     this.value = this.value.replace(/[^0-9\.]/g, '');
  }
});
