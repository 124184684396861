(function() {
    "use strict";

    angular.module('app.controllers').controller('VideoGraphyController', function($scope,$state, VendorsProfileService, BrideRequestService, NotificationService,BridesProfileService){
$scope.allCategories = {};

        $scope.serviceCreate = {};

        $scope.event_details_video = {};

        BridesProfileService.geteventdetails().success(function(response)
        {
        $scope.event_details_video = response;
        });
        VendorsProfileService.getAvaliableServicecategories().success(function(response)
         {
            $scope.allCategories = response;
        });

  
        $scope.teethUR = [{ id: 1,value:'Raw Footage ', checked: false },{ id: 2,value:'Full feature video (30 - 40 minutes)', checked: false }, { id: 3,value:'Highlight video (3 - 4 minutes)', checked: false },{ id: 4,value:'other', checked: false,onclick:'stateChanged()' }];

  $scope.additionalfilm = [{ id: 1,value:'No', checked: false },{ id: 2,value:'Immediate day of editing to show during event', checked: false }, { id: 3,value:'DVD Copies', checked: false },{ id: 4,value:'Music Overlay ', checked: false },{ id: 5,value:'Photo from Video ', checked: false },{ id: 6,value:'other1', checked: false },{ id: 7,value:'other2', checked: false }];

   $scope.yourbudget = [{ id: 1,value:'Under $500 ', checked: false },{ id: 2,value:'$500 to $1000', checked: false }, { id: 3,value:'$1000 to $2000', checked: false },{ id: 4,value:'$2000 to $5000', checked: false },{ id: 5,value:' ', checked: false }];
$scope.isRequired=false;

$scope.$watch('form.Program', function(mVal){
  if (angular.isUndefined($scope.form)) return; 
//alert(mval);
  if(mVal === 'other'){
     $scope.form.OtherProgram = $scope.tmVar;
  } else {
    if($scope.form.OtherProgram !== null){
      $scope.tmVar = $scope.form.OtherProgram;
      $scope.form.OtherProgram = null;
   }
 }
});


            $scope.upperRight = function ()
             {
             
              $scope.type_function_selected_id = "";
             // $("input[name='type_function_selected_id']").val('');
              for (var i = 0; i < $scope.teethUR.length; i++) 
              {
                if ($scope.teethUR[i].checked === true) 
                {
                    //alert($scope.teethUR[i].id);
                    if($scope.teethUR[i].id===4)
                     {
                       $('#other_type_video').prop('disabled', false); 
                       //var myEl = angular.element( $('#other_type_video') );
                       // alert(myEl.attr('required'));
                        //  $('#other_type_video').attr('required');

                       //$('#other_type_video').prop('required', true);
                       //$('#other_type_video').removeAttr('required');​​​​​
                       
                     } 
                     else
                     {
                        $('#other_type_video').val('');
                        $('#other_type_video').prop('disabled', true);  
                       // $('#other_type_video').removeAttr('required');
                      // $('#other_type_video').prop('required', false);
                     }  
                if ($scope.type_function_selected_id === "") 
                  {
                   $scope.type_function_selected_id = $scope.teethUR[i].value;
                 //  $("input[name='type_function_selected_id']").val($scope.teethUR[i].value).trigger('input');
                  // $scope.isRequired=false;

                  } 
                  else 
                  {
                   $scope.type_function_selected_id = $scope.type_function_selected_id + "," + $scope.teethUR[i].value;
                 //  $("input[name='type_function_selected_id']").val($scope.URSelected+ "," + $scope.teethUR[i].value).trigger('input');
                  // $scope.isRequired=false;
                  }
                }
              }
            };


              $scope.additionalfilms1 = function ()
             {
                
                
              $scope.additional_film_selected_id = "";
              for (var j = 0; j < $scope.additionalfilm.length; j++) 
              {
                if ($scope.additionalfilm[j].checked === true) 
                {
                    //alert($scope.additionalfilm[j].id);
                    if($scope.additionalfilm[j].id===6)
                     {

                       $('#txt_additional_film_other1').prop('disabled', false);  

                     } 
                    
                    else if($scope.additionalfilm[j].id===7)
                     {
                       $('#txt_additional_film_other2').prop('disabled', false);  
                     } 
                     else
                     {
                         $('#txt_additional_film_other1').val('');
                         $('#txt_additional_film_other2').val('');
                         $('#txt_additional_film_other1').prop('disabled', true); 
                        $('#txt_additional_film_other2').prop('disabled', true);  
                     }  
                if ($scope.additional_film_selected_id === "") 
                  {
                   $scope.additional_film_selected_id = $scope.additionalfilm[j].value;
                  } 
                  else 
                  {
                   $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                  }
                }
              }
              
            };

         

  
               

           $scope.budgets_new = function ()
             {
                 
              $scope.budgetSelected = "";
              for (var i = 0; i < $scope.yourbudget.length; i++) 
              {
                if ($scope.yourbudget[i].checked === true) 
                {
                   // alert($scope.yourbudget[k].id);
                    if($scope.yourbudget[i].id===5)
                     {
                       $('#txt_budget_other').prop('disabled', false);  
                     } 
                     else
                     {
                        $('#txt_budget_other').prop('disabled', true);  
                     }  
                if ($scope.budgetSelected === "") 
                  {
                   $scope.budgetSelected = $scope.yourbudget[i].value;
                  // $scope.type_function_selected_id='1';
                   //$("input[name='type_function_selected_id']").val($scope.budgetSelected).trigger('input');
                   //$('#selectedDueDate').val(dateText).trigger('input');
                  } 
                  else 
                  {
                   $scope.budgetSelected = $scope.budgetSelected + "," + $scope.yourbudget[i].value;
                   //$("input[name='type_function_selected_id']").val($scope.budgetSelected);
                   //$("input[name='type_function_selected_id']").val($scope.budgetSelected).trigger('input');
                    //$scope.type_function_selected_id='12';
                  }
                }
              }
             // alert($scope.budgetSelected);
         };



$scope.videorequestFormSubmit = function() {
            
       $scope.serviceCreate.videography_request_id = $("input[name='videography_request_id']").val();
       $scope.serviceCreate.videography_location = $("input[name='videography_location']").val();
       $scope.serviceCreate.videography_no_guests = $("input[name='videography_no_guests']").val();
        $scope.serviceCreate.maximum_budget = $("input[name='maximum_budget']").val();
       

       $scope.serviceCreate.type_function_selected_id = $("input[name='type_function_selected_id']").val();
       $scope.serviceCreate.additional_film_selected_id = $("input[name='additional_film_selected_id']").val();
       $scope.serviceCreate.budgets_selected_id = $("input[name='budgets_selected_id']").val();
        $scope.serviceCreate.txt_additional_film_other1 = $("input[name='txt_additional_film_other1']").val();
        $scope.serviceCreate.txt_additional_film_other2 = $("input[name='txt_additional_film_other2']").val();

        $scope.serviceCreate.other_type_video = $("input[name='other_type_video']").val();
        $scope.serviceCreate.txt_budget_other = $("input[name='txt_budget_other']").val();
        $scope.serviceCreate.maximum_budget = $("input[name='maximum_budget']").val();

        

        



        var videoRequestForm = BrideRequestService.saveRequest($scope.serviceCreate);


        console.log(videoRequestForm);

            videoRequestForm.success(function(response) {

              NotificationService.serverAlert(response.message.title, response.message.body);
              location.href = 'brides/requests';

            }).error(function(response) {

                //  _.each(response, function(errors, key) {
                //      _.each(errors, function(e) {
                //        $scope.registrationForm[key].$dirty = true;
                //        $scope.registrationForm[key].$setValidity(key, false);
                //        $scope.errorMessages[key] = e;
                //      });
                //  });

            });

        };
});
   
angular.module('app.controllers').filter('dateformats', function ()
{
return function (item) {
var t = item.split(/[- :]/);
var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
var time = d.getTime();
return time;
};
});




})();

jQuery(document).ready(function(){
      $('body').on('click','#start-date', function () {
      $(this).datetimepicker({
              format: 'MM/DD/YYYY',
              minDate: new Date(),
              
              defaultDate: new Date(),
      });//maxDate: someDate,
    });

$('body').on('keyup','#number_only',function(e){
    var value = $(this).val();
      value = value.replace(/^(0*)/,"");
      $(this).val(value);
  if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
     this.value = this.value.replace(/[^0-9\.]/g, '');
  }
});
});
