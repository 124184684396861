(function(){
    "use strict";
    angular.module('app.controllers').controller('InviteFriendsController', function( $scope,$state, $http,UsersInvites,AuthService,NotificationService,$stateParams,$window ){


          
          
		$scope.invitefriendsForm = {};
		$scope.errorMessages = {};

			UsersInvites.invitesCount().success(function(response) {

			$scope.invitescount = response;
//<!-- popeye fixed invite friend flickering issue -->

          jQuery(".invitefriendsss").removeClass("temphide");

			});

			UsersInvites.invitesList().success(function(response) 
      {
			$scope.usersinvits = response;
			});

      UsersInvites.inviteuserid().success(function(response) 
      {
      $scope.invitesuser = response;
      });
					

		$scope.reinviteclick = function(name,email){

			 var formDatas = {
			  reinvite_name: $scope.reinvite_name = $("input[name='reinvite_name']").val(),
			  reinvite_email:$scope.reinvite_email = $("input[name='reinvite_email']").val(),
			};

			var formData = {
			  reinvite_name: name,
			  reinvite_email:email,
			};

			$http({

            url: "users/reinvitefriendssave",
            data: formData,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

            }).success(function(response){

            NotificationService.serverNotificationRedirectSamePage(response.message.title,response.message.body);

            }).error(function(response) {

                
           });
		};

 
        $scope.invitefriendsSubmit = function(){
        	
         var formData = {

          invite_name1: $scope.invite_name1 = $("input[name='invite_name1']").val(),
          invite_email1:$scope.invite_email1 = $("input[name='invite_email1']").val(),
          invite_name2: $scope.invite_name2 = $("input[name='invite_name2']").val(),
          invite_email2:$scope.invite_email2 = $("input[name='invite_email2']").val(),
          invite_name3: $scope.invite_name3 = $("input[name='invite_name3']").val(),
          invite_email3:$scope.invite_email3 = $("input[name='invite_email3']").val()
          // invite_name4: $scope.invite_name4 = $("input[name='invite_name4']").val(),
          // invite_email4:$scope.invite_email4 = $("input[name='invite_email4']").val(),
          // invite_name5: $scope.invite_name5 = $("input[name='invite_name5']").val(),
          // invite_email5:$scope.invite_email5 = $("input[name='invite_email5']").val(),
          // invite_name6: $scope.invite_name6 = $("input[name='invite_name6']").val(),
          // invite_email6:$scope.invite_email6 = $("input[name='invite_email6']").val(),
          // invite_name7: $scope.invite_name7 = $("input[name='invite_name7']").val(),
          // invite_email7:$scope.invite_email7 = $("input[name='invite_email7']").val(),
          // invite_name8: $scope.invite_name8 = $("input[name='invite_name8']").val(),
          // invite_email8:$scope.invite_email8 = $("input[name='invite_email8']").val(),
          // invite_name9: $scope.invite_name9 = $("input[name='invite_name9']").val(),
          // invite_email9:$scope.invite_email9 = $("input[name='invite_email9']").val(),
          // invite_name10: $scope.invite_name10 = $("input[name='invite_name10']").val(),
          // invite_email10:$scope.invite_email10 = $("input[name='invite_email10']").val()

        };

          $http({

            url: "users/invitefriendssave",
            data: formData,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

            }).success(function(response){

            NotificationService.serverNotificationRedirectSamePage(response.message.title,response.message.body);

            }).error(function(response) {

                _.each(response, function(errors, key) {
                    _.each(errors, function(e) {
                      $scope.inviteFriendsForm[key].$dirty = true;
                      $scope.inviteFriendsForm[key].$setValidity(key, false);
                      $scope.errorMessages[key] = e;
                    });
                });
               
           });
        };



    });

 angular.module('app.controllers').filter('dateformat', function ()
          {
          return function (item) {
            var t = item.split(/[- :]/);
            var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
            var time = d.getTime(); 
            return time;
          };
          });


})();







