 (function() {
    "use strict";
angular.module('app.controllers').controller('CategoryListController', function($scope,$location,$http, VendorsProfileService) {

 VendorsProfileService.getAllServiceCategories().success(function(response) {
           
            $scope.allCategories = response;
             
        });
 $scope.test = function (event) {
   
   sessionStorage.selectedCategories = event.target.id;
   sessionStorage.locations='';
  // sessionStorage.selectedCategories ='';
   sessionStorage.lat='';
   sessionStorage.lng='';
   location.href = '/pages/findvendorslists';
   /*
   var currentPageTemplate = $route.current.templateUrl;
   alert(currentPageTemplate);
$templateCache.remove(currentPageTemplate);
$route.reload();
   //$window.location.reload();
   //$window.location.href = '/pages/findvendorslists';   
   */
 };
 });

})();