(function() {
//Data
angular.module('app.controllers').controller('ActiveLeadsMapCtrl', function ($scope,$http) {
    


	 $scope.selectedCategories ='';
// This is the minimum zoom level that we'll allow
 var minZoomLevel = 5;
        var formData = {
        selectedCategories:  $scope.selectedCategories,
        };
        $http({
        url: "vendors/servicerequestsmap",
        data: formData,
        method: 'POST',
        }).success(function(response){
        $scope.activeleads = response;
        var mapOptions = {
        zoom: minZoomLevel,
        center: new google.maps.LatLng($scope.activeleads[0].latitude, $scope.activeleads[0].longitude),
        mapTypeId: google.maps.MapTypeId.TERRAIN
        };
        $scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);
        $scope.markers = [];
        var infoWindow = new google.maps.InfoWindow();
        var createMarker = function (info){
        var marker = new google.maps.Marker({
        map: $scope.map,
        position: new google.maps.LatLng(info.latitude, info.longitude),
        title: info.type,
        icon: "images/activeleadsmap.png"
        });
        marker.content = '<div class="infoWindowContent">' + info.name + '</div>';
        google.maps.event.addListener(marker, 'click', function(){
        infoWindow.setContent('<h2>' + marker.title + '</h2>' + marker.content);
        infoWindow.open($scope.map, marker);
        });
        $scope.markers.push(marker);
        };
        var createMarker1 = function (info){
        var marker1 = new google.maps.Marker({
        map: $scope.map,
        position: new google.maps.LatLng(info.latitude, info.longitude),
        title: info.type,
        icon: "images/map_yellow.png"
        });
        marker1.content = '<div class="infoWindowContent">' + info.name + '</div>';
        google.maps.event.addListener(marker1, 'click', function(){
        infoWindow.setContent('<h2>' + marker1.title + '</h2>' + marker1.content);
        infoWindow.open($scope.map, marker1);
        });
        $scope.markers.push(marker1);
        };  
        for (i = 1; i < $scope.activeleads.length; i++){
        createMarker($scope.activeleads[i]);
        }
        createMarker1($scope.activeleads[0]);
        $scope.openInfoWindow = function(e, selectedMarker){
        e.preventDefault();
        google.maps.event.trigger(selectedMarker, 'click');
        };
        });   

 // Bounds for North America
 var strictBounds = new google.maps.LatLngBounds(
 new google.maps.LatLng(28.70, -127.50),
 new google.maps.LatLng(48.85, -55.90));

 // Listen for the dragend event
 google.maps.event.addListener(map, 'dragend', function () {
     if (strictBounds.contains(map.getCenter())) return;

     // We're out of bounds - Move the map back within the bounds

     var c = map.getCenter(),
         x = c.lng(),
         y = c.lat(),
         maxX = strictBounds.getNorthEast().lng(),
         maxY = strictBounds.getNorthEast().lat(),
         minX = strictBounds.getSouthWest().lng(),
         minY = strictBounds.getSouthWest().lat();

     if (x < minX) x = minX;
     if (x > maxX) x = maxX;
     if (y < minY) y = minY;
     if (y > maxY) y = maxY;

     map.setCenter(new google.maps.LatLng(y, x));
 });
});
 
})();

