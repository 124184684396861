(function() {
    "use strict";

    var services = angular.module('app.services', []);

    services.factory('EventCreateService', function($http) {

        return {

            create: function(event) {
                var eventCreateRequest = $http({
                    method: 'POST',
                    url: '/brides/events',
                    data: event
                });
                return eventCreateRequest;
            },

     };

    });

    services.factory('AuthService', function($http) {

        return {

            login: function(user) {
                var loginRequest = $http({
                    method: 'POST',
                    url: '/login',
                    data: user
                });
                return loginRequest;
            },

            register: function(user) {
                var registerRequest = $http({
                    method: 'POST',
                    url: '/register',
                    data: user
                });
                return registerRequest;
            },

            socialRegistration: function(user) {
                return $http({
                    method: 'POST',
                    url: '/api/users/roles',
                    data: user
                });
            },

            destroy: function() {
                return $http.get('/logout');
            },

            forgetPassword: function(user) {
                var forgetPasswordRequest = $http({
                    method: 'POST',
                    url: '/password/forget',
                    data: user
                });
                return forgetPasswordRequest;
            },
             adminforgetPassword: function(user) {
                var adminforgetPasswordRequest = $http({
                    method: 'POST',
                    url: '/admin/password/forget',
                    data: user
                });
                return adminforgetPasswordRequest;
            },

            resetPassword: function(user) {
                var resetPasswordRequest = $http({
                    method: 'POST',
                    url: '/password/reset',
                    data: user
                });
                return resetPasswordRequest;
            },

            Activateaccount: function(token) {
                return $http({
                    method: 'GET',
                    url: '/users/activatestatus',
                    });
            },

            Activatestatus: function(token) {
                return $http({
                    method: 'GET',
                    url: '/users/activate/'+token,
                    });
            },

            admin: function(user) {
                var adminloginRequest = $http({
                    method: 'POST',
                    url: '/admin/',
                    data: user
                });
                return adminloginRequest;
            },

              adminSlash: function(user) {
                var adminloginRequest = $http({
                    method: 'POST',
                    url: '/admin',
                    data: user
                });
                return adminloginRequest;
            },


            socialProvider: function() {
                return $http({
                    method: 'GET',
                    url: '/usersprovider',

                });
            },

            allNotifications: function() {
                return $http({
                    method: 'GET',
                    url: '/users/notifications',

                });
            },
	    
	    websitesession: function() {
                return $http({
                    method: 'GET',
                    url: '/users/websession',

                });
            },

            loggedInStatus: function() {
                return $http({
                    method: 'GET',
                    url: '/users/loggedInStatus',

                });
            },

       resetemaillink: function() {
                return $http({
                    method: 'POST',
                    url: '/users/resetemail',

                });
            },


            
        };

    });

    services.factory('NotificationService', function($http, $mdToast, $mdDialog, $window) {

        return {

            serverAlert: function(title, body) {

                var alert = $mdDialog.alert({
                    title: title,
                    content: body,
                    ok: 'OKAY'
                });
                $mdDialog
                    .show(alert)
                    .finally(function() {
                        $window.location = '/';
                    });

            },


            AccpetBidAlert: function(title, body,id) {

                var alert = $mdDialog.alert({
                    title: title,
                    content: body,
                    ok: 'OKAY'
                });
                $mdDialog
                    .show(alert)
                    .finally(function() {
                        $window.location = '/brides/bridesservices/'+id;
                    });

            },



            eventAlert: function(title, body) {

                var alert = $mdDialog.alert({
                    title: title,
                    content: body,
                    ok: 'OKAY'
                });
                $mdDialog
                    .show(alert)
                    .finally(function() {
                        $window.location = '/brides/dashboard';
                    });

            },
            // serverConfirm: function(ev, board) {
            //
            //   var confirm = $mdDialog.confirm()
            //       .title('WARNING')
            //       .content('This Action Will Result in the Deletion of this Task List and All Related Tasks, Proceed At Your Own Risk')
            //       .ok('Proceed')
            //       .cancel('Cancel')
            //       .targetEvent(ev);
            //     $mdDialog.show(confirm).then(function() {
            //           $window.location = '/';
            //         }, function() {
            //         //$scope.dialogClose();
            //         $scope.dialog(null, local[1]);
            //     });
            //
            // },

            serverNotification: function(title, body) {

                var toast = $mdToast.simple()
                    .content(body)
                    .hideDelay(3000)
                    .position('bottom right')
                    .action('X')
                    .highlightAction(true);

                $mdToast.show(toast);

                $window.location = '/';
            },

            serverNotificationRedirectSamePage: function(title, body) {

                 var alert = $mdDialog.alert({
                    title: title,
                    content: body,
                    ok: 'OKAY'
                });
                $mdDialog
                    .show(alert)
                    .finally(function() {
                         $window.location.reload();
                    });


            },


            serverNotificationNoRedirect: function(title, body) {

                var toast = $mdToast.simple()
                    .content(body)
                    .hideDelay(3000)
                    .position('bottom right')
                    .action('X')
                    .highlightAction(true);

                $mdToast.show(toast);
            },



        };

    });

    services.factory('CmsService', function($http, $mdToast, $mdDialog, $window) {

        return {

             gethomecurrentvendorid: function() {
                return $http({
                    method: 'post',
                    url: 'pages/homecurrentvid',
                });
            },

            getAllPageSulgs: function() {
                return $http({
                    method: 'get',
                    url: 'api/pages'
                });

            },

            getPage: function(page) {


                return $http({
                    method: 'get',
                    url: 'api/pages/' + page
                });

            },

              getPageMobile: function(page) {


                return $http({
                    method: 'get',
                    url: 'api/mobilepages/' + page
                });

            },
            
            getfindvendors: function() {
                return $http({
                    method: 'post',
                    url: 'pages/findvendorslists'
                });
            },
             getcontactus: function() {
                return $http({
                    method: 'post',
                    url: 'pages/contact-us'
                });
            },
              getreportus: function() {
                return $http({
                    method: 'post',
                    url: 'pages/report-problem'
                });
            },

              getfindservicecategories: function(id) {

                return $http({
                    method: 'get',
                    url: 'brides/requests/'+id
                });
            },

            
             getactivebidsmap: function() {
               // alert('1');
                return $http({
                    method: 'get',
                    url: 'vendors/activebidsmap'
                });
            },

             findwonbidsmap: function() {
                //alert('1');
                return $http({
                    method: 'post',
                    url: 'vendors/wonbidsmap'
                });
            },

             findlostbidsmap: function() {
                
                return $http({
                    method: 'post',
                    url: 'vendors/lostbidsmap'
                });
            },
            
            getfindservicecategoriesmap: function() {
                //alert('1');
                return $http({
                    method: 'get',
                    url: 'vendors/servicerequestsmap'
                });
            },


            getfindvendorsdetails: function(id) {
             //   id=5;
                return $http({
                    method: 'get',
                    url: '/pages/findvendorsdetails/'+id
                });
            },

        };

    });

    services.factory('ServiceLocations', function($q,$http, $mdToast, $mdDialog, $window) {

        return {

            getAllState: function() {
                return $http({
                    method: 'get',
                    url: 'site/pages'
                });
            },
        };

    });

    services.factory('VendorsProfileService', function($http) {

        return {

            getcurrentvendorid: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/currentvid',
                });
            },

              getCountMessages: function(id) {
                    return $http({
                        method: 'get',
                        url: '/vendors/getmessagecount/'+ id,
                    });
                },


	   getpaymentSettings: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/paymentsettings',
                });
            },            


             getcurrentdate: function() {
                return $http({
                    method: 'get',
                    url: '/vendors/currentdate',
                });
            },

            getAllServiceCategories: function() {
                return $http({
                    method: 'GET',
                    url: '/api/services/categories',
                });
            },

            getAvaliableServicecategories: function(id) {
                return $http({
                    method: 'GET',
                    url: '/api/services/avaliablecategories/'+ id,
                });
            },

             getAvaliableServicecategoriesUpdate: function(id) {
                return $http({
                    method: 'GET',
                    url: '/api/services/updateavaliablecategories/'+ id,
                });
            },


            saveServiceCategories: function(vendor) {
                return $http({
                    method: 'POST',
                    url: '/vendors/services/categories',
                    data: vendor,
                });
            },

            saveProfile: function(vendor) {
                return $http({
                    method: 'POST',
                    url: '/vendors/profile',
                    data: vendor,
                });
            },

            editProfile: function(vendor) {
                return $http({
                    method: 'POST',
                    url: '/vendors/myprofile/edit',

                });
            },

            saveServiceImage: function(id,vendor) {
                return $http({
                    method: 'POST',
                    url: '/vendors/profile/images/add/'+id,
                    data: vendor,
                });
            },

            getAllServiceImages: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/profile/images/show',
                });
            },

            removeServiceImages: function(id) {
                return $http({
                    method: 'post',
                    url: '/vendors/profile/images/deletephoto/' + id,
                });
            },

            getAllServiceImagesCount: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/profile/images/showcount',
                });
            },


             getAllServiceImagesCount1: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/profile/images/showcount',
                });
            },

            getAllServiceRequests: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/requests',
                });
            },

            getAllPlacedServiceRequests: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/placedbids',
                });
            },

            getAllWonServiceRequests: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/acceptedbids',
                });
            },

            getAllLostServiceRequests: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/lostbids',
                });
            },



            getServiceRequestDetails: function(id) {
                return $http({
                    method: 'GET',
                    url: '/vendors/requests/' + id,
                });
            },

            getServiceRequestAcceptDetails: function(id) {
                return $http({
                    method: 'GET',
                    url: '/vendors/requests/accept/' + id,
                });
            },

            getServiceRequestPlacedDetails: function(id) {
                return $http({
                    method: 'GET',
                    url: '/vendors/requests/placed/' + id,
                });
            },

            getServiceRequestLostDetails: function(id) {
                return $http({
                    method: 'GET',
                    url: '/vendors/requests/lost/' + id,
                });
            },

            getServiceRequestBestoffer: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/bestoffers',
                });
            },

            getServiceRequestLostoffer: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/lostoffers',
                });
            },

            getallPlacedDetails: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/placedbidslist',
                });
            },

            getServiceRequestMiniumDetails: function(id) {
                return $http({
                    method: 'GET',
                    url: '/vendors/requests/miniumrequest/' + id,
                });
            },

            getServiceFeeDetails: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/profile/showfee',
                });
            },

            biddingCancelled: function(id) {
                return $http({
                    method: 'GET',
                    url: '/vendors/requests/' + id + '/biddings/cancelled',
                });
            },


            biddingSuccessfull: function() {
                return $http({
                    method: 'GET',
                    url: '/api/payment/successfull/message',
                });
            },

            showmyProfile: function(id) {
                return $http({
                    method: 'GET',
                    url: '/vendors/myprofile',
                });
            },

            selectedserviceslist: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/myprofile/selectedservice',
                });
            },

            selectedservicesarea: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/myprofile/selectedservicearea',
                });
            },

            resetaccountinfo: function(vendor_account_details) {
                var resetRequest = $http({
                    method: 'POST',
                    url: '/vendors/myprofile/accountinfo',
                    data: vendor_account_details
                });
                return resetRequest;
            },

            notificationsinfo: function(vendor) {
                return $http({
                    method: 'POST',
                    url: '/vendors/activeleadcounts',
                });
            },

            getActiveBiddingcount: function(id) {
                return $http({
                    method: 'GET',
                    url: '/vendors/activeleadcounts/' + id,
                });
            },

            getMessagesNotifications: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/messagenotifications',
                });
            },


            
              getServiceRequestIdVendor: function(id) {
                return $http({
                    method: 'GET',
                    url: '/vendors/requestsid/' + id,
                });
            },


             getfindvendors: function() {

                return $http({
                    method: 'get',
                    url: '/site/pages/findvendorsresults',
                });
            },

            getdashboardvendors: function() {

                return $http({
                    method: 'get',
                    url: '/vendors/dashboardvalues',
                });
            },
 

};
    });
                              
                            
services.factory('BridesDashboardService', function($http) {

        return {

            getdashboardbrides: function() {
                return $http({
                    method: 'GET',
                    url: '/brides/dashboard',
                });
            },

            getdashboardevent: function(id) {
                 return $http({
                     method: 'GET',
                     url: '/brides/bridesservices/'+ id,
                 });
             },


        };



    });                         

 services.factory('BridesProfileService', function($http) {

        return {

            
    geteventnamedetail: function(id) {
                    return $http({
                        method: 'get',
                        url: '/brides/geteventname/'+ id,
                    });
                },

                 getCountMessage: function(id) {
                    return $http({
                        method: 'get',
                        url: '/brides/getmessagecount/'+ id,
                    });
                },

   bridephone: function() {
                    return $http({
                        method: 'get',
                        url: '/brides/bridephone',
                    });
                },

eventcountdetails: function() {
                    return $http({
                        method: 'get',
                        url: '/brides/eventcount',
                    });
                },

   getServiceRequestExp: function(id) {
                return $http({
                    method: 'get',
                    url: '/brides/getexpdetails/'+ id,
                });
            },

            getcurrentdates: function() {
                return $http({
                    method: 'get',
                    url: '/brides/currentdates',
                });
            },
   getuserdetails: function() {
            return $http({
            method: 'GET',
            url: '/brides/userdetails',
            });
            },


            getAllServiceRequests: function(id,eid) {
                return $http({
                    method: 'GET',
                    url: '/brides/requests/'+ id + '/'+ eid,
                });
            },

            getAcceptedRequests: function(id) {
                return $http({
                    method: 'GET',
                    url: '/brides/requests/accepted/'+ id,
                });
            },

            getServiceRequestDetails: function(id) {
                return $http({
                    method: 'GET',
                    url: '/brides/requests/' + id,
                });
            },

              getServiceRequestId: function(id) {
                return $http({
                    method: 'GET',
                    url: '/brides/requestsid/' + id,
                });
            },


            getacceptedRequestDetails: function(id,eid) {
                return $http({
                    method: 'GET',
                    url: '/brides/requests/accepted/' + id +'/'+eid,
                });
            },

            acceptBid: function(id) {
                return $http({
                    method: 'GET',
                    url: '/api/brides/services/requests/biddings/' + id + '/accept',
                });
            },

            getServiceRequestMiniumDetailsBride: function(id) {
                return $http({
                    method: 'GET',
                    url: '/brides/miniumrequest/' + id,
                });
            },
            serviceRequestMiniumDetailsBride: function(id) {
                return $http({
                    method: 'GET',
                    url: '/brides/miniumrequest/' + id,
                });
            },
            getServiceRequestBestoffer: function(id) {
                return $http({
                    method: 'GET',
                    url: '/brides/bestoffers/'+id,
                });
            },

            getServiceRequestlowestBidslist: function() {
                return $http({
                    method: 'GET',
                    url: '/brides/lowestbidlists/',
                });
            },

            selectedserviceslist: function() {
                return $http({
                    method: 'GET',
                    url: '/brides/selectedservice',
                });
            },

            geteventdetails: function(id) {
                return $http({
                method: 'GET',
                url: '/brides/eventdetails/' + id,
                });
            },

             getexpire_date: function() {
                return $http({
                method: 'GET',
                url: '/brides/expiredetails',
                });
            },
            
            brideEventStatus: function() {
                return $http({
                    method: 'GET',
                    url: '/users/eventexpirystatus',

                });
            },

            bridemyevent: function(id) {
                return $http({
                    method: 'GET',
                    url: '/brides/event/update/'+id,
                });
            },


            currentEventid: function() {
                            return $http({
                                method: 'GET',
                                url: '/brides/currentEventid',

                            });
                        },

            avaliablecategoriesid: function() {
                return $http({
                    method: 'GET',
                    url: '/brides/services/requests/updatepostid/',
                    //data: serviceid,
                });
            },

            avaliableservicecategories: function(id) {
                return $http({
                    method: 'POST',
                    url: '/brides/services/requests/updatepost/',
                    data: id,
                });
            },



            bridemyProfile: function() {
                return $http({
                    method: 'GET',
                    url: '/brides/profile',
                });
            },

             editBrideProfile: function(bride) {
                return $http({
                    method: 'POST',
                    url: '/brides/profile/edit',

                });
            },

            // getdashboardbrides: function() {
            //                return $http({
            //                    method: 'get',
            //                    url: '/brides/dashboard',
            //                });
            //            },
            
             getServiceCatId: function() {
                         return $http({
                             method: 'POST',
                             url: '/brides/services/requests/update',
                         });
                     },


        };

    });

    services.factory('BrideRequestService', function($http) {

        return {

            saveRequest: function(serviceCreate,url6) {
                return $http({
                    method: 'POST',
                    url: '/brides/requests/store/'+url6,
                    data: serviceCreate,
                });
            },

            };

    });


    services.factory('UsersInvites', function($http) {

        return {

            invitesList: function() {
                return $http({
                    method: 'GET',
                    url: '/users/inviteslist',
                });
            },

                 inviteuserid: function() {
                return $http({
                    method: 'GET',
                    url: '/users/inviteuserid',
                });
            },

             invitesCount: function() {
                return $http({
                    method: 'GET',
                    url: '/users/invitescount',
                });
            },

           getMessagesNotifications: function() {
                return $http({
                    method: 'GET',
                    url: '/users/messagenotifications',
                });
            },


           getMessageHistory: function(id,service_id) {

                return $http({
                    method: 'GET',
                    url: '/users/messagehistory/'+id+'/'+service_id,
                });
            },


        };

    });

    services.factory('TopNotification', function($http) {

        return {

           getMessagesNotifications: function() {
                return $http({
                    method: 'GET',
                    url: '/vendors/messagenotifications',
                });
            },


        };

    });

})();


