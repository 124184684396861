(function() {
    "use strict";

    angular.module('app.controllers').controller('VendorsServiceRequestsController', function($scope, $state, $stateParams, VendorsProfileService, NotificationService) {

        $scope.serviceRequests = {};
        $scope.allCategories = {};
        var values = {};
//console.log($stateParams.id);
        VendorsProfileService.getAllServiceRequests().success(function(response) {
            $scope.serviceRequests = response;
            
            $scope.serviceRequestsCount = $scope.serviceRequests.length;
                       
           // console.log($scope.serviceRequests_count);

        });

        VendorsProfileService.selectedserviceslist().success(function(response) {
        $scope.allCategories = response;
        });

        VendorsProfileService.getcurrentvendorid().success(function(response) {

            $scope.currentvid = response;

            });

        // VendorsProfileService.getActiveBiddingcount($stateParams.id).success(function(response) {
        //     $scope.biddingcount = response;
        //     //console.log($scope.serviceRequests);
        // });

        // VendorsProfileService.getallPlacedDetails().success(function(response) {
        //     $scope.placedbibsdetails = response;

        // });



  
 $scope.showData = function( ){

 $scope.itemsPerPage = 5;
 $scope.currentPage = 0;
     $scope.datalists =  $scope.serviceRequests;
     $scope.range = function() {
    var rangeSize = 5;
    var ps = [];
    var start;

    start = $scope.currentPage;
       //  console.log($scope.pageCount(),$scope.currentPage)
    if ( start > $scope.pageCount()-rangeSize ) {
      start = $scope.pageCount()-rangeSize+1;
    }

    for (var i=start; i<start+rangeSize; i++) {
      if(i>=0) 
         ps.push(i);
    }
    return ps;
  };

  $scope.prevPage = function() {
    if ($scope.currentPage > 0) {
      $scope.currentPage--;
    }
  };

  $scope.DisablePrevPage = function() {
    return $scope.currentPage === 0 ? "disabled" : "";
  };

  $scope.pageCount = function() {
    return Math.ceil($scope.datalists.length/$scope.itemsPerPage)-1;
  };

  $scope.nextPage = function() {
    if ($scope.currentPage < $scope.pageCount()) {
      $scope.currentPage++;
    }
  };

  $scope.DisableNextPage = function() {
    return $scope.currentPage === $scope.pageCount() ? "disabled" : "";
  };

  $scope.setPage = function(n) {
    $scope.currentPage = n;
  };
         
};

			
    });

 angular.module('app.controllers').filter('format', function () {
          return function (item) {
           
           var t = item.split(/[- :]/);
           var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
           var time = d.getTime(); 
           
            var nextDay = new Date(d);
            nextDay.setDate(d.getDate()+15);
            return nextDay;
          };
        });

})();

angular.module('app.controllers').filter('pagination', function() {
    return function(input, start) {
        if (!input || !input.length) { return; }
        start = +start; //parse to int
        return input.slice(start);
    };
});




 
