(function(){
    "use strict";

angular.module('app.controllers').controller('BridesdashboardController', function( $rootScope,$scope, BridesDashboardService,BridesProfileService ){

    BridesDashboardService.getdashboardbrides().success(function(response) 
    {

        $scope.bridesdashboardValues  = response;
            if(!$scope.bridesdashboardValues.logged_user)
                window.location.reload();

                setTimeout(function(){
                jQuery('.DashBoard').css('display','');
                jQuery('.BreadCrumbSec').css('display','');

            }, 1000);
        //                          jQuery('.ProfileTabHeader').css('display','');
        jQuery('.post-btn').css('display','');

    });



    BridesProfileService.getcurrentdates().success(function(response) {

        $scope.currentdate = response;

    });


   
    $scope.deleterecordEvent = function(data)
    {
        var val = data;
        var csrfToken = document.getElementById('csrf-token').value;

        if (confirm("Are you sure want to delete?")) {
            $.ajax({
            type: "POST",
            url: "brides/services/requests/deleteevent",
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
            beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
            },
            data: { services_id : val },
            success:function(campaigns)
            {
            location.reload();
            }
            });
        }

        return false;

    };

    $scope.showDetails = function(data) 
    {

        var val = data;
        var csrfToken = document.getElementById('csrf-token').value;
            $.ajax({
            type: "POST",
            url: "brides/services/requests/updatepost",
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
            beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
            },
            data: { services_id : val},
            success:function(campaigns)
            {
            // $("#campaign").html(campaigns);
            }
            });
    }; 
});

})();
