(function() {
    "use strict";

    angular.module('app.controllers').controller('BridesphotoRequestController', function($scope, $state, VendorsProfileService, BrideRequestService,BridesProfileService, NotificationService, $mdDialog) {
          
        $scope.allCategories = {};

        $scope.serviceCreate = {};
        
    $scope.serviceRequest = {};

    if($scope.ServiceCategorieDetails.service_request_id.length !== 0)
    {

    BridesProfileService.getServiceRequestDetails($scope.ServiceCategorieDetails.service_request_id)
    .success(function(response) {
    $scope.serviceRequest = response;
    });
    }

else
{
  $scope.serviceRequest.biddings =[];

}
     

        $scope.shootat = [{
            'pos':'0',
            'id':'1',
            'name':'Ceremony'
        },{
            'pos':'1',
            'id':'2',
            'name':'Reception'
        }];
        

        $scope.cList = [];
        var path = window.location.pathname;
        var str = path.split("/");

        var url5 =  str[5];
        var url6 =  str[6];

         BridesProfileService.geteventdetails(url6).success(function(response)
        {
          $scope.event_details = response;
// popeye - angular expression display issue 

        jQuery(".PhotoServiceForm").removeClass("temphide");

        });

        BridesProfileService.geteventdetails(url6).success(function(response)
        {
          $scope.event_details = response;

          var someDate = new Date();
           

            $("#start-dates").on("dp.show", function (e) {
              $('#start-dates').data("DateTimePicker").minDate(someDate);
            });
            $('#start-dates').datetimepicker({
                format:"MM/DD/YYYY",                
                disabledDates:[someDate],
                ignoreReadonly: true,
              maxDate: $scope.event_details.start_dates,
                widgetPositioning: {
                    horizontal: 'left',
                    vertical: 'bottom'
                }
            });
        });
        // VendorsProfileService.getAvaliableServicecategories().success(function(response) {
        //   $scope.allCategories = response;
        // });

        $scope.bridephotoRequestFormSubmit = function() {
       
           //$scope.serviceCreate = {};
        $scope.serviceCreate.service_category_id = $("input[name='selectedCategories']").val();
        $scope.serviceCreate.ends_at = $("input[name='request_expdate']").val();
        $scope.serviceCreate.photography_number_hours = $scope.ServiceCategorieDetails.photography_number_hours;
        $scope.serviceCreate.txtphotography_number_hours = $("input[name='txtphotography_number_hours']").val();
        $scope.serviceCreate.shootat = $("input[name='shootat']").val();
        $scope.serviceCreate.txtshootat = $("input[name='txtshootat']").val();
        $scope.serviceCreate.txtshootat_distance = $("input[name='txtshootat_distance']").val();
        $scope.serviceCreate.shoot_date = $("input[name='shoot_date']").val();
        $scope.serviceCreate.shoot_location = $("input[name='shoot_location']").val();
        $scope.serviceCreate.shoot_hours = $("input[name='shoot_hours']").val();
        $scope.serviceCreate.album_required=$scope.ServiceCategorieDetails.album_required;
        $scope.serviceCreate.album_count=$scope.ServiceCategorieDetails.album_count;
        $scope.serviceCreate.album_morepages = $("input[name='album_morepages']").val();
        $scope.serviceCreate.shoot_engagement = $scope.ServiceCategorieDetails.shoot_engagement;
        $scope.serviceCreate.event_id = $("input[name='event_id']").val();
        $scope.serviceCreate.maximum_budget = $("input[name='maximum_budget']").val();
        
        //$scope.serviceCreate.maxmimum_budget=$scope.ServiceCategorieDetails.minimum_budget;

 var confirm = $mdDialog.confirm()
            .parent(angular.element(document.body))
            .title('Attention')
            .content('Please click OKAY to post this service request or click CANCEL to review it again.')
            .ariaLabel('Attention')
            .ok('Okay')
            .cancel('Cancel')
            .hasBackdrop(false);

            $mdDialog.show(confirm).then(function() {

        var bridephotoRequestForm = BrideRequestService.saveRequest($scope.serviceCreate,url6);
        bridephotoRequestForm.success(function(response) {
        // NotificationService.serverAlert(response.message.title, response.message.body);
        location.href = '/brides/requests/'+url6;

        }).error(function(response) {

            //  _.each(response, function(errors, key) {
            //      _.each(errors, function(e) {
            //        $scope.registrationForm[key].$dirty = true;
            //        $scope.registrationForm[key].$setValidity(key, false);
            //        $scope.errorMessages[key] = e;
            //      });
            //  });

        });

         }, function() {

             // $window.location.reload();

            });

        };
 //        
//$scope.len=Object.keys($scope.ServiceCategorieDetails).length;
             
        $scope.gethours = function (id) {
   $scope.ServiceCategorieDetails.photography_number_hours={};          
            //$scope.ServiceCategorieDetails={};
   // alert(event.target.id);
   
   //alert($("#"+event.target.id).val());
      $scope.ServiceCategorieDetails.photography_number_hours=id;

   
 };
 $scope.getshootengagement = function (id) {
    $scope.ServiceCategorieDetails.shoot_engagement={};          
    // $scope.len=Object.keys($scope.ServiceCategorieDetails).length;
    //          alert($scope.len);
           // $scope.ServiceCategorieDetails={};
   //alert(event.target.id);
   
      $scope.ServiceCategorieDetails.shoot_engagement=id;

   
 };
 $scope.getalbumrequired = function (id) {
$scope.ServiceCategorieDetails.album_required={};          
    // $scope.len=Object.keys($scope.ServiceCategorieDetails).length;
    //          alert($scope.len);
            //$scope.ServiceCategorieDetails={};
   //alert(event.target.id);
   
      $scope.ServiceCategorieDetails.album_required=id;

   
 };
 $scope.getalbumcount = function (id) {
$scope.ServiceCategorieDetails.album_count={};          
      
      $scope.ServiceCategorieDetails.album_count=id;

   
 };

 

        $scope.upperRight = function ()
        {

            $scope.ServiceCategorieDetails.txtshootat = "";
            // $("input[name='type_function_selected_id']").val('');
            for (var i = 0; i < $scope.shootat.length; i++) 
            {

            if ($scope.shootat[i].checked === true) 
            {
            //alert($scope.shootat[i].id);

            if ($scope.ServiceCategorieDetails.txtshootat === "") 
            {
             $scope.ServiceCategorieDetails.txtshootat = $scope.shootat[i].id;
            } 
            else 
            {
             $scope.ServiceCategorieDetails.txtshootat = $scope.ServiceCategorieDetails.txtshootat + "," + $scope.shootat[i].id;
            }
            }
           
            }
        };
         
$scope.changeshoot=function(pos)
{
//alert($scope.shootat.length);
$scope.ServiceCategorieDetails.txtshootat = "";
 for (var i = 0; i < $scope.shootat.length; i++) 
            {
             if(i==pos)
             { 
            if($scope.shootat[pos].checked===true)
            {
               // alert('1');
       $scope.shootat[pos].checked=true;
            }   
            else
            {
           // alert($scope.shootat[pos].checked);    
            $scope.shootat[pos].checked=false;
            }
             }
             else
             {
             // alert($scope.ServiceCategorieDetails.user_id);
              if($scope.shootat[i].checked===undefined)
              {
                if($scope.ServiceCategorieDetails.user_id!=='')
                {
              $scope.shootat[i].checked=true;    
               }
               else
               {
                $scope.shootat[i].checked=false;
               }
              }
              else
              {
               if($scope.shootat[i].checked===true)
               {
             $scope.shootat[i].checked=true;
               }
               else
               { 
                $scope.shootat[i].checked=false;
            }
              }
              //$scope.shootat[i].checked=true;  
             }
             
            if ($scope.shootat[i].checked === true) 
            {
           // alert($scope.shootat[i].id);

            if ($scope.ServiceCategorieDetails.txtshootat === "") 
            {
             $scope.ServiceCategorieDetails.txtshootat = $scope.shootat[i].id;
            } 
            else 
            {
             $scope.ServiceCategorieDetails.txtshootat = $scope.ServiceCategorieDetails.txtshootat + "," + $scope.shootat[i].id;
            }
             }

  if($scope.ServiceCategorieDetails.txtshootat == '1')
             {
              $scope.ServiceCategorieDetails.txtshootat_distance = '0';
             }
        
             if($scope.ServiceCategorieDetails.txtshootat == '1,2')
             {
              $scope.ServiceCategorieDetails.txtshootat_distance = '';
             }
             // else
             // {
             //    alert(pos);
             // }
           
            }
};
        $scope.test=function()
        {

        // $("#start_date1").datetimepicker({
        // format: 'MM/DD/YYYY',
        // minDate: new Date(),
        // defaultDate: new Date(),
        // });
        $scope.shoot_dates=$("#shoot_date").val();
        //alert($scope.serviceCreate.shoot_date);
        //alert($("#shoot_date").val());
        setTimeout(function(){
           //   alert($("#shoot_date").val());
        if($("#shoot_date").val()==='')
         { 
          $scope.tests='Yes';  
        } 
        else
        {
          $scope.tests='no';  
        }
      $('#shoot_date').trigger('click');

          }, 3000);

         
        

        };
        
        $scope.$watch('txtshootat', function(mVal){
            if (angular.isUndefined($scope.form)) return; 

            if(mVal === 'other'){
            $scope.form.OtherProgram = $scope.tmVar;
            } else {
            if($scope.form.OtherProgram !== null){
            $scope.tmVar = $scope.form.OtherProgram;
            $scope.form.OtherProgram = null;
            }
            }
        });

        $scope.$watch('ServiceCategorieDetails.shoot_engagement', function(mVal){
              //alert(mVal);
            //$scope.tests='Yes'; 
            if(mVal==="Yes")
            {  
              //alert($scope.serviceCreate.shoot_date);
              if($("#shoot_date").val()==='')
              {
              $scope.tests='Yes';  
              }
              else
              {
               $scope.tests='no';   
              }  

            }
            if(mVal==="No")
            {  
            //$("#shoot_date").val('');
            $scope.tests='no';  
            }

            $scope.txt_shoot_engagement=mVal;

            if (angular.isUndefined($scope.form)) return; 

            if(mVal === 'other'){
            $scope.form.OtherProgram = $scope.tmVar;
            } else {
            if($scope.form.OtherProgram !== null){
            $scope.tmVar = $scope.form.OtherProgram;
            $scope.form.OtherProgram = null;
            }
            }
        });
        
     //  $scope.$watch('ServiceCategorieDetails.shoot_location', function(mVal){
           //  if($("#shoot_date").val()==='')
            //  {
           //   $scope.tests='Yes';  
            //  }
           //   else
           //   {
          //     $scope.tests='no';   
        //      }  

      //  });  
        $scope.$watch('serviceCreate.photography_number_hours', function(mVal){
            if (angular.isUndefined($scope.form)) return; 

            if(mVal === 'other'){
            $scope.form.OtherProgram = $scope.tmVar;
            } else {
            if($scope.form.OtherProgram !== null){
            $scope.tmVar = $scope.form.OtherProgram;
            $scope.form.OtherProgram = null;
            }
            }
        });
           
        $scope.$watch('serviceCreate.album_required', function(mVal){
            if (angular.isUndefined($scope.form)) return; 

            if(mVal === 'other'){
            $scope.form.OtherProgram = $scope.tmVar;
            } else {
            if($scope.form.OtherProgram !== null){
            $scope.tmVar = $scope.form.OtherProgram;
            $scope.form.OtherProgram = null;
            }
            }
        });
        $scope.$watch('serviceCreate.album_count', function(mVal){
            if (angular.isUndefined($scope.form)) return; 

            if(mVal === 'other'){
            $scope.form.OtherProgram = $scope.tmVar;
            } else {
            if($scope.form.OtherProgram !== null){
            $scope.tmVar = $scope.form.OtherProgram;
            $scope.form.OtherProgram = null;
            }
            }
        });

        });
        angular.module('app.controllers').filter('dateformats1', function ()
        {
            return function (item) {
            var t = item.split(/[- :]/);
            var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
            var time = d.getTime();
            return time;
        };
    });

})();

        jQuery(document).ready(function(){
            
        $('body').on('click','#start_date', function () {
        $(this).datetimepicker({
        format: 'MM/DD/YYYY',
        minDate: new Date(),

        defaultDate: new Date(),
        });//maxDate: someDate,
        //alert($(this).val());
        });
        

        $('body').on('keyup','#number_only',function(e){
        var value = $(this).val();
        value = value.replace(/^(0*)/,"");
        $(this).val(value);
        if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
        this.value = this.value.replace(/[^0-9\.]/g, '');
        }
        });

        });



 angular.module('app.controllers').directive('numbersOnly', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attr, ngModelCtrl) {
                function fromUser(text) {
                    if (text) {
                        var transformedInput = text.replace(/[^0-9-]/g, '');
                        if (transformedInput !== text) {
                            ngModelCtrl.$setViewValue(transformedInput);
                            ngModelCtrl.$render();
                        }
                        return transformedInput;
                    }
                    return undefined;
                }
                ngModelCtrl.$parsers.push(fromUser);
            }
        };
    });
