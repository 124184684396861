 (function(){
    "use strict";

    angular.module('app.config').config(function($authProvider) {

        $authProvider.httpInterceptor = false; // Add Authorization header to HTTP request
        $authProvider.loginOnSignup = true;
        $authProvider.baseUrl = '/'; // API Base URL for the paths below.
        $authProvider.loginRedirect = '/dashboard';
        $authProvider.logoutRedirect = '/login';
        $authProvider.signupRedirect = '/login';
        $authProvider.loginUrl = '/login';
        $authProvider.signupUrl = '/register';
        $authProvider.loginRoute = '/login';
        $authProvider.signupRoute = '/register';
        $authProvider.tokenRoot = false; // set the token parent element if the token is not the JSON root
        $authProvider.tokenName = 'token';
        $authProvider.tokenPrefix = 'bridesgenie'; // Local Storage name prefix
        $authProvider.unlinkUrl = '/auth/unlink/';
        $authProvider.unlinkMethod = 'get';
        $authProvider.authHeader = 'Authorization';
        $authProvider.authToken = 'Basic';
        $authProvider.withCredentials = true;
        $authProvider.platform = 'browser'; // or 'mobile'
        $authProvider.storage = 'localStorage'; // or 'sessionStorage'

          $authProvider.facebook({
            clientId: '443042029210103'
          });

          $authProvider.google({
            clientId: '460831094960-1srs0v4aag0qqoqlfapi4sltdujipeaa.apps.googleusercontent.com'
          });

        // Facebook
        $authProvider.facebook({
          url: '/login/facebook',
          authorizationEndpoint: 'https://www.facebook.com/v2.3/dialog/oauth',
          redirectUri: window.location.origin || window.location.protocol + '//' + window.location.host + '/',
          scope: 'email',
          scopeDelimiter: ',',
          requiredUrlParams: ['display', 'scope'],
          display: 'popup',
          type: '2.0',
          popupOptions: { width: 481, height: 269 }
        });

        // Google
        $authProvider.google({
          url: '/login/google',
          authorizationEndpoint: 'https://accounts.google.com/o/oauth2/auth',
          redirectUri: window.location.origin || window.location.protocol + '//' + window.location.host,
          scope: ['profile', 'email'],
          scopePrefix: 'openid',
          scopeDelimiter: ' ',
          requiredUrlParams: ['scope'],
          optionalUrlParams: ['display'],
          display: 'popup',
          type: '2.0',
          popupOptions: { width: 580, height: 400 }
        });


    });

})();
