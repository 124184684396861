(function() {
    "use strict";
    angular.module('app.controllers').controller('FindvendorsRequestssvendorController', function($scope, $state,$http,$location, CmsService,VendorsProfileService, NotificationService) {

VendorsProfileService.getcurrentvendorid().success(function(response) {

            $scope.currentvid = response;

            });
      
$scope.vid='';
$scope.hideselect='false';
 $scope.selectedCategories ={};

 //alert(sessionStorage.selectedCategories);
 if(sessionStorage.locations===undefined)
 {
  
sessionStorage.locations='';
 }
 else if(sessionStorage.locations==='')
 {
  
sessionStorage.locations='';
 }
 else
 {
  $("input[name='postal_code']").val(sessionStorage.locations);
  $scope.locations=sessionStorage.locations;
 }
//alert(JSON.parse(sessionStorage.selectedCategories));


if (sessionStorage.selectedCategories ===undefined)
{
$scope.selectedCategories='';  

}
else if (sessionStorage.selectedCategories ==='')
{
$scope.selectedCategories='';  

}

  //angular.isObject(value);

else if(sessionStorage.selectedCategories==='""')
{
  $scope.selectedCategories='';
  $scope.lat='';
  $scope.lng=''; 
}
else if(sessionStorage.selectedCategories==='{}')
{
 sessionStorage.selectedCategories1=''; 
$scope.selectedCategories='';
  $scope.lat='';
  $scope.lng='';
}
else
{


$scope.selectedCategories = JSON.parse(sessionStorage.selectedCategories);
if(sessionStorage.lat!=='')
{
$scope.lat = JSON.parse(sessionStorage.lat);
$scope.lng = JSON.parse(sessionStorage.lng);
}
else
{
  $scope.lat='';
  $scope.lng='';
  $scope.locations='';
}
$scope.sessionselectedCategories=sessionStorage.selectedCategories;





}



// else
// {
//   $scope.selectedCategories='';
//   $scope.lat='';
//   $scope.lng=''; 
//   $scope.locations='';
// }

if($scope.lat===undefined)
{
  $scope.lat='';
  $scope.lng='';
}
var formData = {
              lat:  $scope.lat,
              lng:  $scope.lng,
              selectedCategories:$scope.sessionselectedCategories,
              locations:$scope.locations,

            };
   
if($scope.lat==='')            
{
  

$http({

              url: "pages/findvendorslistsbycat",
              data: formData,
              method: 'POST',
            }).success(function(response){
             $scope.findVendorsbycat = response;
             $scope.serviceRequestsCountbycat =$scope.findVendorsbycat.length;
             //  location.href = 'pages/findvendorsresults';
               //sessionStorage.empty();
               //alert(sessionStorage.selectedCategories);
                sessionStorage.selectedCategories=$scope.selectedCategories;
                $scope.sessionselectedCategories=sessionStorage.selectedCategories;
                $scope.sessionselectedCategories1=sessionStorage.selectedCategories;
                //$("input[name='postal_code']").val(sessionStorage.locations);
              // sessionStorage.selectedCategories=JSON.stringify('');
               //sessionStorage.lat=JSON.stringify($scope.lat);
               //sessionStorage.lng=JSON.stringify($scope.lng);
               
            }).error(function(){
              
                $scope.serviceRequestsCountbycat =0;
            });

          }
          else
           { 
          


  $http({

              url: "pages/findvendorslists",
              data: formData,
              method: 'POST',
            }).success(function(response){
             $scope.findVendors = response;
             $scope.serviceRequestsCount =$scope.findVendors.length;
             //  location.href = 'pages/findvendorsresults';
               //sessionStorage.empty();
               //alert(sessionStorage.selectedCategories);
                sessionStorage.selectedCategories=$scope.selectedCategories;
                $scope.sessionselectedCategories=sessionStorage.selectedCategories;
                $scope.sessionselectedCategories1=sessionStorage.selectedCategories;
                $("input[name='postal_code']").val(sessionStorage.locations);
              // sessionStorage.selectedCategories=JSON.stringify('');
               sessionStorage.lat=JSON.stringify($scope.lat);
               sessionStorage.lng=JSON.stringify($scope.lng);
               
            }).error(function(){
              
                $scope.serviceRequestsCount =0;
            });
          }

      $('body').on('keyup','#zip_code_find_vendor',function(e){

// if(e.which !== 13){
//      $scope.addressValidationFailed = true;
  //    jQuery(".suggestedlocationerr").css('display','');
//      }
    //  if($("#zip_code_vendors").val().length < 2 || $("#zip_code_vendors").val().length === 0 ){
    //  $scope.addressValidationFailed = false;
     // jQuery(".suggestedlocationerr").css('display','none');
  //    }      
 $('#zip_code_find_vendor').geocomplete({

type: ['postal_code','(cities)'],
      componentRestrictions: {country: 'us'},
      details: "#vendorsearch",
      types: ['(regions)'],   


        }).bind("geocode:result", function(event, result){
            
//  jQuery(".suggestedlocationerr").css('display','none');
  //    $scope.addressValidationFailed = false;       
//  $scope.lat = $("input[name='lat']").val();
         //   $scope.lng = $("input[name='lng']").val();
            $scope.lat = $("input[name='lat']").val();
            $scope.lng = $("input[name='lng']").val();
            //alert(sessionselectedCategories);
            $scope.selectedCategories=$scope.sessionselectedCategories1;
            //$scope.sessionselectedCategories=$scope.selectedCategories;
              // $scope.sessionselectedCategories1=$scope.selectedCategories;
            if($('#vendorsearch input[name="locality"]').val()==='')
                      {
                       
                      $('#vendorsearch input[name="postal_code"]').val($('#vendorsearch input[name="administrative_area_level_1"]').val());  
                      }
                      else
                      {
                      $('#vendorsearch input[name="postal_code"]').val($('#vendorsearch input[name="locality"]').val());
                      }
//var serviceCategories = service_catSelect;
  //                $scope.lat = $("input[name='lat']").val();

      // setInterval(function(){ $("input[name='latitude']").val($("input[name='lat']").val());   $("input[name='longitude']").val($("input[name='lng']").val()); },0);
          $('#vendorsearch input[name="locality"]').prop("disabled", true);
          $('#vendorsearch input[name="administrative_area_level_1"]').prop("disabled", true);

          if($('#vendorsearch input[name="locality"]').val().length === 0){
            $('#vendorsearch input[name="locality"]').prop("disabled", false);
          }


          if($('#vendorsearch input[name="administrative_area_level_1"]').val().length === 0){
            $('#vendorsearch input[name="administrative_area_level_1"]').prop("disabled", false);
          }
      });
    });


        $scope.serviceRequests = {};

        //$scope.datalists = {};

            $scope.findVendors = [];


        // CmsService.getfindvendors().success(function(response) {
        //     $scope.serviceRequests = response;
        //     //console.log($scope.serviceRequests);
        // });
           // $scope.postal_code = $("input[name='postal_code']").val();
           // $scope.lat = $("input[name='lat']").val();
           // $scope.lng = $("input[name='lng']").val();
         //   setInterval(function(){ $scope.lng = '1234';  },0);
         //   $scope.selectedCategories = $("input[name='selectedCategories']").val();

            //$scope.formUrl = '/pages/findvendorslists/' + $scope.selectedCategories + '/'+$scope.lat+'/'+$scope.lng;

        //alert($scope.formUrl);
/*
        var formData = {

              postal_code: $scope.postal_code,
              lat:  $scope.lat,
              lng:  $scope.lng,
              selectedCategories:  $scope.selectedCategories,

            };
*/



        $scope.searchVendor = function(selectedCategories) {
               

      /* the $http service allows you to make arbitrary ajax requests.
       * in this case you might also consider using angular-resource and setting up a
       * User $resource. */
      $http({

              url: "pages/findvendorssearch",
              data: formData,
              method: 'POST',
              headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

            }).success(function(response){

          //   alert(formData);

            });
    };



        VendorsProfileService.getAllServiceCategories().success(function(response) {
     
            $scope.allCategories = response;

            
        });
        CmsService.gethomecurrentvendorid().success(function(response) {
     
            $scope.vid = response;

            
        });

        

 $scope.getmiles = function (event) {
  // alert(event.target.id);
   sessionStorage.miles = event.target.id;
   sessionStorage.types='';
      

   
 };
 $scope.gettypes = function (event) {
  // alert(event.target.id);
  sessionStorage.miles='';
   sessionStorage.types = event.target.id;
   
      

   
 };
 $scope.search = function () {
  
  $scope.locations=$("input[name='postal_code']").val();
  //alert($scope.sessionselectedCategories);
if($scope.selectedCategories!=='undefined' && $scope.lat!=='undefined' && $scope.lng!=='undefined')
{
 if($("input[name='postal_code']").val()!=='')
 { 
var formData = {
              lat:  $scope.lat,
              lng:  $scope.lng,
              selectedCategories:$scope.sessionselectedCategories,
              locations:$scope.locations,

            };



              $http({

              url: "pages/findvendorslists",
              data: formData,
              method: 'POST',
            }).success(function(response){
              $scope.hideselect="false";
             $scope.findVendors = response;
             $scope.serviceRequestsCount = $scope.findVendors.length;
             //alert($scope.selectedCategories);
             //alert('1');
               //$scope.sessionselectedCategories=$scope.selectedCategories;
               $scope.sessionselectedCategories1=$scope.sessionselectedCategories;
               $scope.serviceRequestsCount =$scope.findVendors.length;
               sessionStorage.selectedCategories=$scope.sessionselectedCategories;
               sessionStorage.locations=$("input[name='postal_code']").val();
              // $scope.locations=sessionStorage.locations;
              // sessionStorage.selectedCategories=JSON.stringify('');
               sessionStorage.lat=JSON.stringify($scope.lat);
               sessionStorage.lng=JSON.stringify($scope.lng);

                // location.href = 'pages/findvendorslists';

              //alert($scope.selectedCategories);
             $scope.datalists = $scope.findVendors;
            
            //show more functionality
            var pagesShown = 1;
            var pageSize = 8;
            

            $scope.paginationLimit = function(data) {
            return pageSize * pagesShown;
            };
            $scope.hasMoreItemsToShow = function() {
            return pagesShown < ($scope.datalists.length / pageSize);
            };
            $scope.showMoreItems = function() {
            pagesShown = pagesShown + 1;
            };

            
            
             
             //location.href = 'pages/findvendorsresults';
             //search();
            });

$http({

              url: "pages/findvendorslistsothers",
              data: formData,
              method: 'POST',
            }).success(function(response){
              $scope.hideselect="false";
             $scope.findVendorsbyothers = response;
             $scope.serviceRequestsCountbyothers = $scope.findVendorsbyothers.length;
             //alert($scope.selectedCategories);
             //alert('1');
               //$scope.sessionselectedCategories=$scope.selectedCategories;
               $scope.sessionselectedCategories1=$scope.sessionselectedCategories;
               $scope.serviceRequestsCount =$scope.findVendors.length;
               sessionStorage.selectedCategories=$scope.sessionselectedCategories;
               sessionStorage.locations=$("input[name='postal_code']").val();
              // $scope.locations=sessionStorage.locations;
              // sessionStorage.selectedCategories=JSON.stringify('');
               sessionStorage.lat=JSON.stringify($scope.lat);
               sessionStorage.lng=JSON.stringify($scope.lng);
                // location.href = 'pages/findvendorslists';
              //alert($scope.selectedCategories);
               $scope.datalistsbyothers = $scope.findVendorsbyothers;
            
            //show more functionality
            var pagesShownbyothers = 1;
            var pageSizebyothers = 8;
           $scope.hideselect='1';

            $scope.paginationLimitbyothers = function(data) {
            return pageSizebyothers * pagesShownbyothers;
            };
            $scope.hasMoreItemsToShowbyothers = function() {
            return pagesShownbyothers < ($scope.datalistsbyothers.length / pageSizebyothers);
            };
            $scope.showMoreItemsbyothers = function() {
            pagesShownbyothers = pagesShownbyothers + 1;
            };

             
            
             

           //  location.href = 'pages/findvendorsresults';

             //search();
            });

          }

        }
        $scope.hideselect="true";

    };
        $scope.showData = function(){
 

         //   CmsService.getfindvendors().success(function(response) {

          if($scope.selectedCategories!=='undefined' && $scope.lat!=='undefined' && $scope.lng!=='undefined')
{
          
            
var formData = {
              lat:  $scope.lat,
              lng:  $scope.lng,
              selectedCategories:$scope.sessionselectedCategories,
              locations:$scope.locations,

            };

            if($scope.lat==='')            
{
  

$http({

              url: "pages/findvendorslistsbycat",
              data: formData,
              method: 'POST',
            }).success(function(response){
             $scope.findVendorsbycat = response;
             $scope.serviceRequestsCountbycat =$scope.findVendorsbycat.length;
             $scope.serviceRequestsCountbyothers=0;
             $scope.serviceRequestsCount=0;
             //  location.href = 'pages/findvendorsresults';
               //sessionStorage.empty();
               //alert(sessionStorage.selectedCategories);
                sessionStorage.selectedCategories=$scope.selectedCategories;
                $scope.sessionselectedCategories=sessionStorage.selectedCategories;
                $scope.sessionselectedCategories1=sessionStorage.selectedCategories;
                //$("input[name='postal_code']").val(sessionStorage.locations);
              // sessionStorage.selectedCategories=JSON.stringify('');
               //sessionStorage.lat=JSON.stringify($scope.lat);
               //sessionStorage.lng=JSON.stringify($scope.lng);
               $scope.datalistsbycat = $scope.findVendorsbycat;
                var pagesShownbycat = 1;
                var pageSizebycat = 9;
             

            $scope.paginationLimitbycat = function(data) {
            return pageSizebycat * pagesShownbycat;
            };
            $scope.hasMoreItemsToShowbycat = function() {
            return pagesShownbycat < ($scope.datalistsbycat.length / pageSizebycat);
            };
            $scope.showMoreItemsbycat = function() {
            pagesShownbycat = pagesShownbycat + 1;
            };
               
            }).error(function(){
              
                $scope.serviceRequestsCountbycat =0;
            });

          }
          else 
           { 
            

              $http({

              url: "pages/findvendorslists",
              data: formData,
              method: 'POST',
            }).success(function(response){
              
             //$scope.findVendors = response;
             //$scope.serviceRequestsCount = $scope.findVendors.length;
            
             //location.href = 'pages/findvendorsresults';
             //search();
 

             $scope.findVendors = response;
             $scope.serviceRequestsCount =$scope.findVendors.length;
             
             $scope.sessionselectedCategories=sessionStorage.selectedCategories;
             $scope.sessionselectedCategories1=sessionStorage.selectedCategories;
             $scope.selectedCategories=sessionStorage.selectedCategories;
            // alert($scope.selectedCategories);
          $scope.locations=sessionStorage.locations;
             $("input[name='postal_code']").val(sessionStorage.locations);

             var log = [];
angular.forEach($scope.allCategories, function(value, key) {
  // this.push(key + ': ' + value);
  if(sessionStorage.selectedCategories1==key)
  {
  $scope.selectedvalue1=value;
  $scope.selectedkey1=key;
  sessionStorage.sessionselectedvalue1=value;
  sessionStorage.sessionselectedkey1=key;
}
}, log);
            // $scope.selectedCategories=sessionStorage.selectedCategories;
            /*
             sessionStorage.selectedCategories=JSON.stringify($scope.selectedCategories);
             sessionStorage.locations=$("input[name='postal_code']").val();
              // sessionStorage.selectedCategories=JSON.stringify('');
               sessionStorage.lat=JSON.stringify($scope.lat);
               sessionStorage.lng=JSON.stringify($scope.lng);
                 */
             $scope.datalists = $scope.findVendors;
             
            
            //show more functionality
            var pagesShown = 1;
            var pageSize = 8;
          

            $scope.paginationLimit = function(data) {
            return pageSize * pagesShown;
            };
            $scope.hasMoreItemsToShow = function() {
            return pagesShown < ($scope.datalists.length / pageSize);
            };
            $scope.showMoreItems = function() {
            pagesShown = pagesShown + 1;
            };

            
            });

              $http({

              url: "pages/findvendorslistsothers",
              data: formData,
              method: 'POST',
            }).success(function(response){
              
             //$scope.findVendors = response;
             //$scope.serviceRequestsCount = $scope.findVendors.length;
            
             //location.href = 'pages/findvendorsresults';
             //search();
 

             $scope.findVendorsbyothers = response;
             $scope.serviceRequestsCountbyothers =$scope.findVendorsbyothers.length;
             
             $scope.sessionselectedCategories=sessionStorage.selectedCategories;
             $scope.sessionselectedCategories1=sessionStorage.selectedCategories;
             $scope.selectedCategories=sessionStorage.selectedCategories;
            // alert($scope.selectedCategories);
             $scope.locations=sessionStorage.locations;
             $("input[name='postal_code']").val(sessionStorage.locations);
            // $scope.selectedCategories=sessionStorage.selectedCategories;
            /*
             sessionStorage.selectedCategories=JSON.stringify($scope.selectedCategories);
             sessionStorage.locations=$("input[name='postal_code']").val();
              // sessionStorage.selectedCategories=JSON.stringify('');
               sessionStorage.lat=JSON.stringify($scope.lat);
               sessionStorage.lng=JSON.stringify($scope.lng);
                 */
             $scope.datalistsbyothers = $scope.findVendorsbyothers;
             
            
            //show more functionality
            var pagesShownbyothers = 1;
            var pageSizebyothers = 8;
          

            $scope.paginationLimitbyothers = function(data) {
            return pageSizebyothers * pagesShownbyothers;
            };
            $scope.hasMoreItemsToShowbyothers = function() {
            return pagesShownbyothers < ($scope.datalistsbyothers.length / pageSizebyothers);
            };
            $scope.showMoreItemsbyothers = function() {
            pagesShownbyothers = pagesShownbyothers + 1;
            };

            
            });

          }
        }


            
        };

      //  };



            $scope.showSelectValue_cat = function(){
  // $scope.lengths=Object.keys($scope.allCategories).length;
               
  //        $scope.firstkey=Object.keys($scope.allCategories)[0];
  //       alert($scope.firstkey);

$scope.sessionselectedCategories=$scope.someModel;
           // var selected_service_id = $scope.someModel;
            $scope.selectedCategories = $scope.someModel;
var log = [];
angular.forEach($scope.allCategories, function(value, key) {
  // this.push(key + ': ' + value);
  if($scope.someModel==key)
  {
  $scope.selectedvalue1=value;
  $scope.selectedkey1=key;
  sessionStorage.sessionselectedvalue=value;
  sessionStorage.sessionselectedkey1=key;
}
}, log);
//expect(log).toEqual(['name: misko', 'gender: male']);
             

           //$scope.selectedCategories=$scope.sessionselectedCategories;
           
            

           };




    });

})();

