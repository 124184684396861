(function(){
    "use strict";

	
  angular.module('app.controllers').controller('ResetemailController', function($scope,vcRecaptchaService,AuthService,NotificationService, $mdDialog,$http) {

     
        $scope.resetemaillink = function($email) {

               var formData = {

              emails:  $email,

            };
             $http({
              url: "users/resetemail/",
              data: formData,
              method: 'POST',

            }).success(function(response){

                NotificationService.serverAlert(
                        response.message.title,response.message.body
                    );
             //  location.href = 'pages/findvendorsresults';

            });


      };

      $scope.resetredi = function() {

        location.reload(); 
      };

   
     
    });

})();

