
(function(){
  "use strict";

$('body').on('keyup','#zip_code_edit',function(e){
       $('#zip_code_edit').geocomplete({
          details: "#bridesUpdateEvents",
           componentRestrictions: {country: 'us'},
           types: ['(regions)'],
           }).bind("geocode:result", function(event, result){

          $('#bridesUpdateEvents input[name="locality"]').prop("disabled", true);
          $('#bridesUpdateEvents input[name="administrative_area_level_1_short"]').prop("disabled", true);

          if($('#bridesUpdateEvents input[name="locality"]').val().length === 0){
            $('#bridesUpdateEvents input[name="locality"]').prop("disabled", false);
          }

          if($('#bridesUpdateEvents input[name="administrative_area_level_1_short"]').val().length === 0){
            $('#bridesUpdateEvents input[name="administrative_area_level_1_short"]').prop("disabled", false);
          }
      });
    });



  angular.module('app.controllers').controller('BridesMyeventController', function( $scope, $http, NotificationService, $window, $mdDialog, BridesProfileService,$stateParams){

$scope.serviceCreates = {};

$scope.servicePassword = {};

 
      //$scope.state = $state.current.name;
   BridesProfileService.bridemyevent($stateParams.id).success(function(response) {
           $scope.eventdata = response;
                 
        });

   BridesProfileService.bridephone().success(function(response) {
         $scope.bridephones = response;     
        });


  $scope.brideseventsupdatemultiple = function(){

     var zipcode_lengths = $("input[name='postal_code']").val();
        if (zipcode_lengths.length != 5) {
        jQuery('.minzip').css('display','');
        return false;
        }else{
        //return true;
        }

  var formData = { 

  event_start_at : $scope.serviceCreates.event_start_at = $("input[name='event_start_at']").val(),

  zip_code : $scope.serviceCreates.postal_code = $("input[name='postal_code']").val(),

  city : $scope.serviceCreates.locality = $("input[name='locality']").val(),

state : $scope.serviceCreates.administrative_area_level_1 = $("input[name='administrative_area_level_1_short']").val(),

  lat : $scope.serviceCreates.lat = $("input[name='lat']").val(),

  lng : $scope.serviceCreates.lng = $("input[name='lng']").val(),

  guest_count : $scope.serviceCreates.guest_count = $("input[name='guest_count']").val(),

  mobile_number : $scope.serviceCreates.mobile_number = $("input[name='mobile_number']").val(),

  description : $scope.serviceCreates.description = $("#description").val(),

  event_ends_at : $scope.serviceCreates.event_ends_at = $("input[name='event_ends_at']").val(),

  currenteventid : $scope.serviceCreates.currenteventid = $("input[name='currenteventid']").val(),

  event_name : $scope.serviceCreates.event_name = $("#event_nam").val(),

        };

  $http({
  url: "brides/profile/edit",
  data: formData,
  method: 'POST',
  headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
  }).success(function(response){
       var alert = $mdDialog.alert({
          title: response.message.title,
          content: response.message.body,
          ok: 'OKAY'
      });
      $mdDialog
          .show(alert)
          .finally(function() {
               $window.location = "/brides/event/update/"+ $stateParams.id;
          });
  });

   };





 BridesProfileService.getexpire_date().success(function(response){ 

             $scope.getexpire_date = response;
            var event_start_at = $('#event_start_at').val();
            var ends_at = $('#event_ends_at').val();

        var minExpDate = new Date();
        minExpDate.setDate(minExpDate.getDate() + 6);

          // console.log(minExpDate);
            var expire_dates = $scope.getexpire_date;

            var someDate = new Date(event_start_at);



           someDate.setDate(someDate.getDate() - 7);     
            //console.log(someDate);
           var date_pick = someDate.getDate();

           var endsDate = new Date(event_start_at);

            // $("#start-datee").datetimepicker({
            //   format: 'MM/DD/YYYY'
          
            // });

            $("#start-datee").on("dp.show", function (e) {
              $('#start-datee').data("DateTimePicker").minDate(minExpDate);
              $('#start-datee').data("DateTimePicker").maxDate(someDate);

            });
            $('#start-datee').datetimepicker({
            format:"MM/DD/YYYY",
            disabledDates:[minExpDate],
            ignoreReadonly: true

            });


            BridesProfileService.getexpire_date().success(function(response)
        {
            $scope.getexpire_date = response;

            var event_start_at_count = $scope.getexpire_date;

            var event_start_at_extra = 7;

            var event_start_at = (event_start_at_count) + (+event_start_at_extra);
            
        
            var minDate = new Date();
            var date_pick = minDate.getDate();

            var date_picks = (30) + (+date_pick);

            minDate.setDate(date_picks);

              var ToEndDate = new Date();

            ToEndDate.setDate(ToEndDate.getDate()+365);


            $("#event-start-dateee").on("dp.show", function (e) {
              $('#event-start-dateee').data("DateTimePicker").minDate(minDate);
              
              $('#event-start-dateee').data("DateTimePicker").maxDate(ToEndDate);

            });
            $('#event-start-dateee').datetimepicker({
            format:"MM/DD/YYYY",
            disabledDates:[minDate],
             ignoreReadonly: true,
             widgetPositioning: {
                horizontal: 'left',
                vertical: 'bottom'
            }

            });
        });




});





  });


})();
