(function() {
    "use strict";


    angular.module('app.controllers').controller('DjRequestController', function($scope, $state, VendorsProfileService, BrideRequestService, NotificationService, BridesProfileService,$mdDialog) {


        $scope.allCategories = {};

        $scope.serviceCreate = {};

        $scope.event_details = {};

        $scope.serviceRequest = {};

 $scope.djserviceCreate.type_video_id='';

 $scope.djserviceCreate.type_service_id='';


if($scope.djserviceCreate.service_request_id.length !== 0)
{

 BridesProfileService.getServiceRequestDetails($scope.djserviceCreate.service_request_id)
            .success(function(response) {
                $scope.serviceRequest = response;
            });
}

else
{
  $scope.serviceRequest.biddings =[];

}


          if($scope.djserviceCreate.dj_music===undefined)
        {
            //$scope.ServiceCategorieDetails.type_function=''; 
            $scope.videoarray =[];
            if($scope.videoarray.length===0)
            {
             $scope.djserviceCreate.videoarraylength=''; 
            }
            else
            {
             $scope.djserviceCreate.videoarraylength='1';  
            }

        }
        else if($scope.djserviceCreate.dj_music==='')
        {
          $scope.videoarray =[]; 
          if($scope.videoarray.length===0)
          {
           $scope.djserviceCreate.videoarraylength=''; 
          }
          else
          {
           $scope.djserviceCreate.videoarraylength='1';  
          }
        }
        else
        {
         var split_video   = $scope.djserviceCreate.dj_music.split(',');
         $scope.videoarray  = [];
        for(var j=0;j<split_video.length;j++)
        {
         $scope.videoarray.push(parseInt(split_video[j]));
        if(parseInt(split_video[j])===9)
        {
         $scope.djserviceCreate.type_video_id=9;
        }
        }

        if($scope.videoarray.length===0)
        {
         $scope.djserviceCreate.videoarraylength=''; 
        }
        else
        {
         $scope.djserviceCreate.videoarraylength='1';  
        }
        //   $scope.videoarray =[parseInt($scope.ServiceCategorieDetails.type_video)];
        } 

       
        $scope.videoarray = angular.copy($scope.videoarray);



            if($scope.djserviceCreate.dj_services===undefined)
        {
         //$scope.ServiceCategorieDetails.type_function=''; 
        $scope.servicearray =[];
              if($scope.servicearray.length===0)
                {
               
                 $scope.djserviceCreate.servicearraylength=''; 
                }
                else
                {
                 $scope.djserviceCreate.servicearraylength='1';  
                }

        }
        else if($scope.djserviceCreate.dj_services==='')
        {
         $scope.servicearray =[]; 
         if($scope.servicearray.length===0)
                {
                  
                 $scope.djserviceCreate.servicearraylength=''; 
                }
                else
                {
                 $scope.djserviceCreate.servicearraylength='1';  
                }
        }
        else
        {
          var split_videos   = $scope.djserviceCreate.dj_services.split(',');
    $scope.servicearray  = [];

for(var k=0;k<split_videos.length;k++)
{
$scope.servicearray.push(parseInt(split_videos[k]));
if(parseInt(split_videos[k])===5)
{
$scope.djserviceCreate.type_service_id=5;
}
}

if($scope.servicearray.length===0)
                {
                  
                 $scope.djserviceCreate.servicearraylength=''; 
                }
                else
                {
                 $scope.djserviceCreate.servicearraylength='1';  
                }

        //   $scope.videoarray =[parseInt($scope.ServiceCategorieDetails.type_video)];
        } 

       
        $scope.servicearray = angular.copy($scope.servicearray);

        var path = window.location.pathname;
        var str = path.split("/");

        var url5 =  str[5];
        var url6 =  str[6];

         BridesProfileService.geteventdetails(url6).success(function(response)
           {
            $scope.event_details = response;
        jQuery(".djRequestForm").removeClass("temphide");

           });


          $scope.teethUR = [{ name: '1',id: 1,value: 'Pop', checked: false },
  { name: '2', id: 2,value: 'Hip-Hop/Rap', checked: false }, { name: '3', id: 3,value: 'Latin', checked: false }, { name: '4',id: 4,value: 'Rock', checked: false }, 
  { name: '5',id: 5,value: 'EDM/House/Club', checked: false }, 
  { name: '6',id: 6,value: 'Country', checked: false }, 
  { name: '7',id: 7,value: 'R&B Soul', checked: false }, 
  { name: '8',id: 8,value: 'Top 40', checked: false }, 
  { name: '9',id: 9,value: 'Other', checked: false }];

            $scope.upperRight = function ()
             {
              $scope.dj_music = "";
              $scope.dj_musics = "";
              for (var i = 0; i < $scope.teethUR.length; i++) 
              {
                if ($scope.teethUR[i].checked === true) 
                {
                     if($scope.teethUR[i].id === 9)
                     {
                       $('#other_type_music').prop('disabled', false); 
                       $('#other_type_music').prop('required', true);
                        $scope.dj_musics = $scope.teethUR[i].name; 
                     } 
                     else
                     {
                        $('#other_type_music').val('');
                        $('#other_type_music').prop('disabled', true);  
                       $('#other_type_music').prop('required', false);
                     }  

                if ($scope.dj_music === "") 
                  {
                     if($scope.teethUR[i].id === 9)
                     {
                      $scope.dj_musics = $scope.teethUR[i].name; 
                      $scope.dj_music = $scope.teethUR[i].name;
                     }
                     else
                     {
                   $scope.dj_music = $scope.teethUR[i].name;
                     }
                  } 
                  else 
                  {
                    if($scope.teethUR[i].id === 9)
                     {
                   $scope.dj_music = $scope.dj_music + "," + $scope.teethUR[i].name;
                    $scope.dj_musics = $scope.teethUR[i].name; 
                 }
                 else
                 {
                   $scope.dj_music = $scope.dj_music + "," + $scope.teethUR[i].name;
                   
                  }
                 }
                  
                }

                else
                {

                      $('#other_type_music').prop('disabled', true);  
                }
              }
            };


            $scope.djService = [{ name: '1',id: 1,value: 'MC', checked: false },
  { name: '2', id: 2,value: 'Lighting', checked: false }, { name: '3', id: 3,value: 'Microphones', checked: false }, { name: '4',id: 4,value: 'Fog Machine', checked: false }, 
  { name: '5',id: 5,value: 'Other', checked: false }];

            $scope.djServices = function ()
             {
              $scope.dj_services = "";
              $scope.dj_servicess = "";
              for (var i = 0; i < $scope.djService.length; i++) 
              {
                if ($scope.djService[i].checked === true) 
                {
                     if($scope.djService[i].id === 5)
                     {
                       $('#other_type_service').prop('disabled', false); 
                       $('#other_type_service').prop('required', true); 
                       $scope.dj_servicess = $scope.djService[i].name;
                     } 
                     else
                     {
                        $('#other_type_service').val('');
                        $('#other_type_service').prop('disabled', true);  
                       $('#other_type_service').prop('required', false);
                     }  

                if ($scope.dj_services === "") 
                  {
                    if($scope.djService[i].id === 5)
                     {
                   $scope.dj_services = $scope.djService[i].name;
                    $scope.dj_servicess = $scope.djService[i].name;
                     }
                     else
                     {
                      $scope.dj_services = $scope.djService[i].name;
                     }
                  } 
                  else 
                  {
                     if($scope.djService[i].id === 5)
                     {
                   $scope.dj_services = $scope.dj_services + "," + $scope.djService[i].name;
                    $scope.dj_servicess = $scope.djService[i].name;
                 }
                 else
                 {
                   $scope.dj_services = $scope.dj_services + "," + $scope.djService[i].name;
                 }
                  }
                  
                }
                else
                {
                     $('#other_type_service').prop('disabled', true);
                }
              }
            };
     

        VendorsProfileService.getAvaliableServicecategories().success(function(response) {
            $scope.allCategories = response;
        });


       
        $scope.djRequestFormSubmit = function() {
       $scope.serviceCreate.service_category_id = $("input[name='selectedCategories']").val();
       $scope.serviceCreate.dj_ends_at = $("input[name='dj_ends_at']").val();
       $scope.serviceCreate.dj_music = $("input[name='dj_music']").val();
       $scope.serviceCreate.dj_location = $("input[name='dj_location']").val();
       $scope.serviceCreate.dj_no_guests = $("input[name='dj_no_guests']").val();
       $scope.serviceCreate.dj_services = $("input[name='dj_services']").val();
       $scope.serviceCreate.other_type_inout = $("input[name='other_type_inout']").val();
       $scope.serviceCreate.other_type_music = $("input[name='other_type_music']").val();
       $scope.serviceCreate.other_type_service = $("input[name='other_type_service']").val();
       $scope.serviceCreate.dj_inout = $scope.djserviceCreate.dj_inout;
       $scope.serviceCreate.dj_equipment = $scope.djserviceCreate.dj_equipment;
       $scope.serviceCreate.maximum_budget = $("input[name='maximum_budget']").val();
       $scope.serviceCreate.dj_description = $scope.djserviceCreate.dj_description;
       // $scope.serviceCreate.dj_description = $("input[name='dj_description']").val();
       

        

      //  console.log(djRequestForm);

       var confirm = $mdDialog.confirm()
            .parent(angular.element(document.body))
            .title('Attention')
            .content('Please click OKAY to post this service request or click CANCEL to review it again.')
            .ariaLabel('Attention')
            .ok('Okay')
            .cancel('Cancel')
            .hasBackdrop(false);


             $mdDialog.show(confirm).then(function() {

              var djRequestForm = BrideRequestService.saveRequest($scope.serviceCreate,url6);

            djRequestForm.success(function(response) {

              // NotificationService.serverAlert(response.message.title, response.message.body);
           location.href = '/brides/requests/'+url6;

            }).error(function(response) {

                //  _.each(response, function(errors, key) {
                //      _.each(errors, function(e) {
                //        $scope.registrationForm[key].$dirty = true;
                //        $scope.registrationForm[key].$setValidity(key, false);
                //        $scope.errorMessages[key] = e;
                //      });
                //  });

            });

          }, function() {

             // $window.location.reload();

            });

        };

         $scope.gethoursvenue = function (id) {
   $scope.djserviceCreate.dj_inout={};          
            
      $scope.djserviceCreate.dj_inout=id;

   
 };

       $scope.getsound = function (id) {
   $scope.djserviceCreate.dj_equipment={};          
            
      $scope.djserviceCreate.dj_equipment=id;

   
 };

    });

angular.module('app.controllers').directive("checkboxGroupvideos", function() {
        return {
            restrict: "A",
            link: function(scope, elem, attrs) {
                // Determine initial checked boxes
                if (scope.videoarray.indexOf(scope.color.id) !== -1) {
                    elem[0].checked = true;
                }


                // Update array on click
                elem.bind('click', function() {
                    var index = scope.videoarray.indexOf(scope.color.id);
                    // Add if checked
                    if (elem[0].checked) {
                      
                      if(scope.color.id===9)
                      {
                     scope.djserviceCreate.type_video_id=9;
                      }

                        if (index === -1) scope.videoarray.push(scope.color.id);
                    }
                    // Remove if unchecked
                    else {
                      if(scope.color.id===9)
                      {
                       scope.djserviceCreate.type_video_id='';
                      }

                      //alert(scope.video.id);

                        if (index !== -1) scope.videoarray.splice(index, 1);
                    }


                      if(scope.videoarray.length===0)
                {
                 scope.djserviceCreate.videoarraylength=''; 
                }
                else
                {
                 scope.djserviceCreate.videoarraylength='1';  
                }
                    // Sort and update DOM display
                    scope.$apply(scope.videoarray.sort(function(a, b) {
                        return a - b;
                    }));
                });

               


            }
            
        };
    });



angular.module('app.controllers').directive("checkboxServices", function() {
        return {
            restrict: "A",
            link: function(scope, elem, attrs) {
                // Determine initial checked boxes
                if (scope.servicearray.indexOf(scope.colors.id) !== -1) {
                    elem[0].checked = true;
                }


                // Update array on click
                elem.bind('click', function() {
                    var index = scope.servicearray.indexOf(scope.colors.id);
                    // Add if checked
                    if (elem[0].checked) {
                      
                      if(scope.colors.id===5)
                      {
                     scope.djserviceCreate.type_service_id=5;
                      }

                        if (index === -1) scope.servicearray.push(scope.colors.id);
                    }
                    // Remove if unchecked
                    else {
                      if(scope.colors.id===5)
                      {
                       scope.djserviceCreate.type_service_id='';
                      }

                      //alert(scope.video.id);

                        if (index !== -1) scope.servicearray.splice(index, 1);
                    }


                      if(scope.servicearray.length===0)
                {
                 scope.djserviceCreate.servicearraylength=''; 
                }
                else
                {
                 scope.djserviceCreate.servicearraylength='1';  
                }
                    // Sort and update DOM display
                    scope.$apply(scope.servicearray.sort(function(a, b) {
                        return a - b;
                    }));
                });

               


            }
            
        };
    });



 angular.module('app.controllers').filter('dateformat', function ()
          {
          return function (item) {
            var t = item.split(/[- :]/);
            var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
            var time = d.getTime(); 
            return time;
          };
          });



})();

jQuery(document).ready(function(){

  var someDate = new Date();
    someDate.setDate(someDate.getDate() + 60);

    $('body').on('click','#end-date', function () {
      $(this).datetimepicker({
              format: 'MM/DD/YYYY',
              minDate: new Date(),
              
              defaultDate: new Date(),
      });//maxDate: someDate,
    });

$('body').on('keyup','#hournumber_only',function(e){
    var value = $(this).val();
      value = value.replace(/^(0*)(9*)/,"");
      $(this).val(value);
  if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
     this.value = this.value.replace(/[^0-9\.]/g, '');
  }
});
});

jQuery(document).ready(function(){

$('body').on('keyup','#number_only',function(e){
    var value = $(this).val();
      value = value.replace(/^(0*)/,"");
      $(this).val(value);
  if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
     this.value = this.value.replace(/[^0-9\.]/g, '');
  }
});
});
