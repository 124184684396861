(function() {
    "use strict";

    angular.module('app.controllers').controller('LoginController', function($rootScope, $auth, $scope, $state, AuthService, NotificationService, $window, $http,$mdDialog) {

         $scope.websites = ['Remember me'];
  $scope.selection = ['Remember me'];
  $scope.chkremember=1;
  $scope.toggleSelection = function toggleSelection(site) {

    var idx = $scope.selection.indexOf(site);
    
    // is currently selected
    if (idx > -1) {
        //uncheck
      $scope.chkremember=0;
              $("#chkremember").removeClass("ng-scope md-black-theme md-checked");
              setTimeout(function(){
  $("#chkremember").removeClass("md-checked");
}, 200);
                       $("#topchkremember").removeClass("ng-scope md-black-theme md-checked");
              setTimeout(function(){
  $("#topchkremember").removeClass("md-checked");
}, 200);
              
      $scope.selection.splice(idx, 1);

    }

    // is newly selected
    else {
        //check
        $scope.chkremember=1;
         $("#chkremember").addClass("ng-scope md-black-theme");
         $("#topchkremember").addClass("ng-scope md-black-theme");
      $scope.selection.push(site);
      
    }

  };


        $scope.errorMessages = {};
        $scope.user = {};
        
        //$scope.user.email='';
        
        if(sessionStorage.rememberemail===undefined)
         {
            
            sessionStorage.rememberemail='';
            sessionStorage.rememberpassword='';
            $scope.user.email=sessionStorage.rememberemail;
            $scope.user.password=sessionStorage.rememberpassword;
           // alert('2');
         }
         else if(sessionStorage.rememberemail==='')
         {
          sessionStorage.rememberemail='';
         sessionStorage.rememberpassword='';

         }
         else
         {
         //$("#email").val(sessionStorage.rememberemail);
         //alert(sessionStorage.rememberemail);
         $scope.user.email=sessionStorage.rememberemail;
         $scope.user.password=sessionStorage.rememberpassword;
         
         }
    // $scope.chkremember='';
        $scope.loginSubmit = function() {

            //alert($scope.chkremember);
             //alert($scope.user.email);
            var auth = AuthService.login($scope.user);
// var inputemail = "opentestemailid@gmail.com";
//
// var request = $http({
// method: "post",
// url: baseurl+'/users',
// data: {
// email: inputemail ,
// },
// headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
// });
// /* Successful HTTP post request or not */
// alert(inputemail);
//
// request.success(function (data) {
// if(data == "1"){
// $scope.responseMessage = "Successfully Logged In";
// }
// else {
// $scope.responseMessage = "Username or Password is incorrect";
// }
// });
            auth.success(function(response) {
                

                if(response.success === true){

                    // NotificationService.serverNotification(
                    //     response.message.title,response.message.body
                    // );
            //alert($scope.chkremember);
                    if($scope.chkremember===1)
                    {
                        
                      sessionStorage.rememberemail=$scope.user.email;  
                      sessionStorage.rememberpassword=$scope.user.password;  

                    }
                    else
                    {
                        sessionStorage.rememberemail='';
                        sessionStorage.rememberpassword='';
                    }    
                    sessionStorage.selectedCategories='';
                    sessionStorage.selectedCategories1='';
                    sessionStorage.lat='';
                    sessionStorage.lng='';
                    sessionStorage.locations='';
                    sessionStorage.miles='';
                    sessionStorage.types='';

                    $window.location = '/';

                } else if (response.success === false) {

  if(response.reactivate === true){

    $mdDialog.show({

                   locals: { title: response.message.title, body: response.message.body, email: response.message.email  },
                     controller: ResetEmailController,
                     templateUrl: '/views/reset-email-link/reset-email-link.html',
                     parent: angular.element(document.body),
                     clickOutsideToClose:false,
              });


                  }else {
                    NotificationService.serverAlert(
                        response.message.title,response.message.body
                    );
                }}

            }).error(function(response) {

                _.each(response, function(errors, key) {
                    _.each(errors, function(e) {
                        $scope.errorMessages[key] = e;
                        $scope.loginForm[key].$dirty = true;
                        $scope.loginForm[key].$setValidity(key, false);
                    });
                });

            });

        };


          function ResetEmailController($scope,title,body,email) {

         $scope.usertitle = title;

         $scope.userbody = body;

         $scope.useremail = email;
    }

        $scope.logout = function() {

            var logout = AuthService.destroy();

            logout.success(function(response) {

                // NotificationService.serverNotification(
                    // response.message.title,response.message.body
                // );
            sessionStorage.selectedCategories='';
            sessionStorage.selectedCategories1='';
            sessionStorage.lat='';
            sessionStorage.lng='';
            sessionStorage.locations='';
                $window.location = '/';

            }).error(function(response) {

                NotificationService.serverAlert(
                    response.message.title,response.message.body
                );
            });

        };
        $scope.checkAll = function() {
           if ($scope.user.remember) {
            $scope.chkremember=0;
            //sessionStorage.chkremember=0;
           // alert('yes');
        } else {
            $scope.chkremember=1;
            // sessionStorage.chkremember=1;
            //alert('no');
        }         
        };        
         
    });




angular.module('app.run').run(function($rootScope, $timeout, $http, $auth, AuthService, $document) {    

    var d = new Date();
    var n = d.getTime();  //n in ms
    var sessionTime = '';
    var loggedInStatus = '';

    // $rootScope.sessionTime = AuthService.websitesession().success(function(response) {

    //      return response.data;

    //   });

    // $rootScope.loggedInStatus = AuthService.loggedInStatus().success(function(response) {
    //       return response;        
    //       });

    // console.log($rootScope.sessionTime.data);

    $rootScope.idleEndTime = n+(2*60*1000); //set end time to 20 min from now

    $document.find('body').on('mousemove keydown DOMMouseScroll mousewheel mousedown touchstart', checkAndResetIdle); //monitor events


    function checkAndResetIdle() //user did something
    {
    var d = new Date();
    var n = d.getTime();  //n in ms

    if (n>$rootScope.idleEndTime)
    {
    $document.find('body').off('mousemove keydown DOMMouseScroll mousewheel mousedown touchstart'); //un-monitor events

    //$location.search('IntendedURL',$location.absUrl()).path('/login'); //terminate by sending to login page
  

          if (loggedInStatus===1) {
          //console.log('DENY');
          document.location.href = '/logout';

          }
          else {
          //console.log('ALLOW');
          //$location.path('/home');
          }

    //alert('Session ended due to inactivity');
    }
    else
    {
   
            $rootScope.idleEndTime = n+(2*60*1000); //set end time to 20 min from now

    }
  }

});


angular.module('app.controllers').directive('windowExit', function($window) {
  return {
    restrict: 'AE',
    link: function(element, attrs){
       var myEvent = $window.attachEvent || $window.addEventListener,
       chkevent = $window.attachEvent ? 'onbeforeunload' : 'beforeunload'; /// make IE7, IE8 compatable

       myEvent(chkevent, function (e) { // For >=IE7, Chrome, Firefox
           var confirmationMessage = ' ';  // a space
           (e || $window.event).returnValue = document.location.href = '/logout';
           //return confirmationMessage;
          document.location.href = '/logout';

       });
    }
  };
});
   
})();
