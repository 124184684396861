(function() {
"use strict";


angular.module('app.controllers').controller('BridesServiceRequestsController', function($scope, $state, $stateParams,$http, VendorsProfileService, BridesProfileService, NotificationService, $window) {


            BridesProfileService.getcurrentdates().success(function(response) {

            $scope.currentdate = response;

//            connsole.log($scope.currentdate);

            });

            $scope.eventIds = $stateParams.id;


            BridesProfileService.geteventnamedetail($stateParams.id).success(function(response)
            {
            $scope.eventName = response;
            });


$scope.serviceRequests = {};
$scope.myCategories = {};

var price = [];
$scope.price = [];

var selected_service_id = $scope.Categories;
$scope.selectedCategories = selected_service_id;

var formData = {

selectedCategories:  $scope.selectedCategories,

};
var eventid = $stateParams.id;
$scope.eventid = $stateParams.id;
$http({

url: "brides/requests/"+eventid,
data: formData,
method: 'POST',


}).success(function(response){
$scope.findVendors = response;
$scope.serviceRequestsCount = $scope.findVendors.length;

              angular.forEach($scope.findVendors, function(item){
                  
                   angular.forEach(item.biddings, function(items){

                       var price = $scope.price.push(items.bidding_amount);
                       // console.log(item); 
                          $scope.priceList =[{'price':price.bidding_amount}];
                   });
                    
                   
               });

    //console.log($scope.priceList);


 BridesProfileService.brideEventStatus().success(function(response) {

                $scope.eventStatus = response;
                
            }); 


// $scope.priceList = [{
//     "name": "Product 1",
//     "price": "1000"
//   }, {
//     "name": "Product 2",
//     "price": "200"
//   }, {
//     "name": "Product 3",
//     "price": "300"
//   }, {
//     "name": "Product 4",
//     "price": "400"
//   }, {
//     "name": "Product 5",
//     "price": "500"
//   }];

//  location.href = 'pages/findvendorsresults';

});

 var path = window.location.pathname;
        var str = path.split("/");

        var url5 =  str[5];
        var url6 =  str[6];


BridesProfileService.getAllServiceRequests().success(function(response) {

$scope.serviceRequests = response;

//  NotificationService.serverAlert(response.message.title, response.message.body);
});

BridesProfileService.getServiceRequestlowestBidslist().success(function(response) {

$scope.lowestbestoffer = response;

});


BridesProfileService.selectedserviceslist().success(function(response) {

$scope.myCategories = response;

});


$scope.get_distance = function (event) {

sessionStorage.miles ='';
sessionStorage.types='';


};

$scope.deleterecords = function(data,cat,events_ids) 
{


    var val = data;
    var vals = cat;
    var events_id = events_ids;

    var csrfToken = document.getElementById('csrf-token').value;
    if (confirm("Are you sure want to delete?")) {
     $.ajax({
    type: "POST",
    url: "brides/services/requests/updateposts",
    headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
        beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
        },
    data: { services_id : val,cat_id : vals,eid : events_id },
        success:function(campaigns)
        {
       //     location.reload();
$window.location.href="/brides/requests/" + $stateParams.id;
        }
    });
   }

   return false;

}; 

$scope.sort = function(keyname){
$scope.sortKey = keyname;   //set the sortKey to the param passed
$scope.reverse = !$scope.reverse; //if true make it false and vice versa
};

$scope.brideActivelists = function(){

var selected_service_id = $scope.Categories;
$scope.selectedCategories = selected_service_id;

var formData = {

selectedCategories:  $scope.selectedCategories,

};
$http({

url: "brides/requests/"+eventid,
data: formData,
method: 'POST',


}).success(function(response){
$scope.findVendors = response;
$scope.serviceRequestsCount = $scope.findVendors.length;

//  location.href = 'pages/findvendorsresults';

});

/*
CmsService.getfindservicecategories().success(function(response) {


$scope.findVendors = response;

$scope.datalists = $scope.findVendors.vendors_list;

//show more functionality
var pagesShown = 1;
var pageSize = 4;

$scope.paginationLimit = function(data) {
return pageSize * pagesShown;
};
$scope.hasMoreItemsToShow = function() {
return pagesShown < ($scope.datalists.length / pageSize);
};
$scope.showMoreItems = function() {
pagesShown = pagesShown + 1;
};
});
*/
};

});

angular.module('app.controllers').filter('format', function () {
return function (item) {

var t = item.split(/[- :]/);
var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
var time = d.getTime();

var nextDay = new Date(d);
nextDay.setDate(d.getDate()+15);
return nextDay;
};
});

angular.module('app.controllers').filter('format1', function () {
return function (item) {

var t = item.split(/[- :]/);
var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
var time = d.getTime();

var nextDay = new Date(d);
nextDay.setDate(d.getDate()+0);
return nextDay;
};
});

function brideActivelists(data)
{ var val = data;

//  alert(data);}
}

})();

function brideActivelists(data)
{
var val = data;
alert(data);
//var csrfToken = document.getElementById('csrf-token').value;

// $http.get('vendors/vendorcategorieswise/getactiveLeadslists/'+1).success(function(response){
// //make a get request to mock json file.
//         $scope.data = response; //Assign data received to $scope.data
//         alert($scope.data);
//     })
//     .error(function(err){
//     //handle error
//     });

$.ajax({
type: "POST",
url: "vendors/vendorcategorieswise/getactiveLeadslists/"+data,
headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
$("#activeLeadsByCat").html('<img src="images/loader.gif" /> Now loding...');},
data: { id : val},
success:function(response)
{
//alert(response);
$("#activeLeadsByCat").html(response);
}
});

}


