(function() {
    "use strict";
    
$('body').on('keyup','#zip_code_vendor_one',function(e){
       $('#zip_code_vendor_one').geocomplete({
          details: "#vendorStepone",
	  types: ['(regions)'],
	  type: ['postal_code','(cities)'],
          componentRestrictions: {country: 'us'},
        }).bind("geocode:result", function(event, result){

          $('#vendorStepone input[name="locality"]').prop("disabled", true);
          $('#vendorStepone input[name="administrative_area_level_1_short"]').prop("disabled", true);

          if($('#vendorStepone input[name="locality"]').val().length === 0){
            $('#vendorStepone input[name="locality"]').prop("disabled", false);
          }

          if($('#vendorStepone input[name="administrative_area_level_1_short"]').val().length === 0){
            $('#vendorStepone input[name="administrative_area_level_1_short"]').prop("disabled", false);
          }
      });
    });

    angular.module('app.controllers').controller('VendorsProfileController', function($scope,$state,$templateCache, VendorsProfileService, NotificationService) {
         
        $scope.allCategories = {};
        $scope.defaultOption = 'Select';
        $scope.defaultNone = 'None';
        $scope.vendor = {};
        $scope.contact_number='';
            VendorsProfileService.getAllServiceCategories().success(function(response) {
            $scope.allCategories = response;

            });


            VendorsProfileService.getAllServiceCategories().success(function(response) {
            $scope.items = response;
            $scope.filteredItems = response;
            });

            $scope.$watch('vendor.default_cat',function(){
                $scope.filteredItems = [];
                angular.forEach($scope.items, function(val, key){
               
                    if(key != $scope.vendor.default_cat){

                    var item = {'val':val,'key':key};
                    // this.push(items);
                    $scope.filteredItems.push(item);


                    }   

                },$scope.filteredItems); 
            });

            $scope.vendor.other_cat = $("input[name='other_cat']").val();
            $scope.vendor.default_cat = $("input[name='default_cat']").val();


        $scope.showSelectValue_cat = function(default_cat) {
                  var serviceCategories = default_cat;
                  $scope.selectedCategories = default_cat;

                  };

        $scope.saveVendorCategories = function(serviceCategories,default_cat) {

            var saveCateogries = VendorsProfileService.saveServiceCategories($scope.vendor);

            saveCateogries.success(function(response) {

                NotificationService.serverNotificationNoRedirect(response.message.title, response.message.body);

            }).error(function(response) {

                //  _.each(response, function(errors, key) {
                //      _.each(errors, function(e) {
                //        $scope.registrationForm[key].$dirty = true;
                //        $scope.registrationForm[key].$setValidity(key, false);
                //        $scope.errorMessages[key] = e;
                //      });
                //  });
            });
        };

        $scope.vendorProfileFormSubmit = function() {



            var zipcode_lengths_vendor = $('#zip_code_vendor_one').val();
            
            if (zipcode_lengths_vendor.length != 5) {
            jQuery('.minzipsvendor').css('display','');
            return false;
            }else{
            //return true;
            }
            $scope.vendor.city = $("input[name='locality']").val();
            $scope.vendor.state = $("input[name='administrative_area_level_1_short']").val();
            $scope.vendor.postal_code = $('#zip_code_vendor_one').val();
            $scope.vendor.lat = $("input[name='lat']").val();
            $scope.vendor.lng = $("input[name='lng']").val();

            $scope.vendor.phone_number = $("input[name='phone_number']").val();
            $scope.vendor.mobile_number = $("input[name='mobile_number']").val();


            var saveVendorProfile = VendorsProfileService.saveProfile($scope.vendor);

            saveVendorProfile.success(function(response) {

                NotificationService.serverAlert(response.message.title, response.message.body);

            }).error(function(response) {

                //  _.each(response, function(errors, key) {
                //      _.each(errors, function(e) {
                //        $scope.registrationForm[key].$dirty = true;
                //        $scope.registrationForm[key].$setValidity(key, false);
                //        $scope.errorMessages[key] = e;
                //      });
                //  });


            });

        };
        setTimeout(function(){
  $scope.vendorProfileForm.$setPristine(); 
}, 200);
// $scope.vendorProfileForm.$setPristine(); 
    });

    $('body').on('keyup','#number_only',function(e){
        var value = $(this).val();
      value = value.replace(/^(0*)/,"");
      $(this).val(value);
      if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
         this.value = this.value.replace(/[^0-9\.]/g, '');
      }
    });

})();



angular.module('app.controllers').directive('numbersOnly', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attr, ngModelCtrl) {
            function fromUser(text) {
                if (text) {
                    var transformedInput = text.replace(/[^0-9]/g, '');

                    if (transformedInput !== text) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                }
                return undefined;
            }            
            ngModelCtrl.$parsers.push(fromUser);
        }
    };
});
