(function() {
    "use strict";
    angular.module('app.config').config(function($mdThemingProvider) {

    $mdThemingProvider.theme('black')
        .primaryPalette('grey', {
            'default': '200',
        })
        .accentPalette('grey',{'default':'900',})
        .warnPalette('grey',{'default':'900',});


    $mdThemingProvider.setDefaultTheme('black');

    });
})();