
jQuery(document).ready(function(){

    $('body').on('click', '#eventEditButton', function(){
    $('#showEvent').hide();
    $('#updateEvent').show();
   });

 $('body').on('click', '#editPasswordButton', function(){
    $('#showPassowrd').hide();
    $('#updatePasswordProfile').show();
   });

  $('body').on('click', '#editaccotinfoButton', function(){
    $('#showaccountinfo').hide();
    $('#updateAccountProfile').show();
   });

 $('body').on('click', '#profileEditNotificationButton', function(){
    $('#showProfilePhotos').hide();
    $('#PhotosupdateProfile').show();
   });

 $('body').on('click', '#profileEditNotificationButton', function(){
    $('#showProfileNotification').hide();
    $('#NotificationupdateProfile').show();
   });

});



(function(){
  "use strict";

  angular.module('app.controllers').controller('BridesMyProfileController', function( $scope, $http, NotificationService, $window, $mdDialog, BridesProfileService){

$scope.serviceCreates = {};

$scope.servicePassword = {};

$scope.serviceaccount = {};


BridesProfileService.bridemyProfile().success(function(response) {
        $scope.eventdata = response;
setTimeout(function(){
jQuery(".socialprofilemsg").css("display",'');
//jQuery(".wholediv").css('display','');
}, 500);               
        });




$scope.service_request_notify = function($value)
{
   $http({
            url: "brides/changeservice/" + $value,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
            }).success(function(response){

            });

};

$scope.bid_post_notify = function($value)
{


   $http({
            url: "brides/changebidpost/" + $value,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
            }).success(function(response){

            });

};


$scope.bid_accept_notify = function($value)
{
 
   $http({
            url: "brides/changebidaccept/" + $value,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
            }).success(function(response){

            });

};


$scope.accoutinfoupdate = function()
{

        var formData = {
            phonenum : $scope.serviceaccount.phonenum = $("input[name='phonenum']").val(),
          };


           $http({
            url: "brides/accountinfo",
            data: formData,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
            }).success(function(response){

              var alert = $mdDialog.alert({
                    title: response.message.title,
                    content: response.message.body,
                    ok: 'OKAY'
                });
                $mdDialog
                    .show(alert)
                    .finally(function() {
                        $window.location = "/brides/profile?id=0";
                    });

            });

};



   $scope.passwordupdates = function()
   {

     var formData = { 
  current_password : $scope.servicePassword.current_password = $("input[name='current_password']").val(),

  new_password : $scope.servicePassword.new_password = $("input[name='new_password']").val(),

   confirm_password : $scope.servicePassword.confirm_password = $("input[name='confirm_password']").val(),

  user_id : $scope.servicePassword.user_id = $("input[name='user_id']").val(),

    email : $scope.servicePassword.email = $("input[name='email']").val(),
        };

var user_ids = $("input[name='user_id']").val();
var csrfToken = document.getElementById('csrf-token').value;

  $.ajax({
      type:'POST',
      data: formData,
   url: "/brides/users/changepassword/"+user_ids,
      cache:false,
      beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);},

      success: function(response) {
           if(response.success === true){
                var alert = $mdDialog.alert({
                title: response.message.title,
                content: response.message.body,
                ok: 'OKAY'
                });
                $mdDialog
                .show(alert)
                .finally(function() {
                $window.location = "/brides/profile?id=1";
                });

            }
        },
      error: function(response){
  $("#redundancyCalls").remove();
            $('#bridespassword').find('.error_show').text(' ');

            $.each($.parseJSON(response.responseText), function(key, value){

              $("input[name="+key+"]").parent('div').find(".error_show").remove();
              $("input[name="+key+"]").after('<span class="error_show" style="color:red">'+value+'</span>');

            });
        }
    });


   };


    $scope.notificationSubmits = function()
    {
        var testval = [];
        $('#notifications_class:checked').each(function() {

            testval.push($(this).val());
        });

      var formData = {
            notifications_selected:  testval,
          };


           $http({
            url: "brides/myprofile/notifications",
            data: formData,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
            }).success(function(response){

              var alert = $mdDialog.alert({
                    title: response.message.title,
                    content: response.message.body,
                    ok: 'OKAY'
                });
                $mdDialog
                    .show(alert)
                    .finally(function() {
                         $window.location = "/brides/profile?id=2";
                    });

            });
        };


$scope.init = function () {
//prem work here
var url = window.location.href;
var urlSplit = url.split("=");
if("1" in urlSplit){
 $scope.selectedTab = urlSplit[1];
}
};

  });


})();
   


   // $scope.onTabSelected = function(tab){
	  //  if(tab === "tab3"){
		 //   $scope.selectedTab = "2";
   //   }
	  //  else if(tab === "tab2"){
		 //   $scope.selectedTab = "1";
   //   }
	  //  else{
		 //   $scope.selectedTab = "0";
   //   }
   // };






   




// angular.module('app.controllers').directive('numbersOnly', function () {
//     return {
//         require: 'ngModel',
//         link: function (scope, element, attr, ngModelCtrl) {
//             function fromUser(text) {
//                 if (text) {
//                     var transformedInput = text.replace(/[^0-9]/g, '');

//                     if (transformedInput !== text) {
//                         ngModelCtrl.$setViewValue(transformedInput);
//                         ngModelCtrl.$render();
//                     }
//                     return transformedInput;
//                 }
//                 return undefined;
//             }            
//             ngModelCtrl.$parsers.push(fromUser);
//         }
//     };
// });  

