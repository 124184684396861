(function(){
    "use strict";
   

         $(document).ready(function(){

            $("body").on('click','.dropdown-toggle',function(event){             
              $(this).parent().siblings().children('.submenu').hide();
              $(this).parent().siblings().children().find('.scroll-bar').removeClass('menu-scroll-thumb');
              $(this).next().toggle();
              $(this).next().children().find('.scroll-bar').toggleClass('menu-scroll-thumb');
               event.stopPropagation();
            });
            $('html').on('click',function(){
              $('.submenu').hide();
              $('.scroll-bar').removeClass('menu-scroll-thumb');
              $("body").removeClass('dropdown-overlay');

            });

            $('body').on('click','.submenu',function(event){
                event.stopPropagation();
                //alert('test');
            });


            $("body").on('click','.forgot-pass button',function(){
                $('.accordian .login-popup, .accordian .register-page').hide();
                $('.panel-body .reset-popup').slideDown();
            });
            $("body").on('click','#signin .forgot-pass button',function(){
                $('#signin .panel-heading').hide();
                $('#signin').prepend('<li class="forgot-open">Sign In</li>');
            });
            $("body").on('click','#join-now .forgot-pass button',function(){
                $('#join-now .panel-heading').hide();
                $('#join-now').prepend('<li class="forgot-open">Join Now</li>');
            });
            $("body").on('click','.forgot-open',function(){
                $(this).remove();
                $('#signin .panel-heading, #join-now .panel-heading').show();
                $('.accordian .login-popup, .accordian .register-page').slideDown();
                $('.panel-body .reset-popup').hide();
            });
            $("body").on('click','.top-menu .panel-heading',function(){
                $('.accordian .login-popup, .accordian .register-page, #signin .panel-heading, #join-now .panel-heading').show();
                $('.panel-body .reset-popup').hide();
                $('.forgot-open').remove();
            });
            $("body").on('click','.top-menu .panel-heading',function(){
                $(this).parent().toggleClass('open-menu');
                $(this).parent().siblings().removeClass('open-menu');
                $("body").addClass('dropdown-overlay');
                if ($(".panel").hasClass("open-menu")) {
                    $("body").addClass('dropdown-overlay');
                }
                else{
                    $("body").removeClass('dropdown-overlay');
                }
            });
            $('body').on('click','.js-dropdown-link a', function (event) {
                var myUrl = $(this).attr('href');
                window.location =myUrl;
                // location.reload();
            });

            



        });

$('body').on('keydown','#phone-number',function(e){

    var key = e.charCode || e.keyCode || 0;
   var $phone = $(this);
    

    // Don't let them remove the starting '('
    if ($phone.val().length === 1 && (key === 8 || key === 46)) {
      $phone.val('('); 
      return false;
    } 
    // Reset if they highlight and type over first char.
    else if ($phone.val().charAt(0) !== '(') {
      $phone.val('('); 
    }

    // Auto-format- do not expose the mask as the user begins to type
    if (key !== 8 && key !== 9) {
      if ($phone.val().length === 4) {
        $phone.val($phone.val() + ')');
      }
      if ($phone.val().length === 5) {
        $phone.val($phone.val() + ' ');
      }     
      if ($phone.val().length === 9) {
        $phone.val($phone.val() + '-');
      }
    }

    // Allow numeric (and tab, backspace, delete) keys only
    return (key == 8 || 
        key == 9 ||
        key == 46 ||
        (key >= 48 && key <= 57) ||
        (key >= 96 && key <= 105)); 
  })
  
  .bind('focus click', function () {
    var $phone = $(this);
    
    if ($phone.val().length === 0) {
      $phone.val('(');
    }
    else {
      var val = $phone.val();
      $phone.val('').val(val); // Ensure cursor remains at the end
    }
  })
  
  .blur(function () {
    var $phone = $(this);
    
    if ($phone.val() === '(') {
      $phone.val('');
    }
  });



})();
   // function postdata(data) {

   //  var csrfToken = document.getElementById('csrf-token').value;

   //     $.post("{{ URL::to('vendors/vendorcategorieswiseget') }}", { input:data }, function(returned){
   //     $('.campaign').html(returned);
   //     });
   //  }   

 
   
 angular.module('app.controllers').filter('strLimit', ['$filter', function($filter) {
   return function(input, limit) {
     if (! input) return;
     if (input.length <= limit) {
          return input;
      }
    
      return $filter('limitTo')(input, limit);
   };
}]);


angular.module('app.controllers').filter('eventformat', function () {
          return function (item) {
           
           var t = item.split(/[- :]/);
           var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
           var time = d.getTime();
           
            var nextDay = new Date(d);
            nextDay.setDate(d.getDate());
            return nextDay;
          };
        });

      angular.module('app.controllers').filter('split', function()
                      {
    return function(input, delimiter) 
    {
      var delimiter1 = delimiter || ',';

      return input.split(delimiter1); 
    }; 
  });



function postdata(data)
{
    var val = data;

    var csrfToken = document.getElementById('csrf-token').value;

    $.ajax({
    type: "POST",
    url: "vendors/vendorcategorieswise/get",
    headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
    beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
        $("#campaign").html('<img src="images/loader.gif" /> Now loading...');},
    data: { id : val},
        success:function(campaigns)
        {

            $("#campaign").html(campaigns);
        }
    });
}



function contactCreateSubmit()
{
  var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  var emailid=document.getElementById('email').value;
  
  var contact_name = $('#contact_name').val();
  var contact_message = $('#contact_message').val();
  var contact_number = $('#contact_number').val();
  var contact_usertype = $('#contact_usertype').val();
  var input,input1,input2;
  var csrfToken = document.getElementById('csrf-token').value;

   input=$('#contact_name');

  var is_name=input.val();
  if(is_name!==''){$("#contact_name").next(".error").css("display","none");  input.removeClass("invalid").addClass("valid");}
  else{$("#contact_name").next(".error").css("display","block"); input.removeClass("valid").addClass("invalid");}

  input1=$('#contact_usertype');

  var is_name1=input1.val();
  if(is_name1!==''){$("#contact_usertype").next(".error").css("display","none");input1.removeClass("invalid").addClass("valid");}
  else{$("#contact_usertype").next(".error").css("display","block");input1.removeClass("valid").addClass("invalid");}    
  
  input2=$('#contact_message');
  var is_name2=input2.val();
  if(is_name2!==''){$("#contact_message").next(".error").css("display","none");input2.removeClass("invalid").addClass("valid");}
  else{$("#contact_message").next(".error").css("display","block");input2.removeClass("valid").addClass("invalid");}   



  var input3=$('#email');
  var is_name3=input3.val();
  if(is_name3!==''){
              if (filter.test(emailid)) {
    $("#email").next(".error").css("display","none");input3.removeClass("invalid").addClass("valid");
}
else
{
  $("#email").next(".error").css("display","block");input3.removeClass("valid").addClass("invalid");
}
  }
  else{$("#email").next(".error").css("display","block");input3.removeClass("valid").addClass("invalid");}   



  if(contact_name==='')
  {
    input=$('#contact_name');

   is_name=input.val();
  if(is_name!==''){input.removeClass("invalid").addClass("valid");}
  else{input.removeClass("valid").addClass("invalid");}

  }
  else if(contact_usertype==='')
  {
 input1=$('#contact_usertype');

   is_name1=input1.val();
  if(is_name1!==''){input1.removeClass("invalid").addClass("valid");}
  else{input1.removeClass("valid").addClass("invalid");}    
  }
  else if(contact_message==='')
  {
   input2=$('#contact_message');

   is_name2=input2.val();
  if(is_name2!==''){input2.removeClass("invalid").addClass("valid");}
  else{input2.removeClass("valid").addClass("invalid");}    
  }

  else
  {
   

  if (filter.test(emailid)) {
      $("#email").next(".error").css("display","none");input3.removeClass("invalid").addClass("valid");
  $.ajax({
    type: "POST",
    url: "pages/contactussub",
    headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
    beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
        $("#msg").html('<img src="images/loader.gif" /> Now loading...');},
    data: { email : emailid,contact_name:contact_name,contact_message:contact_message,contact_usertype:contact_usertype,contact_number:contact_number},
        success:function(campaigns)
        {

            $("#msg").html("Successfully Sent");
               // var alert = $mdDialog.alert({
               //      title: response.message.title,
               //      content: response.message.body,
               //      ok: 'OK'
               //  });
               //  $mdDialog
               //      .show(alert)
               //      .finally(function() {
               //           $window.location = "brides/profile?id=0";
               //      });
            $('#contact_name').val('');
            $('#contact_message').val('');
            $('#contact_number').val('');
            $('#email').val('');            
        },
        error:function(){
            $("#msg").html("Failed");
        }
    });
}
 else {
      
     $("#email").next(".error").css("display","block");input3.removeClass("valid").addClass("invalid");  
  
}

}
   
} 

function send_report()
{
  var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  var emailid=document.getElementById('email').value;
  
  var report_name = $('#report_name').val();
  var report_message = $('#report_message').val();
  var report_number = $('#report_number').val();
  var csrfToken = document.getElementById('csrf-token').value;
   input=$('#report_name');

  var is_name=input.val();
  if(is_name!==''){             $("#report_name").next(".error").css("display","none"); input.removeClass("invalid").addClass("valid");}
  else{ $("#report_name").next(".error").css("display","block"); input.removeClass("valid").addClass("invalid");}

  
  
  input2=$('#report_message');
  var is_name2=input2.val();
  if(is_name2!==''){$("#report_message").next(".error").css("display","none");input2.removeClass("invalid").addClass("valid");}
  else{$("#report_message").next(".error").css("display","block");input2.removeClass("valid").addClass("invalid");}   

  var input3=$('#email');
  var is_name3=input3.val();
  if(is_name3!==''){
              if (filter.test(emailid)) {
    $("#email").next(".error").css("display","none");input3.removeClass("invalid").addClass("valid");
}
else
{
  $("#email").next(".error").css("display","block");input3.removeClass("valid").addClass("invalid");
}
  }
  else{$("#email").next(".error").css("display","block");input3.removeClass("valid").addClass("invalid");}   

  if(report_name==='')
  {
    input=$('#report_name');

   is_name=input.val();
  
  input.removeClass("valid").addClass("invalid");

  }
  else if(report_message==='')
  {
   input2=$('#report_message');

   is_name2=input2.val();
  
  input2.removeClass("valid").addClass("invalid");
  }
  else if(emailid==='')
  {
    
     
    if (filter.test(emailid)) {
      $("#email").next(".error").css("display","none");input3.removeClass("invalid").addClass("valid");
        
    }
    else {
       $("#email").next(".error").css("display","block");input3.removeClass("valid").addClass("invalid");
    }
  }

else
{
  
  
    if (filter.test(emailid)) {
      $("#email").next(".error").css("display","none");input3.removeClass("invalid").addClass("valid");

    $.ajax({
    type: "POST",
    url: "pages/reportsub",
    headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
    beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
        $("#msg").html('<img src="images/loader.gif" /> Now loading...');},
    data: { email : emailid,report_name:report_name,report_number:report_number,report_message:report_message},
        success:function(campaigns)
        {

            $("#msg").html("Successfully Sent");
            $('#report_name').val('');
            $('#email').val('');
            $('#report_message').val('');
            $('#report_number').val('');            
        },
        error:function(){
              $("#msg").html("Failed");
        }
    });     
    }
    else {
      
     $("#email").next(".error").css("display","block");input3.removeClass("valid").addClass("invalid");
  
  
}
}
   
} 


    function isNumberKey(evt){
    var charCode = (evt.which) ? evt.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
}

function showDetails(data) 
{

 
    var val = data;
    var csrfToken = document.getElementById('csrf-token').value;
     $.ajax({
    type: "POST",
    url: "brides/services/requests/updatepost",
    headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
        beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
        },
    data: { services_id : val},
        success:function(campaigns)
        {
            // $("#campaign").html(campaigns);
        }
    });
} 

function deleterecord(data,cat) 
{
    var val = data;
    var vals = cat;
    var csrfToken = document.getElementById('csrf-token').value;
    if (confirm("Are you sure want to delete?")) {
     $.ajax({
    type: "POST",
    url: "brides/services/requests/updateposts",
    headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
        beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
        },
    data: { services_id : val,cat_id : vals },
        success:function(campaigns)
        {
            location.reload();
        }
    });
   }

   return false;

} 

function clearsen()
{

     sessionStorage.miles='';
     sessionStorage.types='';
}

function titleUpdater(){
	var url = window.location.href.split('/');
	var urlLength = url.length;
	var lastParams = url[urlLength - 1];
	var headerText;

	if(lastParams == 'invitefriends')
		headerText = "invite friends";
	if(!isNaN(lastParams)){
		if(url[urlLength-2] == "create" && url[urlLength-3] == "requests"){
			headerText = "create service request";
		}
        	else if(url[urlLength-2] == "requests" && url[urlLength-3] == 'brides')
			headerText = "service request";
	}
	if(lastParams == "update" && url[urlLength-2] == "requests" && url[urlLength-3] == "services" )
		headerText = "my service request";
	else if(lastParams == "findvendorslists")
		headerText = "find vendors";
	else if(lastParams == "profile?id=0")
		headerText = "event details";
	else if(lastParams == "profile?id=1")
		headerText = "account information";
	else if(lastParams == "profile?id=2")
		headerText = "notification preference";
	else if(lastParams == "profile")
		headerText = "my profile";
	else if(lastParams == "accepted" && url[urlLength-2] == "requests" && url[urlLength-3] == "brides")
		headerText = "service requests";
	else if(lastParams == "requests" && url[urlLength-2] == "brides")
		headerText = "service request";
	else if(lastParams == "dashboard" && url[urlLength-2] == "vendors")
		headerText = "my dashboard";
	else if(lastParams == "myprofile" && url[urlLength-2] == "vendors")
		headerText = "my profile";
	else if(lastParams == "requests" && url[urlLength-2] == "vendors")
		headerText = "bids and leads";
	else if(lastParams == "dashboard")
		headerText = "dashboard";
	else if(lastParams == "myprofile" && url[urlLength-2] == 'vendors')
		headerText = "my profile";
	else if(lastParams == "requests" && url[urlLength-2] == 'vendors')
		headerText = "active leads";
	else if(lastParams == "placedbids")
		headerText = "bids and leads";
	else if(lastParams == "acceptedbids")
		headerText = "bids and leads";
	else if(lastParams == "lostbids")
		headerText = "bids and leads";
	else if(lastParams == "myprofile?id=0")
		headerText = "business details";
	else if(lastParams == "myprofile?id=1")
		headerText = "portfolio";
	else if(lastParams == "myprofile?id=2")
		headerText = "account information";
	else if(lastParams == "myprofile?id=3")
		headerText = "notification preference";
        else if(lastParams == "contact-us")
                headerText = "Contact us";
	else if(lastParams == "report-problem")
                headerText = "Report problem";
	else
		$(document).attr("title", "brides genie");

	if (typeof headerText !== 'undefined')
		$(document).attr("title", "bridesgenie - "+headerText);

}

// function activeLeadslists(data)
// {
//     var val = data;
//    // alert(val);

//     var csrfToken = document.getElementById('csrf-token').value;

//     $.ajax({
//     type: "POST",
//     url: "vendors/vendorcategorieswise/getactiveLeadslists/"+data,
//     headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
//     beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
//         $("#campaign").html('<img src="images/loader.gif" /> Now loding...');},
//     data: { id : val},
//         success:function(campaigns)
//         {
//             $("#campaign").html(campaigns);
//         }
//     });
// }
