(function() {
  "use strict";

  angular.module('app.controllers').controller('BridesAcceptedRequestsController', function($scope, $state,$stateParams, $http, BridesProfileService, NotificationService) {

    var selected_service_id = $scope.Categories;
    $scope.selectedCategories = selected_service_id;

    var formData = {

      selectedCategories: $scope.selectedCategories,

    };
    var eventid = $stateParams.id;
    $scope.eventid = $stateParams.id;
    $http({

      url: "brides/requests/accepted/"+eventid,
      data: formData,
      method: 'POST',


    }).success(function(response) {
      $scope.findVendors = response;
      $scope.serviceRequestsCount = $scope.findVendors.length;
      //  location.href = 'pages/findvendorsresults';

    });

    

$scope.eventIds = $stateParams.id;


            BridesProfileService.geteventnamedetail($stateParams.id).success(function(response)
            {
            $scope.eventName = response;
            });


    $scope.serviceRequests = {};

      var path = window.location.pathname;
      var str = path.split("/");
      var url5 =  str[5];
      var url6 =  str[6];

    BridesProfileService.getAcceptedRequests(eventid).success(function(response) {

      $scope.serviceRequests = response;
      //console.log($scope.serviceRequests);
      $scope.serviceRequestsCount = $scope.serviceRequests.length;

      // NotificationService.serverAlert(response.message.title, response.message.body);
    });
    $scope.get_distance = function (event) {

    sessionStorage.miles ='';
    sessionStorage.types='';


};

    BridesProfileService.selectedserviceslist().success(function(response) {

      $scope.myCategories = response;

    });

     BridesProfileService.brideEventStatus().success(function(response) {

                $scope.eventStatus = response;
                
            });





  BridesProfileService.currentEventid().success(function(response) {

                $scope.currenteventId = response;
            });




    $scope.sort = function(keyname) {
      $scope.sortKey = keyname; //set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    };

    $scope.brideActivelists = function() {

      var selected_service_id = $scope.Categories;
      $scope.selectedCategories = selected_service_id;

      var formData = {

        selectedCategories: $scope.selectedCategories,

      };
      $http({

        url: "brides/requests/accepted/"+eventid,
        data: formData,
        method: 'POST',


      }).success(function(response) {
        $scope.findVendors = response;
        $scope.serviceRequestsCount = $scope.findVendors.length;
        //  location.href = 'pages/findvendorsresults';

      });

    };
  });

  angular.module('app.controllers').filter('exformat', function() {
    return function(item) {

      var t = item.split(/[- :]/);
      var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
      var time = d.getTime();

      var nextDay = new Date(d);
      nextDay.setDate(d.getDate() + 15);
      return time;
    };
  });

})();
