(function() {
  "use strict";

  angular.module('app.controllers').controller('VendorslostBiddingsController', function($window, $scope, $mdDialog, $state, $stateParams, VendorsProfileService, NotificationService) {

    $scope.serviceRequest = {};
    var service_fee = " ";
    VendorsProfileService.getServiceFeeDetails()
      .success(function(response) {
        $scope.service_fee = response;
        //console.dir(response);
        var service_fee = $scope.service_fee;
        //console.dir($scope.service_fee);
      });

    VendorsProfileService.getServiceRequestLostDetails($stateParams.id)
      .success(function(response) {

        $scope.serviceRequest = response;
        jQuery('.dateLostClass').css('display','');

      });

    VendorsProfileService.getServiceRequestLostoffer()
      .success(function(response) {

        $scope.lostOffer = response;
        $scope.lostOfferCount = $scope.lostOffer.length;

      });

    VendorsProfileService.getServiceRequestMiniumDetails($stateParams.id)
      .success(function(response) {

        $scope.serviceRequest_minimum = response;
        $scope.minimumCount = $scope.serviceRequest_minimum.length;
        //console.log($scope.serviceRequest_minimum);

      });

    VendorsProfileService.getServiceRequestIdVendor($stateParams.id)
      .success(function(response) {
        $scope.serviceRequestIdshow = response;
      });

    // VendorsProfileService.getActiveBiddingcount($stateParams.id).success(function(response) {
    // $scope.biddingcount = response;
    // //console.log($scope.serviceRequests);
    // });

    VendorsProfileService.getcurrentvendorid().success(function(response) {

      $scope.currentvid = response;

    });
    $scope.sort = function(keyname) {
      $scope.sortKey = keyname; //set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    };

  });

  angular.module('app.controllers').filter('format', function() {
    return function(item) {

      var t = item.split(/[- :]/);
      var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
      var time = d.getTime();

      var nextDay = new Date(d);
      nextDay.setDate(d.getDate() + 15);
      return nextDay;
    };
  });

  angular.module('app.controllers').filter('cut', function() {
    return function(value, wordwise, max, tail) {
      if (!value) return '';

      max = parseInt(max, 10);
      if (!max) return value;
      if (value.length <= max) return value;

      value = value.substr(0, max);
      if (wordwise) {
        var lastspace = value.lastIndexOf(' ');
        if (lastspace != -1) {
          value = value.substr(0, lastspace);
        }
      }

      return value + (tail || ' …');
    };
  });
  angular.module('app.controllers').filter('dateformat', function() {
    return function(item) {
      var t = item.split(/[- :]/);
      var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
      var time = d.getTime();
      return time;
    };
  });

})();
$('body').on('keyup', '#number_only', function(e) {
  var value = $(this).val();
  value = value.replace(/^(0*)/, "");
  $(this).val(value);
  if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
    this.value = this.value.replace(/[^0-9\.]/g, '');
  }
});
