(function(){
    "use strict";

    angular.module('app.config').config(['cfpLoadingBarProvider', function(cfpLoadingBarProvider) {

        cfpLoadingBarProvider.includeSpinner = false;

    }]);

})();
