(function(){
    "use strict";

    angular.module('app.controllers').controller('RegistrationController', function( $rootScope,$scope,$state,AuthService,NotificationService,vcRecaptchaService,$window ){

        $scope.errorMessages = {};
        $scope.user = {};

        $scope.response = null;
        $scope.widgetId = null;

        $scope.captchaStatus = true;

        $scope.setResponse = function(response) {
$scope.gotCaptchaResponse = true;
            $scope.user.grecaptcharesponse = response;
        };

        $scope.setWidgetId = function (widgetId) {
            $scope.widgetId = widgetId;
        };

        $scope.checkReCaptcha = function(){

if($scope.gotCaptchaResponse === true){
 $scope.captchaStatus = true;
              $scope.registrationForm.$valid = true;
              return true;

}
          if ( vcRecaptchaService.getResponse() === "" ) {
              $scope.captchaStatus = false;
              $scope.registrationForm.$valid = false;
              return false;
          }
          else{
              $scope.captchaStatus = true;
              $scope.registrationForm.$valid = true;
              return true;
          }

        };

        $scope.registrationSubmit = function(){

           var auth = AuthService.register($scope.user);

           auth.success(function(response){

                NotificationService.serverAlert(response.message.title,response.message.body);

           }).error(function(response) {

                _.each(response, function(errors, key) {
                    _.each(errors, function(e) {
                      $scope.registrationForm[key].$dirty = true;
                      $scope.registrationForm[key].$setValidity(key, false);
                      $scope.errorMessages[key] = e;
                    });
                });

                vcRecaptchaService.reload($scope.widgetId);
$scope.gotCaptchaResponse = false;
                //$scope.captchaStatus = false;

           });

        };

    });

})();

angular.module('app.controllers').directive('replace', function() {
  return {
    require: 'ngModel',
    scope: {
      regex: '@replace',
      with: '@with'
    }, 
    link: function(scope, element, attrs, model) {
      model.$parsers.push(function(val) {
        if (!val) { return; }
        var regex = new RegExp(scope.regex);
        var replaced = val.replace(regex, scope.with); 
        if (replaced !== val) {
          model.$setViewValue(replaced);
          model.$render();
        }         
        return replaced;         
      });
    }
  };
});

