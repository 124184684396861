(function() {
  "use strict";

  angular.module('app.controllers').controller('VendorsPlacedRequestsController', function($scope, $state, $stateParams, $http, VendorsProfileService, NotificationService) {

    var selected_service_id = $scope.Categories;
    $scope.selectedCategories = selected_service_id;

    var formData = {

      selectedCategories: $scope.selectedCategories,

    };
    $http({

      url: "vendors/placedbids",
      data: formData,
      method: 'POST',


    }).success(function(response) {
      $scope.findVendors = response;
      $scope.serviceRequestsCount = $scope.findVendors.length;
      //  location.href = 'pages/findvendorsresults';

    });


    $scope.serviceRequests = {};
    var values = {};
    //console.log($stateParams.id);
    VendorsProfileService.getAllPlacedServiceRequests().success(function(response) {
      $scope.serviceRequests = response;

      $scope.serviceRequestsCount = $scope.serviceRequests.length;
      // console.log($scope.serviceRequests_count);

    });

    VendorsProfileService.getcurrentvendorid().success(function(response) {

      $scope.currentvid = response;

    });

    VendorsProfileService.selectedserviceslist().success(function(response) {
      $scope.allCategories = response;
    });
    // VendorsProfileService.getActiveBiddingcount($stateParams.id).success(function(response) {
    //     $scope.biddingcount = response;
    //     //console.log($scope.serviceRequests);
    // });

    // VendorsProfileService.getallPlacedDetails().success(function(response) {
    //     $scope.placedbibsdetails = response;

    // });

    $scope.sort = function(keyname) {
      $scope.sortKey = keyname; //set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    };
    $scope.brideActivelists = function() {

      var selected_service_id = $scope.Categories;
      $scope.selectedCategories = selected_service_id;

      var formData = {

        selectedCategories: $scope.selectedCategories,

      };
      $http({

        url: "vendors/placedbids",
        data: formData,
        method: 'POST',


      }).success(function(response) {
        $scope.findVendors = response;
        $scope.serviceRequestsCount = $scope.findVendors.length;
        //  location.href = 'pages/findvendorsresults';

      });


    };

    $scope.showData = function() {

      $scope.itemsPerPage = 5;
      $scope.currentPage = 0;
      $scope.datalists = $scope.serviceRequests;
      $scope.range = function() {
        var rangeSize = 5;
        var ps = [];
        var start;

        start = $scope.currentPage;
        //  console.log($scope.pageCount(),$scope.currentPage)
        if (start > $scope.pageCount() - rangeSize) {
          start = $scope.pageCount() - rangeSize + 1;
        }

        for (var i = start; i < start + rangeSize; i++) {
          if (i >= 0)
            ps.push(i);
        }
        return ps;
      };

      $scope.prevPage = function() {
        if ($scope.currentPage > 0) {
          $scope.currentPage--;
        }
      };

      $scope.DisablePrevPage = function() {
        return $scope.currentPage === 0 ? "disabled" : "";
      };

      $scope.pageCount = function() {
        return Math.ceil($scope.datalists.length / $scope.itemsPerPage) - 1;
      };

      $scope.nextPage = function() {
        if ($scope.currentPage < $scope.pageCount()) {
          $scope.currentPage++;
        }
      };

      $scope.DisableNextPage = function() {
        return $scope.currentPage === $scope.pageCount() ? "disabled" : "";
      };

      $scope.setPage = function(n) {
        $scope.currentPage = n;
      };

    };


  });

  angular.module('app.controllers').filter('format', function() {
    return function(item) {

      var t = item.split(/[- :]/);
      var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
      var time = d.getTime();

      var nextDay = new Date(d);
      nextDay.setDate(d.getDate() + 15);
      return nextDay;
    };
  });

})();

angular.module('app.controllers').filter('pagination', function() {
  return function(input, start) {
    if (!input || !input.length) {
      return;
    }
    start = +start; //parse to int
    return input.slice(start);
  };
});
