(function() {
    "use strict";

    angular.module('app.controllers').controller('BridesRequestController12', function($scope, $state, VendorsProfileService, BrideRequestService, NotificationService) {

        $scope.allCategories = {};

        $scope.serviceCreate = {};

        VendorsProfileService.getAvaliableServicecategories().success(function(response) {
            $scope.allCategories = response;
        });

        $scope.brideRequestFormSubmit = function() {

            var brideRequestForm = BrideRequestService.saveRequest($scope.serviceCreate);

            brideRequestForm.success(function(response) {

              NotificationService.serverAlert(response.message.title, response.message.body);
              location.href = 'brides/requests';

            }).error(function(response) {

                //  _.each(response, function(errors, key) {
                //      _.each(errors, function(e) {
                //        $scope.registrationForm[key].$dirty = true;
                //        $scope.registrationForm[key].$setValidity(key, false);
                //        $scope.errorMessages[key] = e;
                //      });
                //  });

            });

        };

    });


})();

jQuery(document).ready(function(){

$('body').on('keyup','#number_only',function(e){
    var value = $(this).val();
      value = value.replace(/^(0*)/,"");
      $(this).val(value);
  if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
     this.value = this.value.replace(/[^0-9\.]/g, '');
  }
});
});
