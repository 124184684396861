(function() {
    "use strict";
    
    angular.module('app.controllers').controller('ContactusController', function($scope,$http, $state,$mdDialog, $stateParams, CmsService, NotificationService, $window) {

    $scope.isDisabled = false;
         var formData = {
              lat:  '',
              
            };
      
        $http({

              url: "pages/contact-us",
              data: formData,
              method: 'POST',
            }).success(function(response){
             $scope.findcontactus = response;
             $scope.findcontactusCount =$scope.findcontactus.length;
             $scope.contact_name=response.uname[0].name;

             $scope.readonlycontact_name=response.uname[0].name;
             $scope.readonlyemail=response.uname[0].email;

             $scope.email=response.uname[0].email;
             $scope.contact_number=response.uname[0].phone_number;
             $scope.readonlycontact_number=response.uname[0].phone_number;
             $scope.selectedcat=response.uname[0].role_name;
               //$('#alpha_only').prop('disabled', true); 

                      //$('#contact_name').val(response.uname[0].name);
                      //$('#alpha_only').attr('readonly', true);
                       //$('#email').val(response.uname[0].email);
                        //$('#email').attr('readonly', true);
                         if(response.uname[0].role_name!=='')
                           { 
                         $('#contact_usertype')
                          .find('option')
                          .remove()
                          .end()
                          .append('<option value='+response.uname[0].role_name+'>'+response.uname[0].role_name+'</option>')
                          .val(response.uname[0].role_name);
                          }
                        //  var phone=response.uname[0].phone_number;
                        //   var formatted;
                        //  if(response.uname[0].phone_number!=="")
                        //  {
                        //  // formatted = phone.substr(0, 3) + '-' + phone.substr(3, 3) + '-' + phone.substr(6,4);
                        //                      //$('#contact_number').val(phone);
                        //  //$('#contact_number').attr('readonly', true);
                        //  }
                        //  else
                        //  {
                           
                      
                        // //$('#contact_number').val(phone);
                        // //$('#contact_number').removeAttribute("readonly",0);
                          
                        //  }
              
            }).error(function(){
              
                $scope.serviceRequestsCount =0;
            });
             
        $scope.allCategories = [{}];

        $scope.serviceCreate = {};
        $scope.lengths=''; 
        

        

          
        $scope.bridesevicelists = function(){
          console.log("1");

            // var selected_service_id = $scope.Categories;
            // $scope.selectedCategories = selected_service_id;

            // var formData = {

            // selectedCategories:  $scope.selectedCategories,

            // };
            //     $http({

            //     url: "pages/servicelists",
            //     data: formData,
            //     method: 'POST',


            //     }).success(function(response){
            //     $scope.findVendors = response;
            //     $scope.serviceRequestsCount = $scope.findVendors.length;

            //     //  location.href = 'pages/findvendorsresults';

            //     });
        };
        $scope.changecategories = function () {
           // alert('1');
   var selected_service_id = $scope.someModel;
            $scope.selectedcat = selected_service_id;

   
 };
        $scope.contactsubmit=function(){
                    $scope.isDisabled = true;

             var formData = {
              contact_name: $scope.contact_name,
              contact_number: $scope.contact_number,
              email :  $scope.email,
              contact_message:$scope.contact_message,
              contact_usertype: $('#contact_usertype').val(),
            };
            //alert($scope.contact_name);
            //$('#contact_name').val();

             $http({

              url: "pages/contactussub",
              data: formData,
              method: 'POST',
            }).success(function(response){
                  var alert = $mdDialog.alert({
                    title: 'Attention',
                    content: 'Thanks for your interest.',
                    ok: 'OKAY'
                });
                $mdDialog
                    .show(alert)
                    .finally(function() {
                         $window.location = "/pages/contact-us";
                    });
                             
             }).error(function(){
              
                $scope.serviceRequestsCount =0;
            });


         //   data: { email : emailid,contact_name:contact_name,contact_message:contact_message,contact_usertype:contact_usertype,contact_number:contact_number},
          
        };


        

    });
        


})();


jQuery(document).ready(function(){

$('body').on('keyup','#number_only',function(e){
    var value = $(this).val();
      value = value.replace(/^(0*)/,"");
      $(this).val(value);
  if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
     this.value = this.value.replace(/[^0-9\.]/g, '');
  }
});
});
