(function() {
    "use strict";

$('body').on('keyup','#zip_code_vendor',function(e){
       $('#zip_code_vendor').geocomplete({
          details: "#vendorsearch",
        }).bind("geocode:result", function(event, result){

          $('#vendorsearch input[name="locality"]').prop("disabled", true);
          $('#vendorsearch input[name="administrative_area_level_1"]').prop("disabled", true);

          if($('#vendorsearch input[name="locality"]').val().length === 0){
            $('#vendorsearch input[name="locality"]').prop("disabled", false);
          }

          if($('#vendorsearch input[name="administrative_area_level_1"]').val().length === 0){
            $('#vendorsearch input[name="administrative_area_level_1"]').prop("disabled", false);
          }
      });
    });

    angular.module('app.controllers').controller('FindvendorsDetailsController', function($scope, $stateParams, $state,$http, CmsService,VendorsProfileService, NotificationService) {
      
     
        //$scope.miles='a';
       if (sessionStorage.miles !=='')
       {
        if (sessionStorage.miles === 'undefined')
       {
       
        sessionStorage.miles='';
        $scope.miles='';
       

       }
       else
       {
        $scope.miles ='Around '+ sessionStorage.miles+'miles';
        }
        
       }
       
       else
       {
        sessionStorage.miles='';
        $scope.miles='';
         sessionStorage.types='';
        $scope.types ='';
       }
       if (sessionStorage.types !=='')
       {

           $scope.types =sessionStorage.types;
       }
       else
       {
        sessionStorage.types='';
        $scope.types ='';
       }

        $scope.serviceRequests = {};

        //$scope.datalists = {};

            $scope.vendorsDetails = [];

        VendorsProfileService.getAllServiceCategories().success(function(response) {

            $scope.allCategories = response;

        });

         CmsService.getfindvendorsdetails($stateParams.id).success(function(response) {
            $scope.vendorsDetails = response;

            $scope.bussinessName = $scope.vendorsDetails.vendor_attributes[0].business_name;
            
            //$scope.vendorsDetails =id;
            //console.log(vendorsDetails);

        });




    });



})();

angular.module('app.controllers').run(['$window', '$rootScope', function ($window ,  $rootScope) {
  $rootScope.goBack = function(){
    $window.history.back();
  };
}]);
