(function(){
  "use strict";
  

$('body').on('keyup','#zip_code_vendors_myprofile',function(e){
       $('#zip_code_vendors_myprofile').geocomplete({
          details: "#vendorsMyprofile",
	  types: ['(regions)'],
	  type: ['postal_code','(cities)'],
          componentRestrictions: {country: 'us'},
        }).bind("geocode:result", function(event, result){

          $('#vendorsMyprofile input[name="locality"]').prop("disabled", true);
          $('#vendorsMyprofile input[name="administrative_area_level_1_short"]').prop("disabled", true);

          if($('#vendorsMyprofile input[name="locality"]').val().length === 0){
            $('#vendorsMyprofile input[name="locality"]').prop("disabled", false);
          }

          if($('#vendorsMyprofile input[name="administrative_area_level_1_short"]').val().length === 0){
            $('#vendorsMyprofile input[name="administrative_area_level_1_short"]').prop("disabled", false);
          }
      });
    });

angular.module('app.controllers').controller('VendorsImagesUploadController', function($window,$scope, $state, VendorsProfileService, NotificationService, $stateParams, $mdDialog, $http, $timeout, $compile, $interval,Upload) {

$scope.showAdvancedProfile = function(ev,$serviceid) {
          $scope.sevice_id = $serviceid;
          $scope.obj = {service_id:$serviceid};
           var self = this;
            this.item = $serviceid;
            $mdDialog.show({
                controller: 'VendorsImagesUploadController',
                templateUrl: '/views/image-upload/images-upload.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                scope: this.$new(),
                locals:{
                       parent: $scope
                        },
                bindToController: true,

            });
        };

        $scope.close = function() {

          $mdDialog.hide();
          $window.location.reload();

        };
        
            $scope.closeDialog = function() {
              $mdDialog.hide();
            };

        $scope.submit = function() {
 VendorsProfileService.getAllServiceImagesCount().success(function(response) 
           {

		$('#submit').hide();
              $('#loading-text').css({ 'display': "block" });
               $scope.image_count_from_db  = response;
               $scope.upload($scope.file,response);
             });

          

        };

        $scope.uploadThen = function (data, status, headers, config) {
if(data.data.success){
$scope.uploadMessageContent += data.data.fileName + " has been successfully uploaded.<br>";

}else{
$scope.uploadMessageContent += data.data.fileName + " upload failed."+" "+data.data.message.image+".<br>";
}

              ++$scope.fileUploaded;              
            if($scope.fileSubmittedCount == $scope.fileUploaded){

 $mdDialog.show({
          controller: 'VendorsImagesUploadController',
          clickOutsideToClose: true,
          scope: $scope,      
          preserveScope: true, 
parent: angular.element(document.body),
                locals:{
                       parent: $scope
                        },
                bindToController: true,
          template: '<md-dialog aria-label="Attentions.jpeg has been ..." md-theme="black" class="md-black-theme transition-in" role="alertdialog" tabindex="-1" aria-describedby="dialog_40">'+'<md-dialog-content tabindex="-1" role="document" id="dialog_40">'+
'<h2 class="md-title ng-binding">Attention</h2><p class="ng-binding">'+$scope.uploadMessageContent+'</p></md-dialog-content><div class="md-actions"><button class="md-primary md-button md-black-theme" onClick="window.location.reload()" tabindex="0"><span class="ng-binding ng-scope">OKAY</span></button></div></md-dialog>',

       });

            }

        };

        $scope.upload = function (files,dbfilecount) {


          if ($('#onetimeloadimage').length > 0) {
            return false;
            }
            $('<input>').attr({
            type: 'hidden',
            id: 'onetimeloadimage',
            name: 'onetimeloadimage'
            }).appendTo('body');

$scope.uploadMessageContent = "";
          $scope.fileList = [];
          
          var service_id = $("input[name='sevice_id']").val();
          var fileSubmittedCount = files.length;
          $scope.fileSubmittedCount = fileSubmittedCount;
          $scope.fileUploaded = 0;
          var totalFilesAfterUpload = (+fileSubmittedCount) + (+dbfilecount);

           if (files && files.length  && ((totalFilesAfterUpload <= 12))) {
            for (var i=0; i < fileSubmittedCount; ++i) {

              var file = files[i];
              Upload.upload({
                url: '/vendors/profile/images/add/'+ service_id ,
                file: file
            }).then($scope.uploadThen);

            }
          
           }else{

var alerts1 = $mdDialog.alert({
                title: "Attention",
                content: "You have reached a maximum number of photos uploading limit.",
                ok: 'OKAY'
                });
                $mdDialog
                .show(alerts1)
                .finally(function() {
               $window.location = "/vendors/myprofile?id=1";
                }); 
            
           }
        };

 });


angular.module('app.controllers').controller('VendorsMyProfileController', function($scope, $state, $stateParams, $http, $window, VendorsProfileService, AuthService, NotificationService,$mdDialog) {
       

       

   $scope.service_requestbride_notify = function($value) {

      $http({
            url: "vendors/changeservicebride/" + $value,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
            }).success(function(response){

            });

   };

    $scope.bid_submit_notify = function($value) {

      $http({
            url: "vendors/changebidsubmit/" + $value,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
            }).success(function(response){

            });

   };


     $scope.bid_won_notify = function($value) {

      $http({
            url: "vendors/changebidwon/" + $value,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
            }).success(function(response){

            });

   };


     $scope.bid_lost_notify = function($value) {

      $http({
            url: "vendors/changebidlost/" + $value,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
            }).success(function(response){

            });

   };


        $scope.state = $state.current.name;
        var selected = window.location.href;
        var selectedid = selected.split("=")['1'];
        $scope.selectedTab = selectedid;

        $scope.allCategories = {};

        $scope.vendor_details = {};

        $scope.vendor = {};

        $scope.resetaccountinfo={};

        $scope.errorMessages = {};

        $scope.selectedservices ={};

        $scope.selectedservicesarealist ={};

        $scope.notificationinfo_save={};

        $scope.vendor_details.serviceCategories = {};

        $scope.accountinfoForm = {};

        VendorsProfileService.getAllServiceCategories().success(function(response) {

        $scope.allCategories = response;

        $scope.selectables = response;

       

    // this is the model that's used for the data binding in the select directive
    // the default selected item
    //using value, i want to set the selected value

        VendorsProfileService.selectedserviceslist().success(function(response) {

              $scope.cservices = [];

              $scope.selectedservices = response;

               // $scope.cats = [{id:1, name:"Photographer"}, {id:2, name: "Videographer"},{id:3, name: "Wedding Planner"}, {id:4, name: "Florist"}, {id:5, name: "Hotels"}, {id:6, name: "DJ"}, {id:7, name: "Catering"}];

                $scope.user = {};

                $scope.user.cats = response;

              var values = $scope.selectedservices;
              var log = [];
              angular.forEach(values, function(value, key) {
              this.push(""+value.id+"");

             // var item = {'service_name':value.name,'service_ide':value.id};
              var item = {'service_ide':value.id};
                  $scope.cservices.push(item);
                 // console.log($scope.cservices.push(item));

              }, log);

              $scope.selectedCategories = log;
                  //     $http.get('/vendors/myprofile/selectedservice').success(function(selectedservice) {
                  //     $scope.selectedservices = selectedservice;
                  //     console.log(selectedservice);
                  // });
               });

        });


      VendorsProfileService.selectedservicesarea().success(function(response) {

        $scope.selectedarea = [
            { name: 'No Restriction', id: 'type1'},
            { name: '0 - 250 miles', id: 'type2'},
            { name: '0 - 100 miles', id: 'type3'},
        ];

        $scope.selectedamount = [
            { name: 'No Minimum', id: 'amount1'},
            { name: '$1000', id: 'amount2'},
            { name: '$2000', id: 'amount3'},
            { name: '$5000', id: 'amount4'},
            { name: '$10000', id: 'amount5'},
        ];

 //         $scope.colors = [
 //            { name: 'No Restriction', value: 'type1'},
 //            { name: '100 – 250 miles', value: 'type2'},
 //            { name: '100 miles', value: 'type3'}
 // ];

       $scope.selectedservicesarealist = response;
              var values = $scope.selectedservicesarealist;
              var log = [];
              angular.forEach(values, function(value, key) {
              this.push(""+value.service_area+"");
              }, log);
              $scope.selectedService = log;
       });

        VendorsProfileService.showmyProfile().success(function(response) {
            $scope.vendor_details = response;
            $scope.vendor.default_cat = response.default_selected_category;
            $scope.vendor.service_catSelect = response.additional_selected_category;
            $scope.service_area = response.attributes.service_area;         
        }); 


        VendorsProfileService.getAllServiceCategories().success(function(response) {
            $scope.items = response;
            $scope.filteredItems = response;
            $scope.filteredItems = [];
              angular.forEach($scope.items, function(val, key){
             
                  if(key != $scope.vendor.default_cat)
                  {

                  var item = {'val':val,'key':key};

                  // this.push(items);
                  $scope.filteredItems.push(item);

                  }   

              },$scope.filteredItems);                
            });

          $scope.$watch('vendor.default_cat',function(){
          
                $scope.filteredItems = [];
                angular.forEach($scope.items, function(val, key){
               
                    if(key != $scope.vendor.default_cat)
                    {

                    var item = {'val':val,'key':key};

                    // this.push(items);
                    $scope.filteredItems.push(item);

                    }   

                },$scope.filteredItems); 
            });
              $scope.vendor.other_cat = $("input[name='other_cat']").val();
            $scope.vendor.default_cat = $("input[name='default_cat']").val();

       
        $scope.vendor_details.user = {};
        $scope.selectedCategories ={};
        $scope.service_area ={};


        $scope.saveVendorCategories = function(serviceCategories) {

            var saveCateogries = VendorsProfileService.saveServiceCategories($scope.vendor);

            saveCateogries.success(function(response) {

                NotificationService.serverNotificationRedirectSamePage(response.message.title, response.message.body);

            }).error(function(response) {

                //  _.each(response, function(errors, key) {
                //      _.each(errors, function(e) {
                //        $scope.registrationForm[key].$dirty = true;
                //        $scope.registrationForm[key].$setValidity(key, false);
                //        $scope.errorMessages[key] = e;
                //      });
                //  });
            });
        };


        $scope.accountinfoSubmit = function(vendor_account_details){

          var formData = {
          name: $scope.vendor.first_name = $("input[name='first_name']").val(),  
          user_id: $scope.vendor.user_id = $("input[name='user_id']").val(),
          email: $scope.vendor.email_id = $("input[name='email_id']").val(),
          password:   $scope.vendor.current_password = $("input[name='current_password']").val(),
          new_password:  $scope.vendor.new_password = $("input[name='new_password']").val(),
//confirm_password:  $scope.vendor.password_confirmation = $("input[name='password_confirmation']").val(),
        };

        //console.log(formData);

          $http({

            url: "vendors/myprofile/accountinfo",
            data: formData,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

            }).success(function(response){

            //console.log("OK", response);
           
                 var alert = $mdDialog.alert({
                    title: response.message.title,
                    content: response.message.body,
                    ok: 'OKAY'
                });
                $mdDialog
                    .show(alert)
                    .finally(function() {
                         $window.location = "/vendors/myprofile?id=2";
                    });

            }).error(function(response) {

                _.each(response, function(errors, key) {
                    _.each(errors, function(e) {
                      $scope.accountinfoForm[key].$dirty = true;
                      $scope.accountinfoForm[key].$setValidity(key, false);
                      $scope.errorMessages[key] = e;
                    });
                });

                vcRecaptchaService.reload($scope.widgetId);
                //$scope.captchaStatus = false;

           });


          // alert(1);
       //     var accountinfo = VendorsProfileService.resetaccountinfo();

          // console.log(accountinfo);

       //     accountinfo.success(function(response){

       //          //NotificationService.serverAlert(response.message.title,response.message.body);

       //     }).error(function(response) {

       //          _.each(response, function(errors, key) {
       //              _.each(errors, function(e) {
       //                $scope.accountinfoForm[key].$dirty = true;
       //                $scope.accountinfoForm[key].$setValidity(key, false);
       //                $scope.errorMessages[key] = e;
       //              });
       //          });

       //          vcRecaptchaService.reload($scope.widgetId);
       //          //$scope.captchaStatus = false;

       //     });

        };

        $scope.notificationsinfoSubmit = function(){



            var notificationinfo_save = VendorsProfileService.notificationsinfo();

            console.log(notificationinfo_save);

            notificationinfo_save.success(function(response){

                NotificationService.serverNotificationRedirectSamePage(response.message.title,response.message.body);

           }).error(function(response) {

                _.each(response, function(errors, key) {
                    _.each(errors, function(e) {
                      $scope.notificationsinfoForm[key].$dirty = true;
                      $scope.notificationsinfoForm[key].$setValidity(key, false);
                      $scope.errorMessages[key] = e;
                    });
                });

           });

        };
         $scope.showSelectValue = function(service_areaSelect) {
                  var service_area = service_areaSelect;
                  $scope.selectedarea = service_areaSelect;
                  var selectedarea= $scope.vendor.selectedarea = service_areaSelect;
                  //console.log($scope.selectedarea);
                  // alert(service_area);
                  };
         $scope.showSelectValue_cat = function(service_catSelect) {
                  var serviceCategories = service_catSelect;
                  $scope.selectedCategoriess = service_catSelect;
                  $scope.vendor.service_catSelect = service_catSelect;

                  };

         $scope.defaultSelectValue_cat = function(defaultCat) {
                  var defaultCategoriess = defaultCat;
                  $scope.defaultCategory = defaultCat;
                  $scope.vendor.default_cat = defaultCat;

                  };
        


        $scope.saveVendorCategories = function(service_catSelect) {

            var saveCateogries = VendorsProfileService.saveServiceCategories($scope.vendor);

        };


        $scope.vendorProfileFormSubmit = function() {


          //alert(1);
            $scope.vendor.city = $("input[name='locality']").val();
            $scope.vendor.state = $("input[name='administrative_area_level_1_short']").val();
            $scope.vendor.postal_code = $('#zip_code_vendors_myprofile').val();
            $scope.vendor.business_name = $("input[name='business_name']").val();

            var default_category = $scope.vendor.default_category = $("input[name='default_category']").val();
            var serviceCategories = $scope.vendor.serviceCategories = $("input[name='selectedCategories']").val();


            // if(default_category==="")  {

            //    default_category = $scope.vendor.vendor_main_cat = $("input[name='vendor_main_cat']").val();            

            // }else{

            //    default_category = $scope.vendor.default_category = $("input[name='default_category']").val();               

            // }

            // if(serviceCategories==="")  {
              
            //    serviceCategories = $scope.vendor.vendor_add_cat = $("input[name='vendor_add_cat']").val();

            // }else{
               
            //    serviceCategories = $scope.vendor.serviceCategories = $("input[name='selectedCategories']").val();
            // }

            // var selectedarea = $("input[name='service_area_before']").val();

            // if(selectedarea==="")  {

            // selectedarea = $("input[name='service_area_before']").val();

            // }else{

            //    selectedarea =  $("input[name='selectedarea']").val();


            // }

            var selectedarea = $scope.vendor.selectedarea = $("input[name='selectedarea']").val();

             var selectedamount = $scope.vendor.selectedamount = $("input[name='selectedamount']").val();

            //alert(selectedarea);
            
          var zipcode_length_vendor = $('#zip_code_vendors_myprofile').val();
          if (zipcode_length_vendor.length != 5) {
          jQuery('.minzipvendor').css('display','');
          return false;
          }else{
          //return true;
          }
            
            //$scope.vendor.serviceCategories = [];
        var formData = {

          default_category: default_category,
          serviceCategories: serviceCategories,
          business_name: $scope.vendor.business_name = $("input[name='business_name']").val(),
          postal_code:   $scope.vendor.postal_code = $('#zip_code_vendors_myprofile').val(),
          phone_number:  $scope.vendor.phone_number = $("input[name='phone_number']").val(),
          mobile_number: $scope.vendor.mobile_number = $("input[name='mobile_number']").val(),
          website_url:   $scope.vendor.website_url = $("input[name='website_url']").val(),
          facebook_url:  $scope.vendor.facebook_url = $("input[name='facebook_url']").val(),
          twitter_url:   $scope.vendor.twitter_url = $("input[name='twitter_url']").val(),
          locality:      $scope.vendor.city = $("input[name='locality']").val(),
          administrative_area_level_1:  $scope.vendor.state = $("input[name='administrative_area_level_1_short']").val(),
          lat:  $scope.vendor.lat = $("input[name='lat']").val(),
          lng:  $scope.vendor.lng = $("input[name='lng']").val(),
          minimum_budget:  selectedamount,
          service_area:  selectedarea

        };


            $http({

            url: "vendors/myprofile/edit",
            data: formData,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

            }).success(function(response){


                 var alert = $mdDialog.alert({
                    title: response.message.title,
                    content: response.message.body,
                    ok: 'OKAY'
                });
                $mdDialog
                    .show(alert)
                    .finally(function() {
                         $window.location = "/vendors/myprofile?id=0";
                    });

            //console.log("OK", response);
            // NotificationService.serverNotificationRedirectSamePage(response.message.title,response.message.body);

            });



            // var saveVendorProfile = VendorsProfileService.editProfile();

            // saveVendorProfile.success(function(response) {

            //     NotificationService.serverAlert(response.message.title, response.message.body);

            // }).error(function(response) {

            //     //  _.each(response, function(errors, key) {
            //     //      _.each(errors, function(e) {
            //     //        $scope.registrationForm[key].$dirty = true;
            //     //        $scope.registrationForm[key].$setValidity(key, false);
            //     //        $scope.errorMessages[key] = e;
            //     //      });
            //     //  });


            // });

        };

        $scope.notificationsSubmit = function(){

        var testval = [];
        $('.notifications_class:checked').each(function() {
            testval.push($(this).val());
        });

           //var accountinfo = VendorsProfileService.notificationsPreferences();
      var formData = {
            notifications_selected:  testval,
            // text_notifications:  $scope.vendor_details.text_notifications = $("input[name='notifications']").val(),
            //text_and_email_notifications:  $scope.vendor_details.text_and_email_notifications = $("input[name='notifications']").val(),
          };



           $http({
            url: "vendors/myprofile/notifications",
            data: formData,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
            }).success(function(response){

                var alert = $mdDialog.alert({
                    title: response.message.title,
                    content: response.message.body,
                    ok: 'OKAY'
                });
                $mdDialog
                    .show(alert)
                    .finally(function() {
                         $window.location = "/vendors/myprofile?id=3";
                    });
            //console.log("OK", response);
             //NotificationService.serverNotificationRedirectSamePage(response.message.title,response.message.body);

            });


           // accountinfo.success(function(response){
           //NotificationService.serverAlert(response.message.title,response.message.body);
           // }).error(function(response) {
           //      _.each(response, function(errors, key) {
           //          _.each(errors, function(e) {
           //            $scope.accountinfoForm[key].$dirty = true;
           //            $scope.accountinfoForm[key].$setValidity(key, false);
           //            $scope.errorMessages[key] = e;
           //          });
           //      });
           //      vcRecaptchaService.reload($scope.widgetId);
           //      //$scope.captchaStatus = false;
           // });

        };

    });

angular.module('app.controllers').controller('ProfileShowimagesController', function($http, $scope, NotificationService, $window, $mdDialog, $state, VendorsProfileService) {

        $scope.category_id = [];

        $scope.menus = [];

          var menus  = VendorsProfileService.getAllServiceImages();
console.log(menus);
          menus.success(function(response) {

             _.each(response.image_lists, function(image_name,service_category_id) {
                  var item = {'image_name':image_name,'service_category_id':service_category_id};
                  //$scope.menus.push(item);
                  $scope.menus.push(item);
               });

             // _.each(response.image_service_id, function(service_category_id) {
             //      var item_id = {'service_category_id':service_category_id};
             //      $scope.category_id.push(item_id);
             //      //console.dir($scope.menus.push(item));
             //   });

          }).error(function(response) {

          //NotificationService.serverNotification('Oops!','There was some server error.');

          });

          $scope.remove = function(idx,index) {

            //$scope.menus.image_name.images.splice(index, 1);

            var confirm = $mdDialog.confirm()
            .parent(angular.element(document.body))
            .title('Attention')
            .content('Are you sure you want to delete the portfolio image?')
            .ariaLabel('Attention')
            .ok('OKAY')
            .cancel('Cancel')
            .hasBackdrop(false);

            $mdDialog.show(confirm).then(function() {
              var request = $http({
              method: "post",
              url: '/vendors/profile/images/deletephoto/'+ idx,
              data: {
              id: idx ,
              },
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
              });
              /* Successful HTTP post request or not */
              request.success(function (response) {
                if(response){
                  $scope.menus.splice(idx, 1);
                //  $window.location.reload();
                  // NotificationService.serverNotificationRedirectSamePage(response.message.title, response.message.body);

                    var alerts = $mdDialog.alert({
                    title: response.message.title,
                    content: response.message.body,
                    ok: 'OKAY'
                });
                $mdDialog
                    .show(alerts)
                    .finally(function() {
                         $window.location = "/vendors/myprofile?id=1";
                    });

                }
                else {
                  alert("Failed");
                }
              });

            }, function() {

             //$window.location.reload();

            });

        };


          VendorsProfileService.getAllServiceImagesCount()
              .success(function(response) {
                $scope.image_count = 12;
                //console.dir($scope.image_count);
              });


    });

})();



angular.module('app.controllers').filter('tel', function () {
    return function (tel) {
        if (!tel) { return ''; }

        var value = tel.toString().trim().replace(/^\+/, '');

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        var country, city, number;

        switch (value.length) {
            case 10: // +1PPP####### -> C (PPP) ###-####
                country = 1;
                city = value.slice(0, 3);
                number = value.slice(3);
                break;

            case 11: // +CPPP####### -> CCC (PP) ###-####
                country = value[0];
                city = value.slice(1, 4);
                number = value.slice(4);
                break;

            case 12: // +CCCPP####### -> CCC (PP) ###-####
                country = value.slice(0, 3);
                city = value.slice(3, 5);
                number = value.slice(5);
                break;

            default:
                return tel;
        }

        if (country == 1) {
            country = "";
        }

        number = number.slice(0, 3) + '-' + number.slice(3);

        return (country + " (" + city + ") " + number).trim();
    };
});

jQuery(document).ready(function(){


    $('body').on('click', '#AccountEditButton', function(){
    $('#showProfileAccount').hide();
    $('#updateProfile').show();
   });

 $('body').on('click', '#profileBusinessEditButton', function(){
    $('#showProfileBusiness').hide();
    $('#BusinessupdateProfile').show();
   });

 $('body').on('click', '#profileEditPhotosButton', function(){
    $('#showProfilePhotos').hide();
    $('#PhotosupdateProfile').show();
   });

 $('body').on('click', '#profileEditNotificationButton', function(){
    $('#showProfileNotification').hide();
    $('#NotificationupdateProfile').show();
   });

});



 /* 
      Format phonenumber as: (aaa) ppp-nnnnxeeeee
      or as close as possible if phonenumber length is not 10
      does not allow country code or extensions > 5 characters long
  */
  angular.module('app.controllers').filter('phoneNumber',
    function() {
      return function(number, allowExtension) {
        /* 
        @param {Number | String} number - Number that will be formatted as telephone number
        Returns formatted number: (###) ###-#### x #####
        if number.length < 4: ###
        else if number.length < 7: (###) ###
        removes country codes
        */
        if (!number) {
          return null;
        }

        number = String(number);
        number = number.replace(/[^0-9]+/g, '');

        // Will return formattedNumber. 
        // If phonenumber isn't longer than an area code, just show number
        var formattedNumber = number;

        // if the first character is '1', strip it out 
        var c = (number[0] == '1') ? '1 ' : '';
        //number = number[0] == '1' ? number.slice(1) : number;

        // (###) ###-#### as (areaCode) prefix-endxextension
        var areaCode = number.substring(0, 3);
        var prefix = number.substring(3, 6);
        var end = number.substring(6, 10);
        var extension = number.substring(10, 15);

        if (prefix) {
          //formattedNumber = (c + "(" + area + ") " + front);
          formattedNumber = (areaCode + "-" + prefix);
        }
        if (end) {
          formattedNumber += ("-" + end);
        }
        if (allowExtension && extension) {
          formattedNumber += ("x" + extension);
        }
        return formattedNumber === '' ? null : formattedNumber;
      };
    }
  );

angular.module('app.controllers').directive('numbersOnly', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attr, ngModelCtrl) {
            function fromUser(text) {
                if (text) {
                    var transformedInput = text.replace(/[^0-9]/g, '');

                    if (transformedInput !== text) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                }
                return undefined;
            }            
            ngModelCtrl.$parsers.push(fromUser);
        }
    };
});
