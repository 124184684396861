(function(){
    "use strict";

	angular.module('app.controllers').controller('SocialLoginController', function($rootScope,$scope,$auth, $state, $mdDialog,$window, AuthService, NotificationService) {

		    var userInfo;

        $scope.loginedfrom = {};

         $scope.authenticate = function(provider) {
         $auth.authenticate(provider).then(function(response) {

            if (response.data.success === false) {
                    NotificationService.serverAlert(
                        response.data.message.title,response.data.message.body
                    );
                    return false;
                }

      			var user = $auth.getPayload().user;
            var roles = user.hasOwnProperty("roles");
            if(roles){
                    //Check role to login

                  if(user.roles.length === 0 || user.roles[0].id ===undefined || user.roles[0].id !=1 || user.roles[0].id!=2 || user.roles[0].id!=3){
                
                     $window.location.reload();

                   }
      			 }else{

              $mdDialog.show({
                   locals: { Socialuser: $auth.getPayload().user },
                     controller: SocialRegistrationController,
                     templateUrl: '/views/social-registration-dialog/social-registration-dialog.html',
                     parent: angular.element(document.body),
                     clickOutsideToClose:false,
              });

      			}
          });
        };


    });

    function SocialRegistrationController($scope,Socialuser) {

         $scope.user = Socialuser;
    }

  angular.module('app.controllers').controller('SocialController', function($scope,vcRecaptchaService,AuthService,NotificationService, $mdDialog) {

      $scope.cancel = function() {
        $mdDialog.cancel();
      };
      AuthService.socialProvider().success(function(response) {

       $scope.loginedfrom = response;

      });

        $scope.errorMessages = {};
    		$scope.registrationForm={};

        // $scope.response = null;
        // $scope.widgetId = null;

        // $scope.captchaStatus = true;

        // $scope.setResponse = function(response) {
        //     $scope.user.grecaptcharesponse = response;
        // };

        // $scope.setWidgetId = function (widgetId) {
        //     $scope.widgetId = widgetId;
        // };

        // $scope.checkReCaptcha = function(){

        //   if ( vcRecaptchaService.getResponse() === null ) {
        //       $scope.captchaStatus = false;
        //       $scope.registrationForm.$valid = false;
        //       return false;
        //   }

        //       $scope.captchaStatus = true;
        //       $scope.registrationForm.$valid = true;
        //       return true;

        // };
        
        $scope.socialRegistration = function(){

        	$scope.socialUser={user_id:$scope.user.id,role_id:$scope.user.role_id,first_name:$scope.user.first_name,last_name:$scope.user.last_name};
        	//grecaptcharesponse:$scope.user.grecaptcharesponse


           var auth = AuthService.socialRegistration($scope.socialUser);

           auth.success(function(response){

                NotificationService.serverAlert(response.message.title,response.message.body);

           }).error(function(response) {

                _.each(response, function(errors, key) {
                    _.each(errors, function(e) {
                      $scope.registrationForm[key].$dirty = true;
                      $scope.registrationForm[key].$setValidity(key, false);
                      $scope.errorMessages[key] = e;

                    });
                });

                //vcRecaptchaService.reload($scope.widgetId);
                //$scope.captchaStatus = false;

           });

        };
    });

})();
