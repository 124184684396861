(function() {
  "use strict";
  angular.module('app.controllers').controller('ActivateAccountController', function($scope, $state, $http, AuthService, NotificationService, $stateParams, $window) {

    var pathArray = location.href.split('/');
    var protocol = pathArray[5];
    // var host = pathArray[2];
    // var url = protocol + '//' + host;

    var url = '/users/activatestatus/' + protocol;
    console.log(url);

    $http({

      url: url,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }

    }).success(function(response) {

      $scope.contentdata = response;
      var contentdata = $scope.contentdata;
      console.log($scope.contentdata);


    }).error(function(response) {



    });






  });

})();
