(function(){
	"use strict";

angular.module('app.controllers').controller('BridesdashboardServiceController', function( $rootScope,$scope,$stateParams, BridesDashboardService,BridesProfileService,$window ){

    BridesDashboardService.getdashboardevent($stateParams.id).success(function(response) 
    {

        $scope.bridesdashboardserviceValues  = response;
        $scope.eventid = $stateParams.id;
            if(!$scope.bridesdashboardserviceValues.logged_user)
                window.location.reload();

                setTimeout(function(){
                jQuery('.DashBoardService').css('display','');
                jQuery('.BreadCrumbSecService').css('display','');

            }, 3000);
        //							jQuery('.ProfileTabHeader').css('display','');
        jQuery('.dashBtt').css('display','');

    });



    BridesProfileService.getcurrentdates().success(function(response) {

        $scope.currentdate = response;

    });


    $scope.deleterecord = function(data,cat,events_ids) 
    {
        var val = data;
        var vals = cat;
        var events_id = events_ids;
        var csrfToken = document.getElementById('csrf-token').value;
        if (confirm("Are you sure want to delete?")) {
            $.ajax({
            type: "POST",
            url: "brides/services/requests/updateposts",
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
            beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
            },
            data: { services_id : val,cat_id : vals ,eid: events_id},
            success:function(campaigns)
            {

//alert("sasi");
//window.location.reload(true);
     //      location.reload();
$window.location="/brides/bridesservices/" + $stateParams.id;
            }
            });
        }

        return false;

    }; 

    $scope.showDetails = function(data) 
    {

        var val = data;
        var csrfToken = document.getElementById('csrf-token').value;
            $.ajax({
            type: "POST",
            url: "brides/services/requests/updatepost",
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
            beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
            },
            data: { services_id : val},
            success:function(campaigns)
            {
            // $("#campaign").html(campaigns);
            }
            });
    }; 
});

})();
