(function() {
//Data
var cities = [
    {
        city : 'Toronto',
        desc : 'This is the best city in the world!',
        lat : 43.7000,
        long : -79.4000
    },
    {
        city : 'New York',
        desc : 'This city is aiiiiite!',
        lat : 40.6700,
        long : -73.9400
    },
    {
        city : 'Chicago',
        desc : 'This is the second best city in the world!',
        lat : 41.8819,
        long : -87.6278
    },
    {
        city : 'Los Angeles',
        desc : 'This city is live!',
        lat : 34.0500,
        long : -118.2500
    },
    {
        city : 'Las Vegas',
        desc : 'Sin City...\'nuff said!',
        lat : 36.0800,
        long : -115.1522
    }
];

//Angular App Module and Controller
angular.module('app.controllers').controller('MapCtrl', function ($scope,$http) {

      //var selected_service_id = $scope.Categories;
            $scope.selectedCategories ='';

               var formData = {

              selectedCategories:  $scope.selectedCategories,

            };

            $http({

              url: "vendors/activebidsmap",
              data: formData,
              method: 'POST',


            }).success(function(response){
 $scope.findVendors1 = response;                

});
             $http({

              url: "vendors/servicerequestsmap",
              data: formData,
              method: 'POST',


            }).success(function(response){
         
$scope.findVendors = response;

          $scope.city1=cities;
          $scope.serviceRequestsCount = $scope.findVendors.length;
           //alert($scope.findVendors[0].latitude);
          

          
           

      var mapOptions = {
        zoom: 18,
        center: new google.maps.LatLng($scope.findVendors[0].latitude, $scope.findVendors[0].longitude),
        mapTypeId: google.maps.MapTypeId.TERRAIN
    };


    $scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);

    $scope.markers = [];
    
    var infoWindow = new google.maps.InfoWindow();
    
    var createMarker = function (info){
       
        var marker = new google.maps.Marker({
            
        
            map: $scope.map,
            position: new google.maps.LatLng(info.latitude, info.longitude),
            title: info.type,
            
            //icon: "images/icon-map_red.png"
            icon: "images/map_marker_icon.png"

        });
    
    
    /*  marker = new google.maps.Marker({
            map: $scope.map,
            position: new google.maps.LatLng(info.latitude, info.longitude),
            title: info.type,
            
            icon: "images/icon-map_red.png"
            //icon: "images/map_marker_icon.png"

        });   
   */
        marker.content = '<div class="infoWindowContent">' + info.name + '</div>';
        
        google.maps.event.addListener(marker, 'click', function(){
            infoWindow.setContent('<h2>' + marker.title + '</h2>' + marker.content);
            infoWindow.open($scope.map, marker);
        });
        
         //var color = "#FE7569";
    

        $scope.markers.push(marker);
        
    };

    var createMarker1 = function (info){
    
        var marker1 = new google.maps.Marker({
            
        
            map: $scope.map,
            position: new google.maps.LatLng(info.latitude, info.longitude),
            title: info.type,
            
           // icon: "images/icon-map_red.png"
            icon: "images/map_blue.png"

        });
    
    
    /*  marker = new google.maps.Marker({
            map: $scope.map,
            position: new google.maps.LatLng(info.latitude, info.longitude),
            title: info.type,
            
            icon: "images/icon-map_red.png"
            //icon: "images/map_marker_icon.png"

        });   
   */
        marker1.content = '<div class="infoWindowContent">' + info.name + '</div>';
        
        google.maps.event.addListener(marker1, 'click', function(){
            infoWindow.setContent('<h2>' + marker1.title + '</h2>' + marker1.content);
            infoWindow.open($scope.map, marker1);
        });
        
         //var color = "#FE7569";
    

        $scope.markers.push(marker1);
        
    };  

    var createMarker2 = function (info){
    
        var marker2 = new google.maps.Marker({
            
        
            map: $scope.map,
            position: new google.maps.LatLng(info.latitude, info.longitude),
            title: info.type,
            
            icon: "images/icon-map_red.png"
            //icon: "images/map_blue.png"

        });
    
    
    /*  marker = new google.maps.Marker({
            map: $scope.map,
            position: new google.maps.LatLng(info.latitude, info.longitude),
            title: info.type,
            
            icon: "images/icon-map_red.png"
            //icon: "images/map_marker_icon.png"

        });   
   */
        marker2.content = '<div class="infoWindowContent">' + info.name + '</div>';
        
        google.maps.event.addListener(marker2, 'click', function(){
            infoWindow.setContent('<h2>' + marker2.title + '</h2>' + marker2.content);
            infoWindow.open($scope.map, marker2);
        });
        
         //var color = "#FE7569";
    

        $scope.markers.push(marker2);
        
    };  
    
    
    for (i = 1; i < $scope.findVendors.length; i++){
        createMarker($scope.findVendors[i]);
    }
    createMarker1($scope.findVendors[0]);
    for (i = 1; i < $scope.findVendors1.length; i++){
        createMarker2($scope.findVendors1[i]);
    }
    $scope.openInfoWindow = function(e, selectedMarker){
        e.preventDefault();
        google.maps.event.trigger(selectedMarker, 'click');
    };


             //  location.href = 'pages/findvendorsresults';

       });    



      
    
    



});
/*
(function() {
    "use strict";




   
  /*

// 1. Google Map // 
var cities = [
    {
        city : 'Location 1',
        desc : 'Test',
        lat : 52.238983,
        long : -0.888509 
    },
    {
        city : 'Location 2',
        desc : 'Test',
        lat : 52.238168,
        long : -52.238168
    },
    {
        city : 'Location 3',
        desc : 'Test',
        lat : 52.242452,
        long : -0.889882 
    },
    {
        city : 'Location 4',
        desc : 'Test',
        lat : 52.247234,
        long : -0.893567 
    },
    {
        city : 'Location 5',
        desc : 'Test',
        lat : 52.241874,
        long : -0.883568 
    }
];

angular.module('app.controllers').directive('myMap', function() {
    // directive link function
    var link = function(scope, element, attrs) {

        var map, infoWindow;
        var markers = [];
        
        // map config
        var mapOptions = {
            center: new google.maps.LatLng(50, 2),
            zoom: 4,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false
        };
        
        // init the map
        function initMap() {
            alert('1');
            if (map === void 0) {
                map = new google.maps.Map(element[0], mapOptions);
            }
        }    
        
        // place a marker
        function setMarker(map, position, title, content) {
            var marker;
            var markerOptions = {
                position: position,
                map: map,
                title: title,
                icon: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
            };

            marker = new google.maps.Marker(markerOptions);
            markers.push(marker); // add marker to array
            
            google.maps.event.addListener(marker, 'click', function () {
                // close window if not undefined
                if (infoWindow !== void 0) {
                    infoWindow.close();
                }
                // create new window
                var infoWindowOptions = {
                    content: content
                };
                infoWindow = new google.maps.InfoWindow(infoWindowOptions);
                infoWindow.open(map, marker);
            });
        }
        
        // show the map and place some markers
        initMap();
        
        setMarker(map, new google.maps.LatLng(10.508515, -0.125487), 'London', 'Just some content');
        setMarker(map, new google.maps.LatLng(52.370216, 4.895168), 'Amsterdam', 'More content');
        setMarker(map, new google.maps.LatLng(48.856614, 2.352222), 'Paris', 'Text here');
    };
    
    return {
        restrict: 'A',
        template: '<div id="gmaps"></div>',
        replace: true,
        link: link
    };
});
/*

angular.module('app.controllers')
.controller('ServiceRequestssMapsController', function ($scope) {
    
    var mapOptions = {
        zoom: 4,
        center: new google.maps.LatLng(40.0000, -98.0000),
        mapTypeId: google.maps.MapTypeId.TERRAIN
    };

    $scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);

    $scope.markers = [];
    
    var infoWindow = new google.maps.InfoWindow();
    
    var createMarker = function (info){
        
        var marker = new google.maps.Marker({
            map: $scope.map,
            position: new google.maps.LatLng(info.lat, info.long),
            title: info.city
        });
        marker.content = '<div class="infoWindowContent">' + info.desc + '</div>';
        
        google.maps.event.addListener(marker, 'click', function(){
            infoWindow.setContent('<h2>' + marker.title + '</h2>' + marker.content);
            infoWindow.open($scope.map, marker);
        });
        
        $scope.markers.push(marker);
        
    }  
    
    for (i = 0; i < cities.length; i++){
        createMarker(cities[i]);
    }

    $scope.openInfoWindow = function(e, selectedMarker){
        e.preventDefault();
        google.maps.event.trigger(selectedMarker, 'click');
    }

});
/*
 angular.module('app.controllers').controller('ServiceRequestsMapsController', function($scope) {


    // Map Settings //
    $scope.initialise = function() {
        var myLatlng = new google.maps.LatLng(37.3000, -120.4833);
        var mapOptions = {
            center: myLatlng,
            zoom: 16,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map = new google.maps.Map(document.getElementById("map"), mapOptions);
      // Geo Location /
        navigator.geolocation.getCurrentPosition(function(pos) {
            map.setCenter(new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude));
            var myLocation = new google.maps.Marker({
                position: new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude),
                map: map,
                animation: google.maps.Animation.DROP,
                title: "My Location"
            });
        });
        $scope.map = map;
        // Additional Markers //
        $scope.markers = [];
        var infoWindow = new google.maps.InfoWindow();
        var createMarker = function (info){
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(info.lat, info.long),
                map: $scope.map,
                animation: google.maps.Animation.DROP,
                title: info.city
            });
            marker.content = '<div class="infoWindowContent">' + info.desc + '</div>';
            google.maps.event.addListener(marker, 'click', function(){
                infoWindow.setContent('<h2>' + marker.title + '</h2>' + marker.content);
                infoWindow.open($scope.map, marker);
            });
            $scope.markers.push(marker);
        }  ;
        for (i = 0; i < cities.length; i++){
            createMarker(cities[i]);
        }

    };
    google.maps.event.addDomListener(document.getElementById("map"), 'load', $scope.initialise());

});


*/
})();

