(function(){
    "use strict";

    angular.module('app.controllers').controller('ResetPasswordController', function( $scope,$state,AuthService,NotificationService,$stateParams,$window ){

          $scope.errorMessages = {};

          $scope.user = {};

          $scope.user.token = $stateParams.token;

          $scope.processResetPassword = function(){

           var reset = AuthService.resetPassword($scope.user);

           reset.success(function(response){

                NotificationService.serverAlert(
                    response.message.title,response.message.body
                );


           }).error(function(response) {

                _.each(response, function(errors, key) {
                    _.each(errors, function(e) {
                      $scope.resetPassword[key].$dirty = true;
                      $scope.resetPassword[key].$setValidity(key, false);
                      $scope.errorMessages[key] = e;
                    });
                });

           });

        };

    });

})();
