(function() {
    "use strict";

    angular.module('app.controllers').controller('BridesvideoRequestController', function($scope, $state, VendorsProfileService, BrideRequestService,BridesProfileService, NotificationService, $mdDialog) {

        $scope.ServiceCategorieDetails.array='';
        $scope.ServiceCategorieDetails.arrays='';
        $scope.ServiceCategorieDetails.type_video_id='';
        $scope.ServiceCategorieDetails.additional_film_id='';
        $scope.ServiceCategorieDetails.additional_film_id1='';
        
 $scope.serviceRequest = {};

if($scope.ServiceCategorieDetails.service_request_id.length !== 0)
{

 BridesProfileService.getServiceRequestDetails($scope.ServiceCategorieDetails.service_request_id)
            .success(function(response) {
                $scope.serviceRequest = response;
            });
}

else
{
  $scope.serviceRequest.biddings =[];

}



        //alert($scope.ServiceCategorieDetails.type_function);
        if($scope.ServiceCategorieDetails.type_function===undefined)
        {
         //$scope.ServiceCategorieDetails.type_function=''; 
         $scope.ServiceCategorieDetails.array =[];
         if($scope.ServiceCategorieDetails.array.length===0)
                {
                 $scope.ServiceCategorieDetails.arraylength=''; 
                }
                else
                {
                 $scope.ServiceCategorieDetails.arraylength='1';  
                }

        }
        else if($scope.ServiceCategorieDetails.type_function==='')
        {
         $scope.ServiceCategorieDetails.array =[]; 
         if($scope.ServiceCategorieDetails.array.length===0)
                {
                 $scope.ServiceCategorieDetails.arraylength=''; 
                }
                else
                {
                 $scope.ServiceCategorieDetails.arraylength='1';  
                }
        }
        else
        {

          
              //  $scope.ServiceCategorieDetails.arraylength='22';
          //var result = ;
         //  $scope.array=[];
         // $scope.array.push($scope.ServiceCategorieDetails.type_function);   

         //var string = '1,2';
    var split_type_function   = $scope.ServiceCategorieDetails.type_function.split(',');
    $scope.ServiceCategorieDetails.array  = [];

for(var i=0;i<split_type_function.length;i++)
{
$scope.ServiceCategorieDetails.array.push(parseInt(split_type_function[i]));
}
if($scope.ServiceCategorieDetails.array.length===0)
                {
                 $scope.ServiceCategorieDetails.arraylength=''; 
                }
                else
                {
                 $scope.ServiceCategorieDetails.arraylength='1';  
                }

     //$scope.array.push($scope.ServiceCategorieDetails.type_function);

          //$scope.array =[$scope.ServiceCategorieDetails.type_function.replace (/"/g,'')];
          //alert($scope.array);
          //var string = JSON.stringify($scope.ServiceCategorieDetails.type_function);
          
          //$scope.array.push(string.replace (/"/g,''));

        } 
        $scope.ServiceCategorieDetails.array = angular.copy($scope.ServiceCategorieDetails.array);



if($scope.ServiceCategorieDetails.videography_type_location===undefined)
{
$scope.ServiceCategorieDetails.arrays =[];
if($scope.ServiceCategorieDetails.arrays.length===0)
  {
   $scope.ServiceCategorieDetails.videography_type_location=''; 
  }
  else
  {
   $scope.ServiceCategorieDetails.arrayslength='1';  
  }

}
else if($scope.ServiceCategorieDetails.videography_type_location==='')
{
$scope.ServiceCategorieDetails.arrays =[]; 
if($scope.ServiceCategorieDetails.arrays.length===0)
  {
   $scope.ServiceCategorieDetails.arrayslength=''; 
  }
  else
  {
   $scope.ServiceCategorieDetails.arrayslength='1';  
  }
}
else
{

var split_typelocation   = $scope.ServiceCategorieDetails.videography_type_location.split(',');
$scope.ServiceCategorieDetails.arrays  = [];

for(var u=0;u<split_typelocation.length;u++)
{
$scope.ServiceCategorieDetails.arrays.push(parseInt(split_typelocation[u]));
}
if($scope.ServiceCategorieDetails.arrays.length===0)
  {
   $scope.ServiceCategorieDetails.arrayslength=''; 
  }
  else
  {
   $scope.ServiceCategorieDetails.arrayslength='1';  
  }

} 
$scope.ServiceCategorieDetails.arrays = angular.copy($scope.ServiceCategorieDetails.arrays);




        if($scope.ServiceCategorieDetails.type_video===undefined)
        {
         //$scope.ServiceCategorieDetails.type_function=''; 
        $scope.videoarray =[];
              if($scope.videoarray.length===0)
                {
               
                 $scope.ServiceCategorieDetails.videoarraylength=''; 
                }
                else
                {
                 $scope.ServiceCategorieDetails.videoarraylength='1';  
                }

        }
        else if($scope.ServiceCategorieDetails.type_video==='')
        {
         $scope.videoarray =[]; 
         if($scope.videoarray.length===0)
                {
                  
                 $scope.ServiceCategorieDetails.videoarraylength=''; 
                }
                else
                {
                 $scope.ServiceCategorieDetails.videoarraylength='1';  
                }
        }
        else
        {
          var split_video   = $scope.ServiceCategorieDetails.type_video.split(',');
    $scope.videoarray  = [];

for(var j=0;j<split_video.length;j++)
{
$scope.videoarray.push(parseInt(split_video[j]));
if(parseInt(split_video[j])===4)
{
$scope.ServiceCategorieDetails.type_video_id=4;
}
}

if($scope.videoarray.length===0)
                {
                  
                 $scope.ServiceCategorieDetails.videoarraylength=''; 
                }
                else
                {
                 $scope.ServiceCategorieDetails.videoarraylength='1';  
                }

        //   $scope.videoarray =[parseInt($scope.ServiceCategorieDetails.type_video)];
        } 

       
        $scope.videoarray = angular.copy($scope.videoarray);
         if($scope.ServiceCategorieDetails.additional_film===undefined)
        {
         //$scope.ServiceCategorieDetails.type_function=''; 
         $scope.additionalfilmarray =[];
         if($scope.additionalfilmarray.length===0)
                {
                 $scope.ServiceCategorieDetails.additionalfilmarraylength=''; 
                }
                else
                {
                 $scope.ServiceCategorieDetails.additionalfilmarraylength='1';  
                }

         

        }
        else if($scope.ServiceCategorieDetails.additional_film==='')
        {
         $scope.additionalfilmarray =[]; 
         if($scope.additionalfilmarray.length===0)
                {
                 $scope.ServiceCategorieDetails.additionalfilmarraylength=''; 
                }
                else
                {
                 $scope.ServiceCategorieDetails.additionalfilmarraylength='1';  
                }


        }
        else
        {

          var split_additionalfilm   = $scope.ServiceCategorieDetails.additional_film.split(',');
    $scope.additionalfilmarray  = [];

for(var k=0;k<split_additionalfilm.length;k++)
{
$scope.additionalfilmarray.push(parseInt(split_additionalfilm[k]));
if(parseInt(split_additionalfilm[k])===6)
{
$scope.ServiceCategorieDetails.additional_film_id=6;
}
if(parseInt(split_additionalfilm[k])===7)
{
$scope.ServiceCategorieDetails.additional_film_id1=7;
}



}

 if($scope.additionalfilmarray.length===0)
                {
                 $scope.ServiceCategorieDetails.additionalfilmarraylength=''; 
                }
                else
                {
                 $scope.ServiceCategorieDetails.additionalfilmarraylength='1';  
                }

         //$scope.additionalfilmarray =[parseInt($scope.ServiceCategorieDetails.additional_film)];
        } 

        
        $scope.additionalfilmarray = angular.copy($scope.additionalfilmarray);
        // $scope.list = [{
        //     "id": 1,
        //     "value": "apple",
        // }, {
        //     "id": 3,
        //     "value": "orange",
        // }, {
        //     "id": 5,
        //     "value": "pear"
        // }];


        $scope.allCategories = {};

        $scope.serviceCreate = {};

        $scope.event_details ={};

        var path = window.location.pathname;
        var str = path.split("/");

        var url5 =  str[5];
        var url6 =  str[6];

       BridesProfileService.geteventdetails(url6).success(function(response)
{
$scope.event_details = response;
// popeye - angular expression display issue

       jQuery(".VideoServiceForm").removeClass("temphide");

});
        VendorsProfileService.getAvaliableServicecategories().success(function(response) 
        {
            $scope.allCategories = response;
        });



        $scope.teethUR = [{pos:'0', id: 1,value:'1',name: 'Raw Footage',  checked: false },{pos:'1', id: 2,value:'1',name: 'Full feature Video (30 - 40 minutes)', checked: false }, {pos:'2', id: 3,value:'3',name:'Highlight Video (3 - 4 minutes)', checked: false },{pos:'3', id: 4,value:'4',name:'Other', checked: false }];

        $scope.additionalfilm = [{ pos:'1', id: 2,value:'2',name: 'Immediate day of editing to show during event', checked: false }, { pos:'2', id: 3,value:'3',name: 'DVD Copies', checked: false },{ pos:'3', id: 4,value:'4',name:'Music Overlay', checked: false },{ pos:'4', id: 5,value:'5',name: 'Photo from Video', checked: false },{ pos:'5', id: 6,value:'6',name: 'Other', checked: false },{ pos:'6', id: 7,value:'7',name: 'Other1', checked: false }];

             $scope.event_function = [{pos:'0', name: '1',id:1,value: 'Engagement', checked: false },
  { pos:'1', name: '2', id:2,value: 'Reception', checked: false }, { pos:'2', name: '3', id:3,value: 'Wedding', checked: false }];

 $scope.typeslocations = [{pos:'0', name: '1',id:1,value: 'Indoor', checked: false },
  { pos:'1', name: '2', id:2,value: 'Outdoor', checked: false }];

            $scope.upperRight21 = function ()
             {
              if($scope.ServiceCategorieDetails.type_function==="")
{
$scope.ServiceCategorieDetails.type_function='';
}
//alert();
              //$scope.ServiceCategorieDetails.type_function = "";
              for (var i = 0; i < $scope.event_function.length; i++) 
              {
                if ($scope.event_function[i].checked === true) 
                {
                if ($scope.ServiceCategorieDetails.type_function === "") 
                  {
                   $scope.ServiceCategorieDetails.type_function = $scope.event_function[i].name;
                  } 
                  else 
                  {
                   $scope.ServiceCategorieDetails.type_function = $scope.ServiceCategorieDetails.type_function + "," + $scope.event_function[i].name;
                  }
                  
                }
              }
            };
           

            $scope.changefunction=function(pos,id)
{
//alert($scope.ServiceCategorieDetails.type_function);
if($scope.ServiceCategorieDetails.type_function==="")
{
$scope.ServiceCategorieDetails.type_function='';
}
//$scope.ServiceCategorieDetails.type_function='';

 for (var i = 0; i < $scope.event_function.length; i++) 
            {

if(i==pos)
             { 
            if($scope.event_function[pos].checked===true)
            {
               // alert('1');
       $scope.event_function[pos].checked=true;
            }   
            else
            {
                alert($scope.event_function[i].checked);
            $scope.event_function[pos].checked=false;
            }
             }
             else
             {
             // alert($scope.ServiceCategorieDetails.user_id);
              if($scope.event_function[i].checked===undefined)
              {
                if($scope.ServiceCategorieDetails.user_id!=='')
                {
              $scope.event_function[i].checked=true;    
               }
               else
               {

                $scope.event_function[i].checked=false;
               }
              }
              else
              {
               if($scope.event_function[i].checked===true)
               {
             $scope.event_function[i].checked=true;
               }
               else
               {
                alert($scope.event_function[i].checked);
                $scope.event_function[i].checked=false;
            }
              }
              //$scope.shootat[i].checked=true;  
             }
 
             
               if ($scope.event_function[i].checked === true) 
            {
           // alert($scope.shootat[i].id);

            if ($scope.ServiceCategorieDetails.txtshootat === "") 
            {
             $scope.ServiceCategorieDetails.type_function = $scope.event_function[i].id;
            } 
            else 
            {
             $scope.ServiceCategorieDetails.type_function = $scope.ServiceCategorieDetails.type_function + "," + $scope.event_function[i].id;
            }
             }
             else
             {
             // alert($scope.event_function[i].id);
             }

           
           
            }
          };


$scope.videosrequestFormSubmit = function() {
       
         $scope.serviceCreate.typelocation = $("input[name='typelocation']").val();
        $scope.serviceCreate.service_category_id = $("input[name='selectedCategories']").val();     
       $scope.serviceCreate.videography_request_id = $("input[name='videography_request_id']").val();
       $scope.serviceCreate.videography_location = $("input[name='videography_location']").val();
       $scope.serviceCreate.videography_no_guests = $("input[name='videography_no_guests']").val();
       $scope.serviceCreate.maximum_budget = $("input[name='maximum_budget']").val();
       $scope.serviceCreate.ends_at = $("input[name='ends_at']").val();
       $scope.serviceCreate.type_function_selected_id = $("input[name='type_function_selected_id']").val();
       $scope.serviceCreate.additional_film_selected_id = $("input[name='additional_film_selected_id']").val();
       $scope.serviceCreate.budgets_selected_id = $("input[name='budgets_selected_id']").val();
        $scope.serviceCreate.txt_additional_film_other1 = $("input[name='txt_additional_film_other1']").val();
        $scope.serviceCreate.txt_additional_film_other2 = $("input[name='txt_additional_film_other2']").val();
        $scope.serviceCreate.other_type_video = $("input[name='other_type_video']").val();
        $scope.serviceCreate.txt_budget_other = $("input[name='txt_budget_other']").val();
        $scope.serviceCreate.typefunction = $("input[name='typefunction']").val();
        $scope.serviceCreate.videography_hours_engagement = $scope.ServiceCategorieDetails.videography_hours_engagement;

          $scope.serviceCreate.film_service = $scope.ServiceCategorieDetails.film_service;
       


        
       // $scope.serviceCreate.videography_type_location = $("input[name='typelocation']").val();
        
$scope.serviceCreate.maximum_budget = $("input[name='maximum_budget']").val();

var confirm = $mdDialog.confirm()
            .parent(angular.element(document.body))
            .title('Attention')
            .content('Please click OKAY to post this service request or click CANCEL to review it again.')
            .ariaLabel('Attention')
            .ok('Okay')
            .cancel('Cancel')
            .hasBackdrop(false);

            $mdDialog.show(confirm).then(function() {
        
        var videoRequestForm = BrideRequestService.saveRequest($scope.serviceCreate,url6);

              videoRequestForm.success(function(response) {

              // NotificationService.serverAlert(response.message.title, response.message.body);
              location.href = '/brides/requests/'+url6;

            }).error(function(response) {

                //  _.each(response, function(errors, key) {
                //      _.each(errors, function(e) {
                //        $scope.registrationForm[key].$dirty = true;
                //        $scope.registrationForm[key].$setValidity(key, false);
                //        $scope.errorMessages[key] = e;
                //      });
                //  });

            });


         }, function() {

             // $window.location.reload();

            });

        }; 

 $scope.getlocation = function (id) {
   $scope.ServiceCategorieDetails.videography_type_location={};          
            
      $scope.ServiceCategorieDetails.videography_type_location=id;

   
 };

  $scope.getshootengagement = function (id) {
    $scope.ServiceCategorieDetails.film_service={};          
      $scope.ServiceCategorieDetails.film_service=id;

   
 };

 $scope.gethoursengagement = function (id) {
   $scope.ServiceCategorieDetails.videography_hours_engagement={};          
            
      $scope.ServiceCategorieDetails.videography_hours_engagement=id;

   
 };



 


  

$scope.$watch('form.Program', function(mVal){
  if (angular.isUndefined($scope.form)) return;
  if(mVal === 'other'){
     $scope.form.OtherProgram = $scope.tmVar;
  } else {
    if($scope.form.OtherProgram !== null){
      $scope.tmVar = $scope.form.OtherProgram;
      $scope.form.OtherProgram = null;
   }
 }
});
 
  
    $scope.$watch('txt_additional_film_other1', function(mVal){
 
 
      $scope.additional_film_selected_id = "";
      $scope.additional_film_selected_id1 = "";
      $scope.additional_film_id="";
      $scope.additional_film_id1="";
for (var j = 0; j < $scope.additionalfilm.length; j++) 
              {
                if ($scope.additionalfilm[j].checked === true) 
                {
                    if($scope.additionalfilm[j].id===6)
                     {

                       $('#txt_additional_film_other1').prop('disabled', false);  
                        $scope.additional_film_id=$scope.additionalfilm[j].id;    
                     } 
                    
                    else if($scope.additionalfilm[j].id===7)
                     {
                       $('#txt_additional_film_other2').prop('disabled', false);  
                       $scope.additional_film_id1=$scope.additionalfilm[j].id;    
                     } 
                     else
                     {
                         $scope.additional_film_id='';
                         $scope.additional_film_id1='';
                         $('#txt_additional_film_other1').prop('disabled', true); 
                        $('#txt_additional_film_other2').prop('disabled', true);  
                     }  
                if ($scope.additional_film_selected_id === "") 
                  {
                    if($scope.additionalfilm[j].id===6)
                    {
                    
                     $scope.additional_film_selected_id = $scope.additionalfilm[j].value;
                     $scope.additional_film_selected_id1 = $scope.additionalfilm[j].id;
                    }
                   else if($scope.additionalfilm[j].id===7)
                    {
                     $scope.additional_film_selected_id = $scope.additionalfilm[j].value;
                     $scope.additional_film_selected_id1 = $scope.additionalfilm[j].id;
                    }
                    else
                    {
                   $scope.additional_film_selected_id = $scope.additionalfilm[j].value;
                   $scope.additional_film_selected_id1 = $scope.additionalfilm[j].id;
                    }
                  } 
                  else 
                  {
                    if($scope.additionalfilm[j].id===6)
                    {
                    
                     $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                    }
                    if($scope.additionalfilm[j].id===7)
                    {
                     $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                    }




                  
                   $scope.additional_film_selected_id1 = $scope.additional_film_selected_id1 + "," + $scope.additionalfilm[j].id;

                     if($scope.additional_film_selected_id1.match("6")) {
                      $scope.additional_film_id=6;
                    //   $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                      }
                     else if($scope.additional_film_selected_id1.match("7")) {
                      $scope.additional_film_id1=7;
                      // $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                      } 
                     
                        else {
                        $scope.additional_film_id='';
                        $scope.additional_film_id1='';
                         $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                  }
                   

                  
                  }
                }
              } 
});

  $scope.$watch('txt_additional_film_other2', function(mVal){
  
 
 $scope.additional_film_selected_id = "";
              $scope.additional_film_selected_id1 = "";
              $scope.additional_film_id="";
      $scope.additional_film_id1="";
  for (var j = 0; j < $scope.additionalfilm.length; j++) 
              {
                if ($scope.additionalfilm[j].checked === true) 
                {
                    if($scope.additionalfilm[j].id===6)
                     {

                       $('#txt_additional_film_other1').prop('disabled', false);  
                        $scope.additional_film_id=$scope.additionalfilm[j].id;    
                     } 
                    
                    else if($scope.additionalfilm[j].id===7)
                     {
                       $('#txt_additional_film_other2').prop('disabled', false);  
                       $scope.additional_film_id1=$scope.additionalfilm[j].id;    
                     } 
                     else
                     {
                         $scope.additional_film_id='';
                         $scope.additional_film_id1='';
                         $('#txt_additional_film_other1').prop('disabled', true); 
                        $('#txt_additional_film_other2').prop('disabled', true);  
                     }  
                if ($scope.additional_film_selected_id === "") 
                  {
                     if($scope.additionalfilm[j].id===6)
                    {
                    
                     $scope.additional_film_selected_id = $scope.additionalfilm[j].value;
                     $scope.additional_film_selected_id1 = $scope.additionalfilm[j].id;
                    }
                    else if($scope.additionalfilm[j].id===7)
                    {
                     $scope.additional_film_selected_id = $scope.additionalfilm[j].value;
                     $scope.additional_film_selected_id1 = $scope.additionalfilm[j].id;
                    }
                    else
                    {
                   $scope.additional_film_selected_id = $scope.additionalfilm[j].value;
                   $scope.additional_film_selected_id1 = $scope.additionalfilm[j].id;
                    }
                  } 
                  else 
                  {
                    
                  if($scope.additionalfilm[j].id===6)
                    {
                    
                     $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                    }
                    if($scope.additionalfilm[j].id===7)
                    {
                     $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                    }

                  
                   $scope.additional_film_selected_id1 = $scope.additional_film_selected_id1 + "," + $scope.additionalfilm[j].id;

                     if($scope.additional_film_selected_id1.match("6")) {
                      $scope.additional_film_id=6;
                      // $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                      }
                     else if($scope.additional_film_selected_id1.match("7")) {
                      $scope.additional_film_id1=7;
                     //  $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                      } 
                     
                        else {
                        $scope.additional_film_id='';
                        $scope.additional_film_id1='';
                         $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                  }
                   

                  
                  }
                }
              } 
});


        $scope.upperRight = function ()
             {
             
              $scope.type_function_selected_id = "";
              $scope.type_function_selected_ids = "";
             // $("input[name='type_function_selected_id']").val('');
              for (var i = 0; i < $scope.teethUR.length; i++) 
              {
                if ($scope.teethUR[i].checked === true) 
                {
                    //alert($scope.teethUR[i].id);
                    if($scope.teethUR[i].id===4)
                     {
                      // $('#other_type_video').prop('disabled', false); 
                       $scope.type_video_id=$scope.teethUR[i].id;               
                     } 
                     else
                     {
                       // $('#other_type_video').prop('disabled', true);
                         $scope.type_video_id='';  
                     }  
                if ($scope.type_function_selected_id === "") 
                  {
                     if($scope.teethUR[i].id===4)
                     {
                   $scope.type_function_selected_id = $scope.teethUR[i].value;
                   $scope.type_function_selected_ids = $scope.teethUR[i].id;
                   $scope.type_video_id=$scope.teethUR[i].id;  
                  }
                  else
                  {
                   $scope.type_function_selected_id = $scope.teethUR[i].value; 
                    $scope.type_video_id='';  
                  }
             
                  } 
                  else 
                  {
                    if($scope.teethUR[i].id===4)
                     {
                      $scope.type_function_selected_id = $scope.type_function_selected_id + "," + $scope.teethUR[i].value;
                      $scope.type_function_selected_ids = $scope.type_function_selected_ids + "," + $scope.teethUR[i].id;
                      $scope.type_video_id=$scope.teethUR[i].id;
                      }
                      else
                      {
                   $scope.type_function_selected_id = $scope.type_function_selected_id + "," + $scope.teethUR[i].value;
                   $scope.type_function_selected_ids = $scope.type_function_selected_ids + "," + $scope.teethUR[i].id;
                   $scope.type_video_id='';
                       }
                  }
                   
                }

                  else
                {
        //$('#other_type_video').prop('disabled', true); 

                }
                if($scope.type_function_selected_ids.match("4")) {
                    
                $scope.type_video_id=4;  
                   }
                   else
                   {
                    $scope.type_video_id='';  
                   }
                   

              }
            };

      $scope.additionalfilms1 = function ()
             {
                
                 
             $scope.additional_film_selected_id = "";
              $scope.additional_film_selected_id1 = "";
              $scope.additional_film_id="";
              $scope.additional_film_id1="";
              for (var j = 0; j < $scope.additionalfilm.length; j++) 
              {
                if ($scope.additionalfilm[j].checked === true) 
                {
                    if($scope.additionalfilm[j].id===6)
                     {

                       $('#txt_additional_film_other1').prop('disabled', false);  
                        $scope.additional_film_id=$scope.additionalfilm[j].id;    
                     } 
                    
                    else if($scope.additionalfilm[j].id===7)
                     {
                       $('#txt_additional_film_other2').prop('disabled', false);  
                       $scope.additional_film_id1=$scope.additionalfilm[j].id;    
                     } 
                     else
                     {
                         $scope.additional_film_id='';
                         $scope.additional_film_id1='';
                         $('#txt_additional_film_other1').prop('disabled', true); 
                        $('#txt_additional_film_other2').prop('disabled', true);  
                     }  
                if ($scope.additional_film_selected_id === "") 
                  {
                     if($scope.additionalfilm[j].id===6)
                    {
                    
                     $scope.additional_film_selected_id = $scope.additionalfilm[j].value;
                     $scope.additional_film_selected_id1 = $scope.additionalfilm[j].id;
                    }
                    else if($scope.additionalfilm[j].id===7)
                    {
                     $scope.additional_film_selected_id = $scope.additionalfilm[j].value;
                     $scope.additional_film_selected_id1 = $scope.additionalfilm[j].id;
                    }
                    else
                     { 
                   $scope.additional_film_selected_id = $scope.additionalfilm[j].value;
                   $scope.additional_film_selected_id1 = $scope.additionalfilm[j].id;
                 }
                  } 
                  else 
                  {
                    
                   if($scope.additionalfilm[j].id===6)
                    {
                    
                     $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                    }
                    if($scope.additionalfilm[j].id===7)
                    {
                     $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                    }
                  
                   $scope.additional_film_selected_id1 = $scope.additional_film_selected_id1 + "," + $scope.additionalfilm[j].id;

                     if($scope.additional_film_selected_id1.match("6")) {
                      $scope.additional_film_id=6;
                      // $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                      }
                     else if($scope.additional_film_selected_id1.match("7")) {
                      $scope.additional_film_id1=7;
                       //$scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                      } 
                     
                        else {
                        $scope.additional_film_id='';
                        $scope.additional_film_id1='';
                         $scope.additional_film_selected_id = $scope.additional_film_selected_id + "," + $scope.additionalfilm[j].value;
                  }
                   

                  
                  }
                }
              } 
            };


    });
angular.module('app.controllers').filter('dateformats', function ()
{
return function (item) {
var t = item.split(/[- :]/);
var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
var time = d.getTime();
return time;
};
});


angular.module('app.controllers').directive("checkboxGroup", function() {
        return {
            restrict: "A",
            link: function(scope, elem, attrs) {
                // Determine initial checked boxes
                if (scope.ServiceCategorieDetails.array.indexOf(scope.item.id) !== -1) {
                    elem[0].checked = true;
                    //scope.ServiceCategorieDetails.type_function=elem[0];
                }


                // Update array on click
                elem.bind('click', function() {
                    var index = scope.ServiceCategorieDetails.array.indexOf(scope.item.id);
                    // Add if checked
                    if (elem[0].checked) {
                        if (index === -1)
                        {

                         scope.ServiceCategorieDetails.array.push(scope.item.id);
                         //scope.ServiceCategorieDetails.type_function=scope.item.id;
                       }
                    }
                    // Remove if unchecked
                    else {
                        if (index !== -1) scope.ServiceCategorieDetails.array.splice(index, 1);
                    }
                      if(scope.ServiceCategorieDetails.array.length===0)
                {
                 scope.ServiceCategorieDetails.arraylength=''; 
                // alert(scope.ServiceCategorieDetails.array.length);
                }
                else
                {
                 scope.ServiceCategorieDetails.arraylength='1';  
                 //alert('1');
                }
                    // Sort and update DOM display
                    scope.$apply(scope.ServiceCategorieDetails.array.sort(function(a, b) {
                        return a - b;
                    }));
                });

              
              
                

                // if (scope.array.indexOf(scope.video.id) !== -1) {
                //     elem[0].checked = true;
                // }

                // // Update array on click
                // elem.bind('click', function() {
                //     var index = scope.videoarray.indexOf(scope.video.id);
                //     // Add if checked
                //     if (elem[0].checked) {
                //         if (index === -1) scope.videoarray.push(scope.video.id);
                //     }
                //     // Remove if unchecked
                //     else {
                //         if (index !== -1) scope.videoarray.splice(index, 1);
                //     }
                //     // Sort and update DOM display
                //     scope.$apply(scope.videoarray.sort(function(a, b) {
                //         return a - b;
                //     }));
                // });


            }

        };
    });



angular.module('app.controllers').directive("checkboxGroups", function() {
        return {
            restrict: "A",
            link: function(scope, elem, attrs) {
                // Determine initial checked boxes
                if (scope.ServiceCategorieDetails.arrays.indexOf(scope.items.id) !== -1) {
                    elem[0].checked = true;
                    //scope.ServiceCategorieDetails.type_function=elem[0];
                }


                // Update array on click
                elem.bind('click', function() {
                    var index = scope.ServiceCategorieDetails.arrays.indexOf(scope.items.id);
                    // Add if checked
                    if (elem[0].checked) {
                        if (index === -1)
                        {

                         scope.ServiceCategorieDetails.arrays.push(scope.items.id);
                         //scope.ServiceCategorieDetails.type_function=scope.item.id;
                       }
                    }
                    // Remove if unchecked
                    else {
                        if (index !== -1) scope.ServiceCategorieDetails.arrays.splice(index, 1);
                    }
                      if(scope.ServiceCategorieDetails.arrays.length===0)
                {
                 scope.ServiceCategorieDetails.arrayslength=''; 
                // alert(scope.ServiceCategorieDetails.array.length);
                }
                else
                {
                 scope.ServiceCategorieDetails.arrayslength='1';  
                 //alert('1');
                }
                    // Sort and update DOM display
                    scope.$apply(scope.ServiceCategorieDetails.arrays.sort(function(a, b) {
                        return a - b;
                    }));
                });

            }

        };
    });

angular.module('app.controllers').directive("checkboxGroupvideo", function() {
        return {
            restrict: "A",
            link: function(scope, elem, attrs) {
                // Determine initial checked boxes
                if (scope.videoarray.indexOf(scope.video.id) !== -1) {
                    elem[0].checked = true;
                }


                // Update array on click
                elem.bind('click', function() {
                    var index = scope.videoarray.indexOf(scope.video.id);
                    // Add if checked
                    if (elem[0].checked) {
                      
                      if(scope.video.id===4)
                      {
                      scope.ServiceCategorieDetails.type_video_id=4;
                      }

                        if (index === -1) scope.videoarray.push(scope.video.id);
                    }
                    // Remove if unchecked
                    else {
                      if(scope.video.id===4)
                      {
                      scope.ServiceCategorieDetails.type_video_id='';
                      }

                      //alert(scope.video.id);

                        if (index !== -1) scope.videoarray.splice(index, 1);
                    }


                      if(scope.videoarray.length===0)
                {
                 scope.ServiceCategorieDetails.videoarraylength=''; 
                }
                else
                {
                 scope.ServiceCategorieDetails.videoarraylength='1';  
                }
                    // Sort and update DOM display
                    scope.$apply(scope.videoarray.sort(function(a, b) {
                        return a - b;
                    }));
                });

               


            }
            
        };
    });
angular.module('app.controllers').directive("checkboxGroupadditionalfilm", function() {
        return {
            restrict: "A",
            link: function(scope, elem, attrs) {
                // Determine initial checked boxes
                if (scope.additionalfilmarray.indexOf(scope.additionalfilms.id) !== -1) {
                    elem[0].checked = true;
                }


                // Update array on click
                elem.bind('click', function() {
                    var index = scope.additionalfilmarray.indexOf(scope.additionalfilms.id);
                    // Add if checked
                    if (elem[0].checked) {
                      if(scope.additionalfilms.id===6)
{
scope.ServiceCategorieDetails.additional_film_id=6;
}
if(scope.additionalfilms.id===7)
{
scope.ServiceCategorieDetails.additional_film_id1=7;
}

                        if (index === -1) scope.additionalfilmarray.push(scope.additionalfilms.id);
                    }
                    // Remove if unchecked
                    else {
                                            if(scope.additionalfilms.id===6)
{
scope.ServiceCategorieDetails.additional_film_id='';
}
if(scope.additionalfilms.id===7)
{
scope.ServiceCategorieDetails.additional_film_id1='';
}

                        if (index !== -1) scope.additionalfilmarray.splice(index, 1);
                    }

                    if(scope.additionalfilmarray.length===0)
                {
                 scope.ServiceCategorieDetails.additionalfilmarraylength=''; 
                }
                else
                {
                 scope.ServiceCategorieDetails.additionalfilmarraylength='1';  
                }

                    // Sort and update DOM display
                    scope.$apply(scope.additionalfilmarray.sort(function(a, b) {
                        return a - b;
                    }));
                });

               


            }
            
        };
    });


})();

jQuery(document).ready(function(){
     $('body').on('click','#engagement_date', function () {
      $(this).datetimepicker({
              format: 'MM/DD/YYYY',
              minDate: new Date(),
              
              defaultDate: new Date(),
      });//maxDate: someDate,
    });

$('body').on('keyup','#number_only',function(e){
    var value = $(this).val();
      value = value.replace(/^(0*)/,"");
      $(this).val(value);
  if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
     this.value = this.value.replace(/[^0-9\.]/g, '');
  }
});

});

 jQuery(document).ready(function () {

    $('body').on('click', "#get_name", function(){
   
     if ($("#chkYes").is(":checked")) 
            {
            $('#dvPassport').show();
            }

            else
            {
            $("#dvPassport").hide();
            }   
   });
    });
