(function(){
    "use strict";

    angular.module('app.controllers').controller('FooterController', function( $scope, $state, CmsService, NotificationService,$http,$mdDialog,$document,$rootScope,AuthService ){

        $scope.menus = [];

  $scope.quickcontactsubmit=function(){
                    $scope.isDisabled = true;

             var formData = {
              quickcontact_name: $scope.quickcontact_name,
              quickemail: $scope.quickemail,
              quickcontact_number :  $("input[name='quickcontact_number']").val(),
             
            };
            //alert($scope.contact_name);
            //$('#contact_name').val();

             $http({

              url: "pages/quickcontact",
              data: formData,
              method: 'POST',
            }).success(function(response){
                  var alert = $mdDialog.alert({
                    title: 'Attention',
                    content: 'Thank you! Your message has been successfully sent. We will contact you very soon!',
                    ok: 'OKAY'
                });
                $mdDialog
                    .show(alert)
                    .finally(function() {
                         location.reload();
                    });
                             
             }).error(function(response){
              
                  _.each(response, function(errors, key) {
                    _.each(errors, function(e) {
                        $scope.errorMessages[key] = e;
                        $scope.quickcontactform[key].$dirty = true;
                        $scope.quickcontactform[key].$setValidity(key, false);
                    });
                });

            });


         //   data: { email : emailid,contact_name:contact_name,contact_message:contact_message,contact_usertype:contact_usertype,contact_number:contact_number},
          
        };

            var menus  = CmsService.getAllPageSulgs();

            menus.success(function(response) {

               _.each(response.pages, function(slug, name) {
                    var item = {'name':name,'slug':slug};
                    $scope.menus.push(item);
                    //console.dir($scope.menus.push(item));
                 });

            }).error(function(response) {

            //NotificationService.serverNotification('Oops!','There was some server error.');

            });

	AuthService.websitesession().success(function(response) {

                    $scope.sessionStatusValue  = response;  


                 });

                AuthService.loggedInStatus().success(function(response) {

                    $scope.loggedInStatusValue = response;  
                    



                  });

  		var d = new Date();
            var n = d.getTime();  //n in ms

            $rootScope.idleEndTime = n+(20*60*1000); //set end time to 20 min from now
	    var sessionValue = $("input[name='sessionStatus']").val();
            //$("#sessionStatus").val();
            var loginValue = $("input[name='loginInStatus']").val();
            //$("#loginInStatus").val();


            $rootScope.idleEndTimes = n+(20*60*1000); //set end time to 20 min from now
	
            $document.find('body').on('mousemove keydown DOMMouseScroll mousewheel mousedown touchstart', checkAndResetIdle); //monitor events

            function checkAndResetIdle() //user did something
            {


//            var loginValue = $("#loginInStatus").val();
  //          var sessionValue = $("#sessionStatus").val();
            // console.log(loginValue);
            var d = new Date();
            var n = d.getTime();  //n in ms


            if (n>$rootScope.idleEndTime)
            {

            $document.find('body').off('mousemove keydown DOMMouseScroll mousewheel mousedown touchstart'); //un-monitor events

            //$location.search('IntendedURL',$location.absUrl()).path('/login'); //terminate by sending to login page
            // document.location.href = '/logout';
            //            document.location.href = '/logout';
 	            if(loginValue!==0)
         	    {
			document.location.href = '/logout';
        	     //alert('Session ended due to inactivity');
	            }
        	    else
	            {
	            }
            }else{

	            $rootScope.idleEndTime = n+(20*60*1000); //reset end time

             }
            }

   });

    $('body').on('keyup','#zip_code_vendor',function(e){
       $('#zip_code_vendor').geocomplete({
          details: "#vendorsearch",
        }).bind("geocode:result", function(event, result){

          $('#vendorsearch input[name="locality"]').prop("disabled", true);
          $('#vendorsearch input[name="administrative_area_level_1"]').prop("disabled", true);

          if($('#vendorsearch input[name="locality"]').val().length === 0){
            $('#vendorsearch input[name="locality"]').prop("disabled", false);
          }

          if($('#vendorsearch input[name="administrative_area_level_1"]').val().length === 0){
            $('#vendorsearch input[name="administrative_area_level_1"]').prop("disabled", false);
          }
      });
    });

})();

