(function() {
    "use strict";
    
    angular.module('app.controllers').controller('VendorsImageUploadController', function($window,$scope, $state, VendorsProfileService, NotificationService, $stateParams, $mdDialog, $http, $timeout, $compile, $interval,Upload) {

        $scope.errorMessages = {};
        $scope.image_count_from_db = {};

        var image_count = VendorsProfileService.getAllServiceImagesCount();

        $scope.showAdvanced = function(ev,$serviceid) {
          $scope.sevice_id = $serviceid;
          $scope.obj = {service_id:$serviceid};
           var self = this;
            this.item = $serviceid;
            $mdDialog.show({
                controller: 'VendorsImageUploadController',
                templateUrl: '/views/image-upload/images-upload.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                scope: this.$new(),
                locals:{
                       parent: $scope
                        },
                bindToController: true,

            });
        };

        $scope.close = function() {

          $mdDialog.hide();
          $window.location.reload();

        };
        

        $scope.submit = function() {
 VendorsProfileService.getAllServiceImagesCount().success(function(response) 
           {

               $scope.image_count_from_db  = response;
               $scope.upload($scope.file,response);
             });

          

        };


        $scope.uploadThen = function (data, status, headers, config) {
if(data.data.success){
$scope.uploadMessageContent += data.data.fileName + " has been successfully uploaded.<br>";

}else{
$scope.uploadMessageContent += data.data.fileName + " upload failed."+" "+data.data.message.image+".<br>";
}

              ++$scope.fileUploaded;
            if($scope.fileSubmittedCount == $scope.fileUploaded){

 $mdDialog.show({
          controller: 'VendorsImagesUploadController',
          clickOutsideToClose: true,
          scope: $scope,
          preserveScope: true,
parent: angular.element(document.body),
                locals:{
                       parent: $scope
                        },
                bindToController: true,
          template: '<md-dialog aria-label="Attentions.jpeg has been ..." md-theme="black" class="md-black-theme transition-in" role="alertdialog" tabindex="-1" aria-describedby="dialog_40">'+'<md-dialog-content tabindex="-1" role="document" id="dialog_40">'+
'<h2 class="md-title ng-binding">Attention</h2><p class="ng-binding">'+$scope.uploadMessageContent+'</p></md-dialog-content><div class="md-actions"><button class="md-primary md-button md-black-theme" onClick="window.location.reload()" tabindex="0"><span class="ng-binding ng-scope">OKAY</span></button></div></md-dialog>',

       });

            }

        };







        $scope.upload = function (files,dbfilecount) {


          if ($('#onetimeloadimage').length > 0) {
            return false;
            }
            $('<input>').attr({
            type: 'hidden',
            id: 'onetimeloadimage',
            name: 'onetimeloadimage'
            }).appendTo('body');

$scope.uploadMessageContent = "";
          $scope.fileList = [];
          
          var service_id = $("input[name='sevice_id']").val();
          var fileSubmittedCount = files.length;
          $scope.fileSubmittedCount = fileSubmittedCount;
          $scope.fileUploaded = 0;
          var totalFilesAfterUpload = (+fileSubmittedCount) + (+dbfilecount);

           if (files && files.length  && ((totalFilesAfterUpload <= 12))) {
            for (var i=0; i < fileSubmittedCount; ++i) {

              var file = files[i];
              Upload.upload({
                url: '/vendors/profile/images/add/'+ service_id ,
                file: file
            }).then($scope.uploadThen);

            }
          
           }else{

var alerts1 = $mdDialog.alert({
                title: "Attention",
                content: "You have reached a maximum number of photos uploading limit.",
                ok: 'OKAY'
                });
                $mdDialog
                .show(alerts1)
                .finally(function() {
                $window.location.reload();
                }); 
            
           }
        };

        /*$scope.upload = function (files) {
          
          $scope.fileList = [];
          
          var service_id = $("input[name='sevice_id']").val();

           if (files && files.length){
          
           $('#submit').hide();
           $(".ImageUploadError").hide();
           var isFileCountExceeded = false;
          

               var image_count_from_db  = response;
                var count_img = $scope.image_count_from_db.image_count;
                $mdDialog.hide();

             var total_count = (+image_count_from_db) + (+files.length);

           if(total_count > 12)
           {
            isFileCountExceeded = true; 
             
                return;
           }

          if(!isFileCountExceeded)
          {
            for (var i=0; i < files.length; ++i) 
            {
            var file = files[i];
            Upload.upload({
            url: '/vendors/profile/images/add/'+ service_id ,
            file: file
            });

            }

               var alerts = $mdDialog.alert({
                title: "Attention",
                content: "Profile image(s) added Successfully.",
                ok: 'OKAY'
                });
                $mdDialog
                .show(alerts)
                .finally(function() {
               $window.location.reload();
                });   
          }

               

 
            } else{
          
               $(".ImageUploadError").html("Please Select image");
          
            }

        };*/

    });


      angular.module('app.controllers').controller('ShowimagesController', function($http, $scope, NotificationService, $mdDialog,$window, $state, VendorsProfileService) {

        $scope.menus = [];

          var menus  = VendorsProfileService.getAllServiceImages();

          menus.success(function(response) {

             _.each(response.image_lists, function(image_name,id,default_status) {
                  var item = {'image_name':image_name,'id':id};
                  $scope.menus.push(item);
                  //console.dir($scope.menus.push(item));
               });

          }).error(function(response) {

          //NotificationService.serverNotification('Oops!','There was some server error.');

          });

          $scope.remove = function(idx,index) {

             $scope.menus.splice(idx, 1);

             var confirm = $mdDialog.confirm()
            .parent(angular.element(document.body))
            .title('Attention')
            .content('Are you sure you want to delete the portfolio image?')
            .ariaLabel('Attention')
            .ok('OKAY')
            .cancel('Cancel')
            .hasBackdrop(false);


            $mdDialog.show(confirm).then(function() {
              var request = $http({
              method: "post",
              url: '/vendors/profile/images/deletephoto/'+ idx,
              data: {
              id: idx ,
              },
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
              });
              /* Successful HTTP post request or not */
              request.success(function (response) {
                if(response){
                  $scope.menus.splice(idx, 1);
                 //  $window.location.reload();
                  NotificationService.serverNotificationRedirectSamePage(response.message.title, response.message.body);
                }
                else {
                  alert("Failed");
                }
              });

            }, function() {

               //$window.location.reload();

            });





        };

          VendorsProfileService.getAllServiceImagesCount()
              .success(function(response) {
                $scope.image_count = 12;
                //console.dir($scope.image_count);
              });


    });
    angular.module('app.controllers').controller('ButtonactionController', function($http, $window, $scope, VendorsProfileService) {

      $scope.image_count_from_db = {};

      VendorsProfileService.getAllServiceImagesCount().success(function(response) {
              
                var image_count_from_db  = response;
                var count_img = $scope.image_count_from_db.image_count;
                $scope.image_count_from_db=response;
 });


      $scope.backbutton = function() {

        $window.location = '/vendors/profile/step_one';

      };

      $scope.completebutton = function() {

        $http({

            url: "vendors/profile/stepskiptwo",
           // data: formData,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

            }).success(function(response){

             $window.location = '/vendors/dashboard';

            }).error(function(response) {

                _.each(response, function(errors, key) {
                    _.each(errors, function(e) {
                      $scope.accountinfoForm[key].$dirty = true;
                      $scope.accountinfoForm[key].$setValidity(key, false);
                      $scope.errorMessages[key] = e;
                    });
                });

                vcRecaptchaService.reload($scope.widgetId);
                //$scope.captchaStatus = false;

           });

       

      };

    });


})();
