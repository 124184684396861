(function() {
    "use strict";

    angular.module('app.controllers').controller('PagesController', function($scope, $state,$mdDialog, $stateParams, CmsService, NotificationService, $window) {
 
            var slug = $stateParams.slug;

            var page = CmsService.getPage(slug);


            page.success(function(response) {
      // $window.alert(response);
                if(response.success === true){
                    // changePageDetails(response.page,response.uname,response.aboutus);
                     
                    $('#cmsPage').html(response.page[0].attributes.description);

		    if (typeof response.page[0] !== 'undefined') {
		    $("meta[name='keywords']").attr("content", response.page[0].attributes.seo_meta_keywords);
		    $("meta[name='title']").attr("content", response.page[0].attributes.seo_meta_title);
		    
		    $("meta[name='description']").attr("content", response.page[0].attributes.seo_meta_description);
 $(document).attr("title", "bridesgenie - "+response.page[0].attributes.seo_meta_title);
		    }

//     alert($("meta[name='description']").attr("content"));


                     if ($('#members_count').length)
                        {
                         $('#members_count').html(response.aboutus[0].memberscount);
                         $('#vendors_count').html(response.aboutus[0].vendorscount);
                         $('#events_count').html(response.aboutus[0].eventscount);
                     }
                if(typeof response.uname[0] !== 'undefined'){
                     $('#report_name').val(response.uname[0].name);
                      $('#contact_name').val(response.uname[0].name);

                       $('#email').val(response.uname[0].email);
		}

                      $('#report_name').attr('readonly', true);
                      $('#contact_name').attr('readonly', true);
                        $('#email').attr('readonly', true);

                         $('#contact_usertype')
                          .find('option')
                          .remove()
                          .end()
                          .append('<option value='+response.uname[0].role_name+'>'+response.uname[0].role_name+'</option>')
                          .val(response.uname[0].role_name);

                         var phone=response.uname[0].phone_number;
                          var formatted;
                         if(response.uname[0].phone_number!=="")
                         {
                         // formatted = phone.substr(0, 3) + '-' + phone.substr(3, 3) + '-' + phone.substr(6,4);
                         $('#report_number').val(phone);
                         $('#report_number').attr('readonly', true);
                         $('#contact_number').val(phone);
                         $('#contact_number').attr('readonly', true);
                         }
                         else
                         {
                           
                          $('#report_number').val(phone);
                        $('#report_number').removeAttribute("readonly",0);
                        $('#contact_number').val(phone);
                        $('#contact_number').removeAttribute("readonly",0);
                          
                         }
     
                }

            }).error(function(response) {

                NotificationService.serverNotification(
                    response.message.title,response.message.body
                );

            });
           
          $scope.contactCreateSubmit = function(){

            
            //$scope.event.mobile_number = $('#mobile_number').val();



            /*
            brideEventForm.success(function(response) {

                if (response.success === true) {
                    NotificationService.serverAlert(response.message.title, response.message.body);
                }


            }).error(function(response) {

                 _.each(response, function(errors, key) {
                      _.each(errors, function(e) {
                        $scope.errorMessages[key] = e;
                        $scope.contactCreateForm[key].$dirty = true;
                        $scope.contactCreateForm[key].$setValidity(key, false);
                      });
                  });

            });
*/
        };


          

        });



   


})();


$( document ).ready(function() {
  // Handler for .ready() called.
//	titleUpdater();
});

