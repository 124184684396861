(function() {
  "use strict";

  angular.module('app.controllers').controller('BridesAcceptedRequestsDetailController', function($scope, $state, $stateParams, $http, VendorsProfileService, BridesProfileService, NotificationService) {

    $scope.serviceRequest = {};

   
var path = window.location.pathname;
      var str = path.split("/");
      var url4 =  str[4];
      var url5 =  str[5];

       $scope.eventIds = url5;

  BridesProfileService.geteventnamedetail(url5).success(function(response)
            {
            $scope.eventName = response;
            });

    BridesProfileService.getacceptedRequestDetails(url4,url5)
      .success(function(response) {
        $scope.serviceRequest = response;
        $scope.serviceRequestCount = $scope.serviceRequest.length;
      });

    $scope.acceptBid = function(id) {
      BridesProfileService.acceptBid(id)
        .success(function(response) {
          NotificationService.serverAlert(
            response.message.title, response.message.body
          );
        });
    };
    BridesProfileService.getServiceRequestBestoffer($stateParams.id).success(function(response) {

      $scope.biddingList = response;
      $scope.bestOfferCount = $scope.biddingList.length;

    });

    BridesProfileService.getServiceRequestId($stateParams.id)
      .success(function(response) {
        $scope.serviceRequestId = response;
        jQuery('.datesBClass').css('display','');

      });

    BridesProfileService.getServiceRequestMiniumDetailsBride(url4)
      .success(function(response) {

        $scope.serviceRequest_minimum = response;
        $scope.minimumCount = $scope.serviceRequest_minimum.length;
        //console.log($scope.serviceRequest_minimum);

      });
    $scope.sort = function(keyname) {

      $scope.sortKey = keyname; //set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    };

    $scope.vendorBiddingnotesFormSubmit = function() {

      var formData = {

        service_id: $scope.service_id = $("input[name='service_id']").val(),
        bride_id: $scope.bride_id = $("input[name='bride_id']").val(),
        vendor_id: $scope.vendor_id = $("input[name='vendor_id']").val(),
        notes: $scope.notes = $scope.bidding.notes,

      };

      $http({

        url: "brides/sendanotes",
        data: formData,
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }

      }).success(function(response) {
        //console.log("OK", response);
        NotificationService.serverAlert(response.message.title, response.message.body);

      });

    };
  });
  angular.module('app.controllers').controller('AppCtrl', function($scope) {

    $scope.custom = true;
    $scope.toggleCustom = function() {
      $scope.custom = $scope.custom === false ? true : false;
    };
  });

  var item = '';
  angular.module('app.controllers').filter('format', function() {
    return function(item) {
      var t = item.split(/[- :]/);
      var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
      var time = d.getTime();
      return time;
    };
  });

  angular.module('app.controllers').filter('dateformat', function() {
    return function(item) {
      var t = item.split(/[- :]/);
      var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
      var time = d.getTime();
      return time;
    };
  });
})();
