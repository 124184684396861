(function() {
    "use strict";
    
    angular.module('app.controllers').controller('BridesRequestController', function($scope, $state,$http,$templateCache,BridesProfileService, VendorsProfileService, BrideRequestService, NotificationService,$stateParams,$location,$window) {
             
        $scope.allCategories = [{}];
        $scope.ServiceCategorieDetails={};
        $scope.serviceCreate = {};
        $scope.lengths='';
        $scope.event_id='';

 var path = window.location.pathname;
      var str = path.split("/");

      var url5 =  str[5];
      var url6 =  str[6];

  BridesProfileService.geteventnamedetail(url6).success(function(response)
        {
          $scope.event_name = response;
        });

$scope.init = function(){
BridesProfileService.getServiceRequestExp(url6)
            .success(function(response) {
		if(response === "1")
			jQuery("#descservreq").css('display','');
		else
			jQuery("#errservreq").css('display','');

            });
};
        // BridesProfileService.geteventdetails().success(function(response)
        // {
        //   $scope.event_details = response;
        //   $scope.event_id=$scope.event_details.event_id;
        //   alert($scope.event_id);


        // });


     var avaiableid =  url5;//$stateParams.id;
     if(avaiableid!==""){
       avaiableid =  url5;//$stateParams.id;
     }else{
      avaiableid =  1;
     }
     //console.log(avaiableid);

     // BridesProfileService.avaliableservicecategories()
     //        .success(function(response) {
     //            var avaiableid = response;
             
     //        });
        
        VendorsProfileService.getAvaliableServicecategories(url6).success(function(response1) {
          BridesProfileService.geteventdetails(url6).success(function(response)
        {
          $scope.event_details = response;
        jQuery(".ServiceReqForm").removeClass("temphide");

          $scope.event_id=$scope.event_details.event_id;
         // alert($scope.event_id);
          

        
        $scope.allCategories = response1;
        // alert(Object.keys($scope.allCategories).length);
        
        $scope.lengths=Object.keys($scope.allCategories).length;
               
        //$scope.firstkey=Object.keys($scope.allCategories)[0];
        // $scope.firstkey=avaiableid;


       var length_of_cat = $scope.lengths;

               if(length_of_cat>0){

                 //$scope.firstkey=Object.keys($scope.allCategories)[0];
        $scope.firstkey=avaiableid;

               }else{

                 $scope.firstkey=Object.keys($scope.allCategories)[0];
        //$scope.firstkey=avaiableid;


               }
       


         $scope.selectedCategories = avaiableid;
         // setTimeout(function(){
        //alert($scope.event_id);
       // $scope.event_id =$scope.event_id;
          // }, 3000);

        

                   var formData = {

              
              selectedCategories:  $scope.selectedCategories,
              event_id:  $scope.event_id,

            };
         

            $http({

              url: "pages/getAllServiceCategoriesDetails/"+url6,
              data: formData,
              method: 'POST',
             // headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

            }).success(function(response){
              $scope.ServiceCategoriesDetails=response;
               //$scope.test=$scope.test+1;
              

             //  location.href = 'pages/findvendorsresults';

            });


        $scope.selectedCategories=$scope.firstkey;
        $scope.templates = [{
                  name: 'photography.html',
                  url: 'photography.html'},
                  {
                  name: 'photography.html',
                  url: 'photography.html'},
                  {
                  name: 'videography.html',
                  url: 'videography.html'},
                  {
                  name: 'wedding.html',
                  url: 'wedding.html'},
                  {
                  name: 'DJ.html',
                  url: 'DJ.html'},
                  {
                  name: 'DJ.html',
                  url: 'DJ.html'},
                   {
                  name: 'DJ.html',
                  url: 'DJ.html'}
                  
                  ];

        $scope.template = $scope.templates[$scope.selectedCategories];

        //alert($scope.allCategories[0]);
        //var first=$scope.allCategories[0];
});

        });

 

        $scope.brideRequestFormSubmit = function() {

        var brideRequestForm = BrideRequestService.saveRequest($scope.serviceCreate);

        brideRequestForm.success(function(response) {

          NotificationService.serverAlert(response.message.title, response.message.body);
          location.href = 'brides/requests';

        }).error(function(response) {

            //  _.each(response, function(errors, key) {
            //      _.each(errors, function(e) {
            //        $scope.registrationForm[key].$dirty = true;
            //        $scope.registrationForm[key].$setValidity(key, false);
            //        $scope.errorMessages[key] = e;
            //      });
            //  });

        });

        };


          $scope.$watch('Categories', function(mVal){
                 $scope.brideRequestForm.$setPristine();
                $scope.ServiceCategoriesDetails={};
               // $scope.test=1;
                  //alert(mVal);
                  $scope.selectedCategories = mVal;
                  $scope.event_id =$("input[name='event_id']").val();

                   var formData = {

              
              selectedCategories:  $scope.selectedCategories,
              event_id:  $scope.event_id,

            };
         

            $http({

              url: "pages/getAllServiceCategoriesDetails/"+url6,
              data: formData,
              method: 'POST',
             // headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

            }).success(function(response){
              $scope.ServiceCategoriesDetails=response;
               //$scope.test=$scope.test+1;
              

             //  location.href = 'pages/findvendorsresults';

            });

                  $scope.templates = [{
                  name: 'photography.html',
                  url: 'photography.html'},
                  {
                  name: 'photography.html',
                  url: 'photography.html'},
                  {
                  name: 'videography.html',
                  url: 'videography.html'},
                  {
                  name: 'wedding.html',
                  url: 'wedding.html'},
                  {
                  name: 'DJ.html',
                  url: 'DJ.html'},
                  {
                  name: 'DJ.html',
                  url: 'DJ.html'},
                   {
                  name: 'DJ.html',
                  url: 'DJ.html'}
                  
                  ];
                  $scope.template = $scope.templates[$scope.selectedCategories];

         
            //$scope.selectedCategories = selected_service_id;

            //$scope.formUrl = '/pages/findvendorslists/' + $scope.selectedCategories + '/'+$scope.lat+'/'+$scope.lng;

        //alert($scope.formUrl);

       




            //alert(mVal);
          if (angular.isUndefined($scope.form)) return; 

          if(mVal === 'other'){
          $scope.form.OtherProgram = $scope.tmVar;
          } else {
          if($scope.form.OtherProgram !== null){
          $scope.tmVar = $scope.form.OtherProgram;
          $scope.form.OtherProgram = null;
          }
          }
          });
        $scope.bridesevicelists = function(){
          //console.log("1");

            // var selected_service_id = $scope.Categories;
            // $scope.selectedCategories = selected_service_id;

            // var formData = {

            // selectedCategories:  $scope.selectedCategories,

            // };
            //     $http({

            //     url: "pages/servicelists",
            //     data: formData,
            //     method: 'POST',


            //     }).success(function(response){
            //     $scope.findVendors = response;
            //     $scope.serviceRequestsCount = $scope.findVendors.length;

            //     //  location.href = 'pages/findvendorsresults';

            //     });
        };


                  $scope.changeservicerequest=function(){
                    
                  var selected_service_id = $scope.Categories;
                  $scope.selectedCategories = selected_service_id;
                  // alert($scope.lengths);
                  // alert(selected_service_id);
                  $scope.templates = [{
                  name: 'photography.html',
                  url: 'photography.html'},
                  {
                  name: 'photography.html',
                  url: 'photography.html'},
                  {
                  name: 'videography.html',
                  url: 'videography.html'},
                  {
                  name: 'wedding.html',
                  url: 'wedding.html'},
                  {
                  name: 'DJ.html',
                  url: 'DJ.html'},
                  {
                  name: 'DJ.html',
                  url: 'DJ.html'},
                   {
                  name: 'DJ.html',
                  url: 'DJ.html'}
                  
                  ];
                  $scope.template = $scope.templates[selected_service_id];

                     //console.log($templateCache.get('videography-planner/videography-planner.html'));
                   //alert($templateCache.get('videography-planner/videography-planner.html')); 
                     
                     
                     
                   

                  var formData = {

                  selectedCategories:  $scope.selectedCategories,

                  };

        };

    });
        


})();


jQuery(document).ready(function(){

$('body').on('keyup','#number_only',function(e){
    var value = $(this).val();
      value = value.replace(/^(0*)/,"");
      $(this).val(value);
  if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
     this.value = this.value.replace(/[^0-9\.]/g, '');
  }
});
});
