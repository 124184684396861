(function(){
		"use strict";

	angular.module('app.controllers').controller('DashboardController', function($http, $rootScope, $scope, VendorsProfileService ){

                //$scope.authUser =SessionService.get('authUser');

						VendorsProfileService.getdashboardvendors().success(function(response) 
						{

						$scope.dashboardValues  = response;
						jQuery('.VendorDashTitle').css('display','');
						jQuery('.DashboardSelect').css('display','');

						//console.log($scope.dashboardValues);
						$scope.sum=0;
							//console.dir($scope.dashboardValues.Msgcount);


						});

						$scope.vendorsajax = function(){

						var selected_service_id = $scope.Categories;
						$scope.selectedCategories = selected_service_id;

						var formData = {

						id:  $scope.selectedCategories,

						};
						$http({

						url: "vendors/vendorcategorieswise/get",
						data: formData,
						method: 'POST',


						}).success(function(response){

						 $scope.dashboardValues  = response;
						 $("#campaign").html(dashboardValues);

						//  location.href = 'pages/findvendorsresults';

						});
						};

	});

})();
