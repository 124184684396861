(function() {
    "use strict";
    angular.module('app.controllers').controller('ProfileSidebarController', function($scope, $location, $state) {


    	$scope.getClass = function (path) {
		  if ($location.path().substr(0, path.length) === path) {
		    return 'active';
		  } else {
		    return '';
		  }
		};

		$scope.state = $state.current.name;
		

	});

})();
