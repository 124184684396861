(function() {
    "use strict";

    angular.module('app.controllers').controller('MessageNotificationController', function($scope,UsersInvites, AuthService, $http) {

            $scope.messages = {};



            AuthService.allNotifications().success(function(response) {

            $scope.Notifymessages = response;


            });

            UsersInvites.getMessagesNotifications().success(function(response) {

            $scope.messages = response;
            $scope.role_user = response;


            if($scope.messages.unreadNotificationCount !== 0)
            {
            jQuery('.count').css('display','block');
            }

             if($scope.messages.messagesUnread !== 0)
            {
            	jQuery('#counts').css('display','block');
            }


            });
                $scope.readStatus = function (serviceId){
               // alert(serviceId);
                $http({

                url: "users/messagereaded/"+serviceId,
                data: {serviceId:serviceId},
                method: 'POST',
                headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

                }).success(function(response){
                    $scope.Notifymessages.unreadNotificationCount--;
                    var x = document.querySelectorAll(".notifier_"+serviceId);
                    var i;
                    for (i = 0; i < x.length; i++) {
                        x[i].style.display = "none";
                    }

                //console.log("OK", response);
               // NotificationService.serverNotificationNoRedirect(response.message.title,response.message.body);

                }).error(function(response) {


                });
              //  console.log('deleting user with id='+userId+' at index='+index);
                };

                $scope.clearStatus = function (){
                  
                    $http({

                    url: "users/clearstatus",
                    method: 'POST',
                    headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

                    }).success(function(response){

                        location.reload();

                    }).error(function(response) {

                    });
                };

    });

})();
