(function() {
  "use strict";
  angular.module('app.controllers').controller('TasksController', function($scope, $location,BridesProfileService) {

 BridesProfileService.getuserdetails().success(function(response) 
    {
    $scope.userName = response;
    console.log($scope.userName);
    });

    $scope.getClass = function(path) {
      if ($location.path().substr(0, path.length) === path) {
        return 'active';
      } else {
        return '';
      }
    };



  });

})();
