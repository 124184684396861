(function() {
    "use strict";

    angular.module('app.controllers').controller('AccordianController', function($scope,BridesProfileService) {

        $scope.oneAtATime = true;

        $scope.status = {
            isFirstOpen: true,
            isFirstDisabled: false
        };

        $('html').on('click',function(){
            $('.panel-collapse').removeClass('in');
            $('.panel-collapse').addClass('collapse');
        });

        $('body').on('click','.panel-group',function(event){
            event.stopPropagation();
        });

        $('body').on('click','.js-dropdown-link a', function (event) {
          $('.panel-collapse').removeClass('in');
          $('.panel-collapse').addClass('collapse');
        });
        
        $scope.privacypolicyWithSingleURL = 'pages/privacypolicy';
        $scope.termsofuseWithSingleURL = 'pages/termsofuse';

    });



})();

angular.module('app.controllers').directive('replaces', function() {
  return {
    require: 'ngModel',
    scope: {
      regex: '@replaces',
      with: '@with'
    }, 
    link: function(scope, element, attrs, model) {
      model.$parsers.push(function(val) {
        if (!val) { return; }
        var regex = new RegExp(scope.regex);
        var replaced = val.replace(regex, scope.with); 
        if (replaced !== val) {
          model.$setViewValue(replaced);
          model.$render();
        }         
        return replaced;         
      });
    }
  };
});
