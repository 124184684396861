(function() {
    "use strict";

    angular.module('app.controllers').controller('BridesServiceRequestsDetailController', function($scope, $state, $stateParams,$http, BridesProfileService, NotificationService, $filter) {

        $scope.serviceRequest = {};

        BridesProfileService.getServiceRequestDetails($stateParams.id)
            .success(function(response) {
                $scope.serviceRequest = response;
                jQuery('.datesBrClass').css('display','');
            });

      var path = window.location.pathname;
      var str = path.split("/");
      var url3 =  str[3];
      var url4 =  str[4];

$scope.eventIds = url4; 


BridesProfileService.getCountMessage($stateParams.id).success(function(response)
            {
            $scope.messagecount = response;
            });
 
       BridesProfileService.geteventnamedetail(url4).success(function(response)
            {
            $scope.eventName = response;
            });


        BridesProfileService.getServiceRequestId($stateParams.id)
            .success(function(response) {
                $scope.serviceRequestId = response;
            });  

 BridesProfileService.getcurrentdates().success(function(response) {

            $scope.currentdate = response;

            });


 $scope.readStatus = function (serviceId){
               // alert(serviceId);
                $http({

                url: "users/messagereaded/"+serviceId,
                data: {serviceId:serviceId},
                method: 'POST',
                headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

                }).success(function(response){
                    $scope.Notifymessages.unreadNotificationCount--;
                    var x = document.querySelectorAll(".notifier_"+serviceId);
                    var i;
                    for (i = 0; i < x.length; i++) {
                        x[i].style.display = "none";
                    }

                //console.log("OK", response);
               // NotificationService.serverNotificationNoRedirect(response.message.title,response.message.body);

                }).error(function(response) {


                });
              //  console.log('deleting user with id='+userId+' at index='+index);
                };


$scope.currenturi = $stateParams.id; 

       // $scope.CurrentDate = new Date(), 'MM/dd/yyyy';

        var date = new Date();
            $scope.CurrentDate = $filter('date')(new Date(), 'MM/dd/yyyy');

        $scope.acceptBid = function(id) {
            BridesProfileService.acceptBid(id)
                .success(function(response) {
                NotificationService.AccpetBidAlert(response.message.title,response.message.body,url4);
                });
        };
       
        var bid_id = $("input[name='bid_id']").val();

        var counter = 0;
        $scope.brideBiddingnotesFormSubmit = function($event){

          counter++;
          

        
             var formData = {
                    
              service_id: $scope.service_id = $("input[name='service_id']").val(),
              bride_id: $scope.bride_id = $("input[name='bride_id']").val(),
              vendor_id: $scope.vendor_id = $("input[name='vendor_id']").val(),
              notes:  $scope.notes = $("input[name='notes']").val(),

              };

           $http({

            url: "brides/sendanotes",
            data: formData,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

            }).success(function(response){
            //console.log("OK", response);
             NotificationService.serverNotificationRedirectSamePage(response.message.title,response.message.body,url4);

            });
             $event.preventDefault();

        };
        $scope.sort = function(keyname){
               
              $scope.sortKey = keyname;   //set the sortKey to the param passed
              $scope.reverse = !$scope.reverse; //if true make it false and vice versa
            };
    });
        angular.module('app.controllers').controller('AppCtrl', function($scope) {

            $scope.custom = true;
                $scope.toggleCustom = function() {
                     $scope.custom = $scope.custom === false ? true: false;
                };
        });

  


//         angular.module('app.controllers').filter('groupBy', function () {
//     return function (data, key) {
      
//         if (!(data && key)) return;

//         var result = {};

//         for (var i=0;i<data.length;i++) 
//         {
//             if (!result[data[i][key]])

//                 result[data[i][key]]=[];

//             result[data[i][key]].push(data[i]);

//         }
//         return result;

//     };
// });

// angular.module('app.controllers').filter('groupBy', function ($timeout) {
//     return function (data, key) {
//         if (!key) return data;
//         var outputPropertyName = '__groupBy__' + key;
//         if(!data[outputPropertyName]){
//             var result = {};  
//             for (var i=0;i<data.length;i++) {
//                 if (!result[data[i][key]])
//                     result[data[i][key]]=[];
//                 result[data[i][key]].push(data[i]);
//             }
//             Object.defineProperty(data, outputPropertyName, {enumerable:false, configurable:true, writable: false, value:result});
//             $timeout(function(){delete data[outputPropertyName];},0,false);
//         }
//         return data[outputPropertyName];
//     };
// });

//  angular.module('app.controllers').filter('unique', function() {
//    return function(collection, keyname) {
//       var output = [], 
//           keys = [];

//       angular.forEach(collection, function(item) {
//           var key = item[keyname];
//           if(keys.indexOf(key) === -1) {
//               keys.push(key);
//               output.push(item);
//           }
//       });
//       return output;
//    };
// });

        angular.module('app.controllers').filter('formats', function () {
          return function (item) {
               var t = item.split(/[- :]/);
           var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
           var time=d.getTime();                 
                   return time;
          };
        });

         angular.module('app.controllers').filter('dateformat', function ()
          {
          return function (item) {
            var t = item.split(/[- :]/);
            var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
            var time = d.getTime(); 
            return time;
          };
          });
})();
