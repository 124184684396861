(function() {
  "use strict";

  var app = angular.module('app', [
    'ngMaterial',
    'app.controllers',
    'app.filters',
    'app.services',
    'app.directives',
    'app.routes',
    'app.config',
    'ui.bootstrap',
    'ngMessages',
    'angular-loading-bar',
    'ngAnimate',
    'vcRecaptcha',
    'ngCookies',
    'satellizer',
    'ngFileUpload',
    'ngTinyScrollbar',
    'ui.utils.masks.us'
  ]);

  angular.module('app.routes', ['ui.router', 'ngStorage']);
  angular.module('app.controllers', ['ui.router', 'ngMaterial', 'ngStorage', 'restangular', 'vcRecaptcha', 'ngAnimate']);
  angular.module('app.filters', []);
  angular.module('app.services', ['ui.router', 'ngStorage', 'restangular', 'ngMaterial', 'ngCookies']);
  angular.module('app.directives', []);
  angular.module('app.run', []);
  angular.module('app.config', ['ui.bootstrap', 'restangular', 'ngMaterial', 'angular-loading-bar', 'satellizer', 'ngMaterial']);

  $("#alpha_onlys").on("keypress", function(event) {
    var englishAlphabetDigitsAndWhiteSpace = /[A-Za-z\s ]/g;
    var key = String.fromCharCode(event.which);

    if (englishAlphabetDigitsAndWhiteSpace.test(key)) {
      return true;
    }
    return false;
  });

  $('#alpha_onlys').on("paste", function(e) {
    e.preventDefault();
  });

  angular.module('app.controllers').directive('myTarget', function() {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        var href = element.href;
        if (true) { // replace with your condition
          element.attr("target", "_blank");
        }
      }
    };
  });

angular.module('app.config').config(['$tooltipProvider', function($tooltipProvider){
  $tooltipProvider.setTriggers({
    'mouseenter': 'mouseleave',
    'click': 'click',
    'focus': 'blur',
    'click focus': 'mouseleave blur',
    'never': 'mouseleave' // <- This ensures the tooltip will go away on mouseleave
  
  });
}]);




  angular.module('app.controllers').controller('CollapseCtrl', function($scope) {
    $scope.isCollapsed = true;
    $scope.isCollapsed01 = true;
    $scope.isCollapsed02 = true;
    $scope.$on('$stateChangeStart', function() {
      $scope.isCollapsed = true;
    });
  });







})();

jQuery(document).ready(function() {
  jQuery('body').on('click', '.DashWon .View span, .DashLost .View span', function() {
    jQuery(this).toggleClass('active');
    jQuery(this).parents('.Dashhead').next().slideToggle();
    //$('#updateProfile').show();
  });


});
