(function() {
  "use strict";

  angular.module('app.controllers').controller('AdminloginController', function($rootScope, $auth, $scope, $state, AuthService, NotificationService, $window, $http) {

$scope.websites = ['Remember me'];
  $scope.selection = ['Remember me'];
  $scope.chkremember=1;
  $scope.toggleSelection = function toggleSelection(site) {

    var idx = $scope.selection.indexOf(site);
    
    // is currently selected
    if (idx > -1) {
        //uncheck
      $scope.chkremember=0;
              $("#chkadminremember").removeClass("ng-scope md-black-theme md-checked");
              setTimeout(function(){
  $("#chkadminremember").removeClass("md-checked");
}, 200);
                       
              
      $scope.selection.splice(idx, 1);

    }

    // is newly selected
    else {
        //check
        $scope.chkremember=1;
         $("#chkadminremember").addClass("ng-scope md-black-theme");
         
      $scope.selection.push(site);
      
    }

  };

    $scope.errorMessages = {};
    $scope.user = {};

    if(sessionStorage.rememberemail===undefined)
         {
            
            sessionStorage.rememberemail='';
            sessionStorage.rememberpassword='';
            $scope.user.email=sessionStorage.rememberemail;
            $scope.user.password=sessionStorage.rememberpassword;
           // alert('2');
         }
         else if(sessionStorage.rememberemail==='')
         {
          sessionStorage.rememberemail='';
         sessionStorage.rememberpassword='';

         }
         else
         {
         //$("#email").val(sessionStorage.rememberemail);
         //alert(sessionStorage.rememberemail);
         $scope.user.email=sessionStorage.rememberemail;
         $scope.user.password=sessionStorage.rememberpassword;
         
         }

    $scope.loginSubmit = function() {

      var auth = AuthService.admin($scope.user);
      auth.success(function(response) {

        if (response.success === true) {

          // NotificationService.serverNotification(
          //     response.message.title,response.message.body
          // );
       if($scope.chkremember===1)
                    {
                        
                      sessionStorage.rememberemail=$scope.user.email;  
                      sessionStorage.rememberpassword=$scope.user.password;  

                    }
                    else
                    {
                        sessionStorage.rememberemail='';
                        sessionStorage.rememberpassword='';
                    }
          $window.location = '/';

        } else if (response.success === false) {
          NotificationService.serverAlert(
            response.message.title, response.message.body
          );
        }

      }).error(function(response) {

        _.each(response, function(errors, key) {
          _.each(errors, function(e) {
            $scope.errorMessages[key] = e;
            $scope.loginForm[key].$dirty = true;
            $scope.loginForm[key].$setValidity(key, false);
          });
        });

      });


var auth1 = AuthService.adminSlash($scope.user);
      auth1.success(function(response) {

        if (response.success === true) {

          // NotificationService.serverNotification(
          //     response.message.title,response.message.body
          // );
       if($scope.chkremember===1)
                    {
                        
                      sessionStorage.rememberemail=$scope.user.email;  
                      sessionStorage.rememberpassword=$scope.user.password;  

                    }
                    else
                    {
                        sessionStorage.rememberemail='';
                        sessionStorage.rememberpassword='';
                    }
          $window.location = '/';

        } else if (response.success === false) {
          NotificationService.serverNotification(
            response.message.title, response.message.body
          );
        }

      }).error(function(response) {

        _.each(response, function(errors, key) {
          _.each(errors, function(e) {
            $scope.errorMessages[key] = e;
            $scope.loginForm[key].$dirty = true;
            $scope.loginForm[key].$setValidity(key, false);
          });
        });

      });


    };

    $scope.logout = function() {

      var logout = AuthService.destroy();

      logout.success(function(response) {

        // NotificationService.serverNotification(
        // response.message.title,response.message.body
        // );
        $window.location = '/';

      }).error(function(response) {

        NotificationService.serverAlert(
          response.message.title, response.message.body
        );
      });

    };

  });


})();
