(function() {
  "use strict";

  angular.module('app.routes').run(['$rootScope', '$state', '$stateParams', function($rootScope, $state, $stateParams) {
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
    // $state.transitionTo('home');
     // $rootScope.$on('$stateChangeStart', function() {
     //    $rootScope.showSpinner = true;

     //  });
    $rootScope.$on('$stateChangeSuccess',function(){
        $("html, body").animate({ scrollTop: 0 }, 200);
        $('.submenu').hide();
        $('.scroll-bar').removeClass('menu-scroll-thumb');
//added to update title bar
titleUpdater();
    });
  }]);

  angular.module('app.routes').run(['$rootScope', '$state', '$stateParams', function($rootScope, $state, $stateParams) {
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
    // $state.transitionTo('home');
  }]);



  angular.module('app.routes').config(function($stateProvider, $urlRouterProvider, $locationProvider) {

    $locationProvider.html5Mode(true);

    var getView = function(viewName) {
      return '/views/' + viewName + '/' + viewName + '.html';
    };

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('layout', {
        abstract: true,
        views: {
          header: {
            templateUrl: '/navbar'
          },
          footer: {
            templateUrl: getView('footer')
          },
        }
      })
      .state('home', {
        parent: 'layout',
        url: '/',
        views: {
          'main@': {
            templateUrl: getView('home')
          },
        }
      })
      .state('registration', {
        parent: 'layout',
        url: '/register',
        views: {
          'main@': {
            templateUrl: getView('registration')
          }
        }
      })
      .state('login', {
        parent: 'layout',
        url: '/login',
        views: {
          'main@': {
            templateUrl: getView('login')
          }

        }
      })

    .state('users-activatelogin', {
      parent: 'layout',
      url: '/users/activate/:token',
      views: {
        'main@': {
          //templateUrl: '/users/activatelogin'
          templateUrl: getView('activate-account')
        }
      }
    })

    .state('invite-friends', {
      parent: 'layout',
      url: '/users/invitefriends',
      views: {
        'main@': {
          //templateUrl: '/users/activatelogin'
          templateUrl: getView('invite-friends')
        }
      }
    })

    .state('admin', {
      parent: 'layout',
      url: '/admin/',
      views: {
        'main@': {
          templateUrl: getView('admin')
        }
      }
    })


     .state('adminSlash', {
      parent: 'layout',
      url: '/admin',
      views: {
        'main@': {
          templateUrl: getView('admin')
        }
      }
    })

    .state('forget-password', {
        parent: 'layout',
        url: '/password/forget',
        views: {
          'main@': {
            templateUrl: getView('forget-password')
          }
        }
      })
    .state('admin-forget-password', {
        parent: 'layout',
        url: '/admin/password/forget',
        views: {
          'main@': {
            templateUrl: getView('admin-forget-password')
          }
        }
      })
      .state('reset-password', {
        parent: 'layout',
        url: '/password/reset/:token',
        views: {
          'main@': {
            templateUrl: getView('reset-password')
          }
        }
      })
      .state('brides-create-event', {
        parent: 'layout',
        url: '/brides/events/create',
        views: {
          'main@': {
            templateUrl: getView('brides-create-event')
          }
        }
      })
      .state('brides-firsttime-create-event', {
        parent: 'layout',
        url: '/brides/events/create',
        views: {
          'main@': {
            templateUrl: getView('brides-firsttime-create-event')
          }
        }
      })


    .state('brides-profile', {
      parent: 'layout',
      url: '/brides/profile',
      views: {
        'main@': {
          templateUrl: getView('brides-profile')
          // templateUrl: '/brides/profile'
        }
      },


    })

 
.state('brides-event-update', {
        parent: 'layout',
        url: '/brides/event/update/:id',
        views: {
          'main@': {
            templateUrl: getView('brides-event-update')
            //templateUrl: '/brides/dashboard'
          }
        }
      })

    .state('brides-dashboard', {
        parent: 'layout',
        url: '/brides/dashboard',
        views: {
          'main@': {
              templateUrl: getView('brides-dashboard')
      // templateUrl: '/brides/dashboard'
          }
        }
      })

    .state('brides-dashboard-services', {
        parent: 'layout',
        url: '/brides/bridesservices/:id',
        views: {
          'main@': {
		                templateUrl: getView('brides-dashboard-services')
	       // templateUrl: '/brides/dashboard'
          }
        }
      })

      .state('brides-requests-accepted', {
        parent: 'layout',
        url: '/brides/requests/accepted/:id',
        views: {
          'main@': {
            templateUrl: getView('brides-accepted-requests')
          }
        }
      })

      .state('brides-accepted-requests-show', {
        parent: 'layout',
        url: '/brides/requests/accepted/:id/:eid',
        views: {
          'main@': {
            templateUrl: getView('brides-accepted-requests-show')
          }
        }
      })

    .state('brides-requests', {
        parent: 'layout',
        url: '/brides/requests/:id',
        views: {
          'main@': {
            templateUrl: getView('brides-service-requests')
          }
        }
      })
      .state('brides-service-requests-show', {
        parent: 'layout',
        url: '/brides/requests/:id/:eid',
        views: {
          'main@': {
            templateUrl: getView('brides-service-requests-show')
          }
        }
      })
      .state('service-categories', {
        parent: 'layout',
        url: '/brides/service/categories',
        views: {
          'main@': {
            templateUrl: '/brides/service/categories'
          }
        }
      })
      .state('service-request-create', {
        parent: 'layout',
        url: '/brides/services/requests/create/:id/:eid',
        views: {
          'main@': {
            templateUrl: getView('service-request-create')
          }
        }
      })

      .state('service-request-update', {
        parent: 'layout',
        url: '/brides/services/requests/update/:id/:eid',
        views: {
          'main@': {
            templateUrl: getView('service-request-update')
          }
        }
      })
    
      .state('vendors-dashboard', {
        parent: 'layout',
        url: '/vendors/dashboard',
        views: {
          'main@': {
           templateUrl: getView('vendordashboard') //   templateUrl: '/vendors/dashboard'
          },
        }
      })
      .state('vendors-profile-setp-one', {
        parent: 'layout',
        url: '/vendors/profile/step_one',
        views: {
          'main@': {
            templateUrl: getView('vendors-profile-step-one')
          },
        }
      })

    .state('vendors-profile-setp-two', {
      parent: 'layout',
      url: '/vendors/profile/step_two',
      views: {
        'main@': {
          templateUrl: getView('vendors-profile-step-two')
        },
      }
    })

    .state('vendors-requests-list', {
      parent: 'layout',
      url: '/vendors/requests',
      views: {
        'main@': {
          templateUrl: getView('vendor-service-requests')
        },
      }
    })

    .state('vendor-service-requests-show', {
      parent: 'layout',
      url: '/vendors/requests/:id',
      views: {
        'main@': {
          templateUrl: getView('vendor-service-requests-show')
        },
      }
    })

    .state('vendor-service-requests-accept-show', {
      parent: 'layout',
      url: '/vendors/requests/accept/:id',
      views: {
        'main@': {
          templateUrl: getView('vendor-service-requests-accept-show')
        },
      }
    })

    .state('vendor-service-requests-placed-show', {
      parent: 'layout',
      url: '/vendors/requests/placed/:id',
      views: {
        'main@': {
          templateUrl: getView('vendor-service-requests-placed-show')
        },
      }
    })

    .state('vendor-service-requests-lost-show', {
      parent: 'layout',
      url: '/vendors/requests/lost/:id',
      views: {
        'main@': {
          templateUrl: getView('vendor-service-requests-lost-show')
        },
      }
    })

    .state('vendors-myprofile', {
      parent: 'layout',
      url: '/vendors/myprofile',
      views: {
        'main@': {
          templateUrl: getView('vendors-myprofile')
        },
      }
    })






    .state('vendors-requests-bidding-success', {
      parent: 'layout',
      url: '/vendors/requests/:id/biddings/successfull',
      views: {
        'main@': {
          templateUrl: getView('vendors-bidding-successfull')
        },
      }
    })

    .state('vendors-requests-bidding-cancelled', {
        parent: 'layout',
        url: '/vendors/requests/:id/biddings/cancelled',
        views: {
          'main@': {
            templateUrl: getView('vendors-bidding-cancelled')
          },
        }
      })
      .state('vendor-service-requests-accepted', {
        parent: 'layout',
        url: '/vendors/acceptedbids',
        views: {
          'main@': {
            templateUrl: getView('vendor-service-requests-accepted')
              //templateUrl: '/vendors/acceptedbids'vendors-acceptedbids
          }
        }
      })

    .state('vendor-service-requests-placed', {
      parent: 'layout',
      url: '/vendors/placedbids',
      views: {
        'main@': {
          templateUrl: getView('vendor-service-requests-placed')
        }
      }
    })

    .state('vendors-lostbids', {
      parent: 'layout',
      url: '/vendors/lostbids',
      views: {
        'main@': {
          //templateUrl: '/vendors/lostbids'
          templateUrl: getView('vendor-service-requests-lost')

        }
      }
    })

    .state('service-requests-map', {
      parent: 'layout',
      url: '/vendors/servicerequestsmap',
      views: {
        'main@': {
          templateUrl: getView('service-requests-map')

        }
      }
    })

    .state('active-bids-map', {
      parent: 'layout',
      url: '/vendors/activebidsmap',
      views: {
        'main@': {
          templateUrl: getView('active-bids-map')

        }
      }
    })

    .state('won-bids-map', {
      parent: 'layout',
      url: '/vendors/wonbidsmap',
      views: {
        'main@': {
          templateUrl: getView('won-bids-map')

        }
      }
    })

    .state('lost-bids-map', {
      parent: 'layout',
      url: '/vendors/lostbidsmap',
      views: {
        'main@': {
          templateUrl: getView('lost-bids-map')

        }
      }
    })

    .state('find-vendors', {
      parent: 'layout',
      //url: '/pages/findvendorslists/:selectedCategories:lat:lng',
      url: '/pages/findvendorslists',
      views: {
        'main@': {
          templateUrl: getView('find-vendors')

        }
      }
    })

     .state('contact-us', {
      parent: 'layout',
      //url: '/pages/findvendorslists/:selectedCategories:lat:lng',
      url: '/pages/contact-us',
      views: {
        'main@': {
          templateUrl: getView('contact-us')

        }
      }
    })
       .state('report-problem', {
      parent: 'layout',
      //url: '/pages/findvendorslists/:selectedCategories:lat:lng',
      url: '/pages/report-problem',
      views: {
        'main@': {
          templateUrl: getView('report-problem')

        }
      }
    })

    .state('brides-service-requests', {
      parent: 'layout',
      url: '/brides/requests/:id',
      views: {
        'main@': {
          templateUrl: getView('brides-service-requests')

        }
      }
    })

    .state('vendor-service-requests', {
      parent: 'layout',
      url: '/vendors/requests',
      views: {
        'main@': {
          templateUrl: getView('vendor-service-requests')

        }
      }
    })


    .state('find-vendors-details', {
      parent: 'layout',
      url: '/pages/findvendorsdetails/:id',
      views: {
        'main@': {
          templateUrl: getView('find-vendors-details')
        }
      }
    })

    .state('message-history', {
      parent: 'layout',
      url: '/users/messagehistory/:service_request_id/:vendor_id',
      views: {
        'main@': {
          templateUrl: getView('message-history')

        }
      }
    })


    .state('service-requests-wedding-planner', {
      parent: 'layout',
      url: '/brides/weddingplaner',
      views: {
        'main@': {
          templateUrl: getView('service-requests-wedding-planner')
        }
      }
    })

    .state('service-requests-photography', {
        parent: 'layout',
        url: '/brides/photography',
        views: {
          'main@': {
            templateUrl: getView('service-requests-photography')
          }
        }
      })
      .state('service-requests-videography', {
        parent: 'layout',
        url: '/brides/videography',
        views: {
          'main@': {
            templateUrl: getView('service-requests-videography')
          }
        }
      })

    .state('service-requests-dj', {
      parent: 'layout',
      url: '/brides/discjackey',
      views: {
        'main@': {
          templateUrl: getView('service-requests-dj')
        }
      }
    })


    // .state('vendor-service-requests-accepted', {
    //     parent: 'layout',
    //     url: '/vendors/requests/service/accepted',
    //     views: {
    //       'main@': {
    //           templateUrl: getView('vendor-service-requests-accepted')
    //       },
    //     }
    // })

    .state('cms-page', {
      parent: 'layout',
      url: '/pages/:slug',
      views: {
        'main@': {
          templateUrl: getView('cms-pages')
        }
      }
    })

       .state('mobilecms-pages', {
      parent: 'layout',
      url: '/mobilepages/:slug',
      views: {
        'main@': {
          templateUrl: getView('mobilecms-pages')
        }
      }
    })


    ;
  });

})();
