(function() {
//Data
angular.module('app.controllers').controller('LostBidsMapCtrl', function ($scope,$http) {
$scope.selectedCategories ='';
var minZoomLevel = 5;
var formData = {
selectedCategories:  $scope.selectedCategories,
};
$http({
url: "vendors/lostbidsmap",
data: formData,
method: 'POST',
}).success(function(response){
$scope.findVendors = response;
$scope.serviceRequestsCount = $scope.findVendors.length;
var mapOptions = {
zoom: minZoomLevel,
center: new google.maps.LatLng($scope.findVendors[0].latitude, $scope.findVendors[0].longitude),
mapTypeId: google.maps.MapTypeId.TERRAIN
};
$scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);
$scope.markers = [];
var infoWindow = new google.maps.InfoWindow();
var createMarker = function (info){
var marker = new google.maps.Marker({
map: $scope.map,
position: new google.maps.LatLng(info.latitude, info.longitude),
title: info.type,
icon: "images/lostbidsmap.png"
});
marker.content = '<div class="infoWindowContent">' + info.name + '</div>';
google.maps.event.addListener(marker, 'click', function(){
infoWindow.setContent('<h2>' + marker.title + '</h2>' + marker.content);
infoWindow.open($scope.map, marker);
});
$scope.markers.push(marker);
};
var createMarker1 = function (info){
var marker1 = new google.maps.Marker({
map: $scope.map,
position: new google.maps.LatLng(info.latitude, info.longitude),
title: info.type,
icon: "images/map_yellow.png"
});
marker1.content = '<div class="infoWindowContent">' + info.name + '</div>';
google.maps.event.addListener(marker1, 'click', function(){
infoWindow.setContent('<h2>' + marker1.title + '</h2>' + marker1.content);
infoWindow.open($scope.map, marker1);
});
$scope.markers.push(marker1);
};  
for (i = 1; i < $scope.findVendors.length; i++){
createMarker($scope.findVendors[i]);
}
createMarker1($scope.findVendors[0]);
$scope.openInfoWindow = function(e, selectedMarker){
e.preventDefault();
google.maps.event.trigger(selectedMarker, 'click');
};
});    
});
})();

