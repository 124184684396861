(function(){
    "use strict";

    angular.module('app.controllers').controller('PasswordController', function( $scope,$state,AuthService,NotificationService,$window ){

          $scope.errorMessages = {};

          $scope.processForgetPassword = function(){

           var auth = AuthService.forgetPassword($scope.user);

           auth.success(function(response){

               NotificationService.serverAlert(
                   response.message.title,response.message.body
               );

           }).error(function(response) {

                _.each(response, function(errors, key) {
                    _.each(errors, function(e) {
                      $scope.errorMessages[key] = e;
                      $scope.forgetPassword[key].$dirty = true;
                      $scope.forgetPassword[key].$setValidity(key, false);
                      });
                });

           });

        };

    });

})();
