(function() {
    "use strict";

    angular.module('app.controllers').controller('VendorsplacedBiddingsController', function($http,$window,$scope, $mdDialog, $state, $stateParams, VendorsProfileService, NotificationService) {

        $scope.serviceRequest = {};
        var item;
        var service_fee = " ";
        VendorsProfileService.getServiceFeeDetails().success(function(response) {
               
               $scope.service_fee = response;
               var service_fee = $scope.service_fee;
               
            });

             $scope.readStatus = function (serviceId){
               // alert(serviceId);
                $http({

                url: "users/messagereaded/"+serviceId,
                data: {serviceId:serviceId},
                method: 'POST',
                headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

                }).success(function(response){
                    $scope.Notifymessages.unreadNotificationCount--;
                    var x = document.querySelectorAll(".notifier_"+serviceId);
                    var i;
                    for (i = 0; i < x.length; i++) {
                        x[i].style.display = "none";
                    }

                //console.log("OK", response);
               // NotificationService.serverNotificationNoRedirect(response.message.title,response.message.body);

                }).error(function(response) {


                });
              //  console.log('deleting user with id='+userId+' at index='+index);
                };


VendorsProfileService.getCountMessages($stateParams.id).success(function(response)
            {
            $scope.messagecount = response;
            });

        VendorsProfileService.getcurrentvendorid().success(function(response) {
               
               $scope.currentvid = response;
               
            });

        
            VendorsProfileService.getcurrentdate().success(function(response) {

            $scope.currentdate = response;

            });


            //$scope.formUrl = '/vendors/requests/withoutpay/' + $stateParams.id;

           $scope.formUrl = '/vendors/services/requests/biddings/rebid'; 

           // $scope.repaysubmitclick = function() {
          
         // $('#repaysubmit').hide();

//          };

	$scope.repaysubmitclick = function() {
            $('#repaysubmit').hide();
         // $scope.csrfToken = document.getElementById('csrf-token').value;
          

if($("input[name='bidding_amount']").val() < 1)
{
var alert = $mdDialog.alert({
                    title: 'Attention',
                    content: 'Please enter an amount greater than $1.00',
                    ok: 'OKAY'
                });
                $mdDialog
                    .show(alert)
                    .finally(function() {
                      $window.location.reload();
                    });
}

else
{
        var confirm = $mdDialog.confirm()
        .parent(angular.element(document.body))
        .title('Attention')
        .content("Please confirm your Quote.")
        .ariaLabel('Attention')
        .ok('Okay') 
        .cancel('Cancel')
        .hasBackdrop(false); 
        $mdDialog.show(confirm).then(function() {
            document.getElementById('vendorBiddingForm').submit();           
        }, function() {
          
           // $window.location = '/vendors/requests';
         $('#repaysubmit').show();
        });

}
          };



            $scope.csrfToken = document.getElementById('csrf-token').value;

            VendorsProfileService.getServiceRequestDetails($stateParams.id)
            .success(function(response) {

              $scope.serviceRequest = response;
              jQuery('.dateplasedClass').css('display','');

            });

             VendorsProfileService.getServiceRequestIdVendor($stateParams.id)
            .success(function(response) {
                $scope.serviceRequestIdshow = response;
            });


            VendorsProfileService.getServiceRequestMiniumDetails($stateParams.id)
            .success(function(response) {

              $scope.serviceRequest_minimum = response;
              $scope.minimumCount = $scope.serviceRequest_minimum.length;
              //console.log($scope.serviceRequest_minimum);

            });

            VendorsProfileService.getServiceRequestBestoffer().success(function(response) {

              $scope.bestOffer = response;

            });
            $scope.sort = function(keyname){
              $scope.sortKey = keyname;   //set the sortKey to the param passed
              $scope.reverse = !$scope.reverse; //if true make it false and vice versa
            };
$scope.showData = function( ){

 $scope.itemsPerPage = 2005;
 $scope.currentPage = 0;
     $scope.datalists =  $scope.serviceRequest_minimum;
     $scope.range = function() {
    var rangeSize = 5;
    var ps = [];
    var start;

    start = $scope.currentPage;
       //  console.log($scope.pageCount(),$scope.currentPage)
    if ( start > $scope.pageCount()-rangeSize ) {
      start = $scope.pageCount()-rangeSize+1;
    }

    for (var i=start; i<start+rangeSize; i++) {
      if(i>=0) 
         ps.push(i);
    }
    return ps;
  };

  $scope.prevPage = function() {
    if ($scope.currentPage > 0) {
      $scope.currentPage--;
    }
  };

  $scope.DisablePrevPage = function() {
    return $scope.currentPage === 0 ? "disabled" : "";
  };

  $scope.pageCount = function() {
    return Math.ceil($scope.datalists.length/$scope.itemsPerPage)-1;
  };

  $scope.nextPage = function() {
    if ($scope.currentPage < $scope.pageCount()) {
      $scope.currentPage++;
    }
  };

  $scope.DisableNextPage = function() {
    return $scope.currentPage === $scope.pageCount() ? "disabled" : "";
  };

  $scope.setPage = function(n) {
    $scope.currentPage = n;
  };
         
};
            

            // VendorsProfileService.rebidservicesrequest()
            // .success(function(response) {

            //    NotificationService.serverAlert(response.message.title, response.message.body);

            // });
            


             $scope.vendorBiddingnotesFormSubmit = function(){
              

             var formData = {
                    
              service_id: $scope.service_id = $("input[name='service_id']").val(),
              bride_id: $scope.bride_id = $("input[name='bride_id']").val(),
              vendor_id: $scope.vendor_id = $("input[name='vendor_id']").val(),
              notes: $scope.notes = $scope.bidding.notes,

              };

           $http({

            url: "vendors/sendanotes",
            data: formData,
            method: 'POST',
            headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

            }).success(function(response){

            //console.log("OK", response);
             NotificationService.serverAlert(response.message.title,response.message.body);

            });

        };

    });

    angular.module('app.controllers').controller('VendorsBiddingsCancelledController', function($scope, $state, $stateParams, VendorsProfileService, NotificationService) {

        $scope.serviceRequest = {};

        VendorsProfileService.getServiceRequestDetails($stateParams.id)
            .success(function(response) {
                $scope.serviceRequest = response;
            });

        VendorsProfileService.biddingCancelled($stateParams.id)
            .success(function(response) {
                if (response.success === false) {
                    NotificationService.serverAlert(response.message.title, response.message.body);
                }
            });
    });

    angular.module('app.controllers').controller('VendorsBiddingsSuccessfullController', function($scope, $state, $stateParams, VendorsProfileService, NotificationService) {

        $scope.serviceRequest = {};
        VendorsProfileService.getServiceRequestDetails($stateParams.id)
            .success(function(response) {
                $scope.serviceRequest = response;
            });

        VendorsProfileService.biddingSuccessfull()
            .success(function(response) {
                if (response.success === true) {
                    NotificationService.serverAlert(response.message.title, response.message.body);
                }
            });
    });
    
    angular.module('app.controllers').filter('format', function () {
          return function (item) {
           
           var t = item.split(/[- :]/);
           var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
           var time = d.getTime(); 
           
            var nextDay = new Date(d);
            nextDay.setDate(d.getDate()+15);
            return nextDay;
          };
        });

})();
$('body').on('keyup','#number_only',function(e){
    var value = $(this).val();
      value = value.replace(/^(0*)/,"");
      $(this).val(value);
  if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
     this.value = this.value.replace(/[^0-9\.]/g, '');
  }
}); 

angular.module('app.controllers').filter('pagination', function() {
    return function(input, start) {
        if (!input || !input.length) { return; }
        start = +start; //parse to int
        return input.slice(start);
    };

});
          angular.module('app.controllers').filter('dateformat', function ()
          {
          return function (item) {
            var t = item.split(/[- :]/);
            var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
            var time = d.getTime(); 
            return time;
          };
          });


jQuery(document).ready(function(){

$(document).on("keypress", "#vendorBiddingForm", function(event) { return event.keyCode != 13; });

});
