(function(){
    "use strict";

    angular.module('app.controllers').controller('EventEditController', function($scope, $state, EventCreateService, BridesProfileService, NotificationService){

        $scope.errorMessages = {};
        $scope.event = {};

        BridesProfileService.bridemyProfile().success(function(response) {
        // $scope.data = response;
        //$scope.data = JSON.parse(response);
        $scope.eventdata = response;


        setTimeout(function(){
        jQuery(".socialprofilemsg").css("display",'');
        jQuery(".wholediv").css('display','');
        }, 5000);


        });

        BridesProfileService.getexpire_date().success(function(response)
        {
            $scope.getexpire_date = response;

            var event_start_at_count = $scope.getexpire_date;

            var event_start_at_extra = 7;

            var event_start_at = (event_start_at_count) + (+event_start_at_extra);
            
        
            var someDate = new Date();
            var date_pick = someDate.getDate();

            var date_picks = (29) + (+date_pick);

            someDate.setDate(date_picks);

             var ToEndDate = new Date();

            ToEndDate.setDate(ToEndDate.getDate()+365);

            $("#start-dates").on("dp.show", function (e) {
              $('#start-dates').data("DateTimePicker").minDate(someDate);
              $('#start-dates').data("DateTimePicker").maxDate(ToEndDate);

            });
            $('#start-dates').datetimepicker({
                format:"MM/DD/YYYY",
                disabledDates:[someDate],
                ignoreReadonly: true
            });

//  $("#start-dates").keypress(function (e)
// {
//    e.preventDefault();
// });

//  $(function(){
//     /*
//      * this swallows backspace keys on any non-input element.
//      * stops backspace -> back
//      */
//     var rx = /INPUT|SELECT|TEXTAREA/i;

//     $("#start-dates").bind("keydown keypress", function(e){
//         if( e.which == 8 ){ // 8 == backspace
//             if(!rx.test(e.target.tagName) || e.target.disabled || e.target.readOnly ){
//                 e.preventDefault();
//             }
//         }
//     });
// });

        });

   
        $scope.eventCreateSubmit = function()
        {
            $scope.event.starts_at = $("input[name='starts_at']").val();
            $scope.event.latitude = $("input[name='lat']").val();
            $scope.event.longitude = $("input[name='lng']").val();
            $scope.event.state = $("input[name='administrative_area_level_1']").val();
            $scope.event.city = $("input[name='locality']").val();
            $scope.event.mobile_number = $("input[name='mobile_number']").val();
            $scope.event.postal_code = $('#zip_code').val();

             var brideEventForm = EventCreateService.create($scope.event);

            brideEventForm.success(function(response) {

                if (response.success === true) {
                    NotificationService.eventAlert(response.message.title, response.message.body);
                }

                // location.href = 'brides/profile';

            }).error(function(response) {

                 _.each(response, function(errors, key) {
                      _.each(errors, function(e) {
                        $scope.errorMessages[key] = e;
                        $scope.eventCreateForm[key].$dirty = true;
                        $scope.eventCreateForm[key].$setValidity(key, false);
                      });
                  });

            });
        };
    });

})();


 jQuery(document).ready(function(){

//     var someDate = new Date();
//     someDate.setDate(someDate.getDate() + 60);

//     $('body').on('click','#start-date', function () {
//       $(this).datetimepicker({
//               format: 'MM/DD/YYYY',
//               minDate: new Date(),
              
//               defaultDate: new Date(),
//       });//maxDate: someDate,
//     });

    $('body').on('keyup','#zip_code',function(e){
       $('#zip_code').geocomplete({
          details: "#bridesevents",
          componentRestrictions: {country: 'us'},
          types: ['(regions)'],
          type: ['postal_code','(cities)'],
        }).bind("geocode:result", function(event, result){

          $('input[name="locality"]').prop("disabled", true);
          $('input[name="administrative_area_level_1"]').prop("disabled", true);

          if($('input[name="locality"]').val().length === 0){
            $('input[name="locality"]').prop("disabled", false);
          }

          if($('input[name="administrative_area_level_1"]').val().length === 0){
            $('input[name="administrative_area_level_1"]').prop("disabled", false);
          }
      });
    });
    $('body').on('keyup','#number_only',function(e){

      var value = $(this).val();
      value = value.replace(/^(0*)/,"");
      $(this).val(value);
    
      if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
         this.value = this.value.replace(/[^0-9\.]/g, '');
      }
    });

    $('body').on('keyup','#alpha_only',function(e){
      if (this.value != this.value.replace(/[^a-zA-Z]/g, '')) {
         this.value = this.value.replace(/[^a-zA-Z]/g, '');
      }
    });
});


angular.module('app.controllers').directive('numbersOnly', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attr, ngModelCtrl) {
            function fromUser(text) {
                if (text) {
                    var transformedInput = text.replace(/[^0-9]/g, '');

                    if (transformedInput !== text) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                }
                return undefined;
            }            
            ngModelCtrl.$parsers.push(fromUser);
        }
    };
});
