(function() {
    "use strict";

    angular.module('app.controllers').controller('VendorsBiddingsController', function($http,$window,$scope, $mdDialog, $state, $stateParams, VendorsProfileService, NotificationService, BridesProfileService) {


        $scope.serviceRequest = {};

 $scope.currenturi = $stateParams.id;

 VendorsProfileService.getCountMessages($stateParams.id).success(function(response)
            {
            $scope.messagecount = response;
            });


        var service_fee = " ";
        VendorsProfileService.getServiceFeeDetails()
            .success(function(response) {
               $scope.service_fee = response;
               //console.dir(response);
               var service_fee = $scope.service_fee;
               jQuery('.datesClass').css('display','');
               //console.dir($scope.service_fee);
            });
            VendorsProfileService.getServiceRequestMiniumDetails($stateParams.id)
            .success(function(response) {

              $scope.serviceRequest_minimum = response;
              $scope.serviceminimumcount = $scope.serviceRequest_minimum.length;
              //console.log($scope.serviceRequestCount);

            });
            VendorsProfileService.getcurrentvendorid().success(function(response) {

            $scope.currentvid = response;

            });

            VendorsProfileService.getServiceRequestBestoffer().success(function(response) {

              $scope.bestOffer = response;

              $scope.bestOfferCount = $scope.bestOffer.length;

            });
	
		VendorsProfileService.getpaymentSettings().success(function(response) {

            $scope.paymentSettings = response;

            });


            VendorsProfileService.getcurrentdate().success(function(response) {

            $scope.currentdate = response;

            });


        VendorsProfileService.getServiceRequestIdVendor($stateParams.id)
            .success(function(response) {
                $scope.serviceRequestIdshow = response;
            });


             $scope.readStatus = function (serviceId){
               // alert(serviceId);
                $http({

                url: "users/messagereaded/"+serviceId,
                data: {serviceId:serviceId},
                method: 'POST',
                headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

                }).success(function(response){
                    $scope.Notifymessages.unreadNotificationCount--;
                    var x = document.querySelectorAll(".notifier_"+serviceId);
                    var i;
                    for (i = 0; i < x.length; i++) {
                        x[i].style.display = "none";
                    }

                //console.log("OK", response);
               // NotificationService.serverNotificationNoRedirect(response.message.title,response.message.body);

                }).error(function(response) {


                });
              //  console.log('deleting user with id='+userId+' at index='+index);
                };

            $scope.sort = function(keyname){
              $scope.sortKey = keyname;   //set the sortKey to the param passed
              $scope.reverse = !$scope.reverse; //if true make it false and vice versa
            };


        // var confirm = $mdDialog.confirm()
        // .parent(angular.element(document.body))
        // .title('Attention')
        // .content('Please pay $'+ service_fee +' to place your bid for this service request.')
        // .ariaLabel('Attention')
        // .ok('Okay')
        // .cancel('Cancel')
        // .hasBackdrop(false);

        // $mdDialog.show(confirm).then(function() {
        //
        //     $scope.formUrl = '/vendors/requests/' + $stateParams.id + '/biddings/create';
        //
        //     $scope.csrfToken = document.getElementById('csrf-token').value;
        //
        //     VendorsProfileService.getServiceRequestDetails($stateParams.id)
        //     .success(function(response) {
        //
        //       $scope.serviceRequest = response;
        //       $scope.service_fee = 10;
        //
        //     });
        // }, function() {
        //
        //     $window.location = '/vendors/requests';
        //
        // });

$scope.formUrl = '/vendors/requests/' + $stateParams.id + '/biddings/create';

 $scope.paysubmitclick = function() {
            $('#paysubmit').hide();
         // $scope.csrfToken = document.getElementById('csrf-token').value;
          if($("input[name='bidding_amount']").val() < 1)
{

   var alert = $mdDialog.alert({
                    title: 'Attention',
                    content: 'Please enter an amount greater than $1.00',
                    ok: 'OKAY'
                });
                $mdDialog
                    .show(alert)
                    .finally(function() {
                      $window.location.reload();
                    });
}

else
{

        var confirm = $mdDialog.confirm()
        .parent(angular.element(document.body))
        .title('Attention')
        .content("Are you sure want to place a quote on this request?")
        .ariaLabel('Attention')
        .ok('Okay') 
        .cancel('Cancel')
        .hasBackdrop(false); 
        $mdDialog.show(confirm).then(function() {
            document.getElementById('vendorBiddingForm').submit();           
        }, function() {
          
           // $window.location = '/vendors/requests';
         $('#paysubmit').show();
        });
}

          };



          //   $scope.formUrl = '/vendors/requests/' + $stateParams.id + '/biddings/create';

          // $scope.paysubmitclick = function() {
          
          // $('#paysubmit').hide();

          // };

         $scope.csrfToken = document.getElementById('csrf-token').value;

            VendorsProfileService.getServiceRequestDetails($stateParams.id)
            .success(function(response) {

              $scope.serviceRequest = response;

            });

    });

    angular.module('app.controllers').controller('VendorsBiddingsCancelledController', function($scope, $state, $stateParams, VendorsProfileService, NotificationService) {

        $scope.serviceRequest = {};

        VendorsProfileService.getServiceRequestDetails($stateParams.id)
            .success(function(response) {
                $scope.serviceRequest = response;
            });

        VendorsProfileService.biddingCancelled($stateParams.id)
            .success(function(response) {
                if (response.success === false) {
                    NotificationService.serverAlert(response.message.title, response.message.body);
                }
            });
    });

    angular.module('app.controllers').controller('VendorsBiddingsSuccessfullController', function($scope, $state, $stateParams, VendorsProfileService, NotificationService) {

        $scope.serviceRequest = {};
        VendorsProfileService.getServiceRequestDetails($stateParams.id)
            .success(function(response) {
                $scope.serviceRequest = response;
            });

        VendorsProfileService.biddingSuccessfull()
            .success(function(response) {
                if (response.success === true) {
                    NotificationService.serverAlert(response.message.title, response.message.body);
                }
            });
    });

     angular.module('app.controllers').filter('format', function () {
          return function (item) {
           
           var t = item.split(/[- :]/);
           var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
           var time = d.getTime(); 
           
            var nextDay = new Date(d);
            nextDay.setDate(d.getDate()+15);
            return nextDay;
          };
        });

      angular.module('app.controllers').filter('dateformat', function ()
          {
          return function (item) {
            var t = item.split(/[- :]/);
            var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
            var time = d.getTime(); 
            return time;
          };
          });


})();
$('body').on('keyup','#number_only',function(e){
    var value = $(this).val();
      value = value.replace(/^(0*)/,"");
      $(this).val(value);
  if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
     this.value = this.value.replace(/[^0-9\.]/g, '');
  }
});

jQuery(document).ready(function(){

$(document).on("keypress", "#vendorBiddingForm", function(event) { return event.keyCode != 13; });

});
