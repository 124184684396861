(function(){
    "use strict";

    angular.module('app.controllers').controller('adminPasswordController', function( $scope,$state,$mdDialog,AuthService,NotificationService,$window ){

          $scope.errorMessages = {};
          
          $scope.processForgetPassword = function(){

         

          // var formData = {
              
              
          //     email :  $scope.user.email,
              
          //   };
          //   //alert($scope.contact_name);
          //   //$('#contact_name').val();

          //    $http({

          //     url: "admin/password/checkadminemail",
          //     data: formData,
          //     method: 'POST',
          //   }).success(function(response){
          //       //   var alert = $mdDialog.alert({
          //       //     title: 'Attention',
          //       //     content: 'Thank you! Your message has been successfully sent. We will contact you very soon!',
          //       //     ok: 'OKAY'
          //       // });
          //       // $mdDialog
          //       //     .show(alert)
          //       //     .finally(function() {
          //       //          $window.location = "pages/contact-us";
          //       //     });
                             
          //    }).error(function(){
              
          //       $scope.serviceRequestsCount =0;
          //   });




           var auth = AuthService.adminforgetPassword($scope.user);

           auth.success(function(response){
            //$scope.email=response;
//alert(response.success);
if(response.success===false)
{
 var alert = $mdDialog.alert({
                    title: 'Attention',
                    content: 'Invalid Email ID',
                    ok: 'OKAY'
                });
 $mdDialog
                    .show(alert)
                    .finally(function() {
                       //  $window.location = "pages/contact-us";
                    });  
}
               NotificationService.serverAlert(
                   response.message.title,response.message.body
               );

           }).error(function(response) {
            $scope.email="Invalid Email ID";
           

                _.each(response, function(errors, key) {
                    _.each(errors, function(e) {
                      $scope.forgetPassword[key].$dirty = true;
                      $scope.forgetPassword[key].$setValidity(key, false);
                      $scope.errorMessages[key] = e;
                      // $scope.errorMessages[message] ='invalid';
                      
                    });
                });

           });

        };

    });

})();
