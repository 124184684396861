(function() {
  "use strict";



  angular.module('app.controllers').controller('HomeController', function($scope) {

  });

  angular.module('app.controllers').controller('SliderController', function($scope) {

    $scope.myInterval = 3000;

    $scope.slides = [{
      image: 'images/slider.png'
    }, {
      image: 'images/slider.png'
    }, {
      image: 'images/slider.png'
    }, {
      image: 'images/slider.png'
    }];

  });

 angular.module('app.controllers').controller('FindvendorsRequestssController', function( $scope,$state,$location,CmsService, $http,VendorsProfileService,NotificationService,$stateParams,$window ){
$scope.errorMessages = {};

$('body').on('keyup','#zip_code_vendors',function(e){

if(e.which !== 13){
	$scope.addressValidationFailed = true;
	jQuery(".suggestedlocationerr").css('display','');
}
if($("#zip_code_vendors").val().length < 2 || $("#zip_code_vendors").val().length === 0 ){
	$scope.addressValidationFailed = false;
	jQuery(".suggestedlocationerr").css('display','none');
}
       $('#zip_code_vendors').geocomplete({
type: ['postal_code','(cities)'],
  	  componentRestrictions: {country: 'us'},
          details: "#vendorsearch",
	  types: ['(regions)'],
        
        }).bind("geocode:result", function(event, result){

		jQuery(".suggestedlocationerr").css('display','none');
		$scope.addressValidationFailed = false;


          $('#vendorsearch input[name="locality"]').prop("disabled", true);
          $('#vendorsearch input[name="administrative_area_level_1"]').prop("disabled", true);

          if($('#vendorsearch input[name="locality"]').val().length === 0){
            $('#vendorsearch input[name="locality"]').prop("disabled", false);
          }
                      $scope.lat = $("input[name='lat']").val();
                      $scope.lng = $("input[name='lng']").val();
                      if($('#vendorsearch input[name="locality"]').val()==='')
                      {

                      $('#vendorsearch input[name="postal_code"]').val($('#vendorsearch input[name="administrative_area_level_1"]').val());  
                      }
                      else
                      {
                      $('#vendorsearch input[name="postal_code"]').val($('#vendorsearch input[name="locality"]').val());
                      }
          if($('#vendorsearch input[name="administrative_area_level_1"]').val().length === 0){
            $('#vendorsearch input[name="administrative_area_level_1"]').prop("disabled", false);
          }

      });

    });



  $scope.serviceCreate = {};

        VendorsProfileService.getAllServiceCategories().success(function(response) {
            $scope.allCategories = response;
        });

            $scope.selectedCategories ={};

            $scope.showSelectValue_cat = function(){

            var selected_service_id = $scope.someModel;
            $scope.selectedCategories = selected_service_id;

            };

    VendorsProfileService.getAllServiceCategories().success(function(response) {

            $scope.allCategories = response;

        });

                $scope.formUrl = 'pages/findvendorslists';


$scope.search = function () {
if(!$scope.addressValidationFailed){
  sessionStorage.selectedCategories = JSON.stringify($scope.selectedCategories);

  sessionStorage.locations = $('#vendorsearch input[name="postal_code"]').val();
  
sessionStorage.lat = JSON.stringify($scope.lat);
sessionStorage.lng = JSON.stringify($scope.lng);

if($scope.selectedCategories!=='{}' && $scope.selectedCategories!==undefined && $scope.lat!==undefined && $scope.lng!==undefined)
{
 location.href = 'pages/findvendorslists';
 // $window.location.href = '/pages/findvendorslists';
}

}

//alert(errorMessages);
//$window.location.href = '/pages/findvendorslists';
};

      $scope.findvendors = function(){

            $scope.postal_code = $("input[name='postal_code']").val();
            $scope.lat = $("input[name='lat']").val();
            $scope.lng = $("input[name='lng']").val();
            $scope.selectedCategories = $("input[name='selectedCategories']").val();

            //$scope.formUrl = '/pages/findvendorslists/' + $scope.selectedCategories + '/'+$scope.lat+'/'+$scope.lng;

        //alert($scope.formUrl);

        var formData = {

              postal_code: $scope.postal_code,
              lat:  $scope.lat,
              lng:  $scope.lng,
              selectedCategories:  $scope.selectedCategories,

            };
         // location.href = $scope.formUrl;

        // $scope.csrfToken = document.getElementById('csrf-token').value;

            // $('#inset_form').html('<form action="pages/findvendorslists" name="vote" id="vote" method="post" style="display:none;"><input type="text" name="catid" value="' + $scope.selectedCategories + '" /><input type="text" name="lon" value="' + $scope.lng + '" /><input type="text" name="lat" value="' + $scope.lat + '" /><input type="text" name="_token" value="' + document.getElementById('csrf-token').value + '" /></form>');

            // $('#vote').submit();
            //location.href = 'pages/findvendorsresults/'+$scope.selectedCategories+'/'+$scope.lng+'/'+$scope.lat;

            // var formData = {

            //   postal_code: $scope.postal_code,
            //   lat:  $scope.lat,
            //   lng:  $scope.lng,
            //   selectedCategories:  $scope.selectedCategories,

            // };

            $http({

              url: "site/pages/findvendorsresults",
              data: formData,
              method: 'POST',
              headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}

            }).success(function(response){

             //  location.href = 'pages/findvendorsresults';

            });


          };



      });

  angular.module('app.controllers').controller('VendorContrller', function($scope) {

    $scope.vendors = [

      {
        list: 'Free to participate - No cost'
      }, {
        list: 'Save money By receiving competitive proposals from well qualified vendors'
      }, {
        list: 'Save time - No need to call all vendors, vendors will send you proposals'
      }, {
        list: 'Stay Informed - Receive proposals in "Real Time" through our App'
      }, {
        list: 'Streamline Define your "Job" once and get multiple proposals'
      }
    ];

  });

  /*********** Brides List ************/

  angular.module('app.controllers').controller('BridesContrller', function($scope) {

    $scope.brides = [{
      list: 'Receive free Leads Only the ones that fit your profile'
    }, {
      list: 'Save time - No need to be disrupted during your busy day'
    }, {
      list: 'Real Time Request Receive request for proposals in "Real Time" through our App'
    }, {
      list: 'Know your customers precise requirement'
    }, {
      list: 'Get Rewarded for participating'
    }];

  });



})();



